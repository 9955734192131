import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Overlay,
  ConnectionPositionPair,
  PositionStrategy,
  ConnectedOverlayPositionChange,
  OverlayConfig,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { ScrollStrategies } from '../models/popover-scroll-strategy.enum';
import { PreferablePositions } from '../constants/positions.constant';

import { PopoverComponent } from '../components/popover.component';
import { MmPopoverRef } from '../classes/popover-ref';
import { MmPopoverConfig } from '../models/popover-params.interface';

@Injectable({
  providedIn: 'root',
})
export class MmPopoverService {
  private positionChanged: Observable<ConnectedOverlayPositionChange>;

  constructor(private readonly overlay: Overlay, private readonly injector: Injector) {}

  open<T>({ content, data, closeOnBackdropClick, ...rest }: MmPopoverConfig<T>): MmPopoverRef<T> {
    const overlayRef = this.overlay.create(this.getOverlayConfig({ ...rest }));
    const popoverRef = new MmPopoverRef<T>(overlayRef, content, data, closeOnBackdropClick, this.positionChanged);

    const injector = this.createInjector(popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));

    return popoverRef;
  }

  openGlobal<T>({
    origin,
    content,
    data,
    width,
    height,
    closeOnBackdropClick,
    ...rest
  }: MmPopoverConfig<T>): MmPopoverRef<T> {
    const overlayRef = this.overlay.create(this.getOverlayConfig({ origin: null, width, height, ...rest }));
    const popoverRef = new MmPopoverRef<T>(overlayRef, content, data, closeOnBackdropClick);

    const injector = this.createInjector(popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));

    return popoverRef;
  }

  createInjector(popoverRef: MmPopoverRef, injector: Injector) {
    return Injector.create({
      parent: injector,
      providers: [{ provide: MmPopoverRef, useValue: popoverRef }],
    });
  }

  private getOverlayConfig({
    origin,
    width,
    height,
    offsetX,
    offsetY,
    top,
    left,
    bottom,
    scrollStrategy,
    isCentered,
    hasBackdrop = true,
    right,
    preferedPosition,
  }: any): OverlayConfig {
    const isConnected = !!origin;
    const baseOptions = {
      hasBackdrop,
      width,
      height,
      backdropClass: 'mm-popover-backdrop',
    };

    if (isConnected) {
      const connectedScrollStrategy = scrollStrategy
        ? this.getScrollStrategy(scrollStrategy)
        : this.getScrollStrategy(ScrollStrategies.reposition);

      return new OverlayConfig({
        ...baseOptions,
        positionStrategy: this.getOverlayPosition({ origin, offsetX, offsetY, preferedPosition }),
        scrollStrategy: connectedScrollStrategy,
      });
    }

    const globalScrollStrategy = scrollStrategy
      ? this.getScrollStrategy(scrollStrategy)
      : this.getScrollStrategy(ScrollStrategies.block);

    return new OverlayConfig({
      ...baseOptions,
      positionStrategy: this.getGlobalOverlayPosition({ isCentered, top, left, bottom, right }),
      scrollStrategy: globalScrollStrategy,
    });
  }

  private getGlobalOverlayPosition({ isCentered, top, left, right, bottom }): PositionStrategy {
    const positionStrategy = this.overlay.position().global();

    if (isCentered) {
      return positionStrategy.centerHorizontally().centerVertically();
    } else if (top) {
      return positionStrategy.top(top);
    } else if (bottom) {
      return positionStrategy.bottom(bottom);
    } else if (left) {
      return positionStrategy.left(left);
    } else if (right) {
      return positionStrategy.right(right);
    }

    return positionStrategy;
  }

  private getOverlayPosition({ origin, offsetX, offsetY, preferedPosition }): PositionStrategy {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withPositions(this.getDefaultPositions(preferedPosition))
      .withDefaultOffsetX(offsetX)
      .withDefaultOffsetY(offsetY)
      .withFlexibleDimensions(false)
      .withPush(false);

    this.positionChanged = positionStrategy.positionChanges;

    return positionStrategy;
  }

  private getDefaultPositions(preferedPosition?: ConnectionPositionPair): ConnectionPositionPair[] {
    const defaultPositions = Object.values(PreferablePositions);

    return preferedPosition ? [preferedPosition, ...defaultPositions] : defaultPositions;
  }

  private getScrollStrategy(scrollStrategy: ScrollStrategies): ScrollStrategy {
    switch (scrollStrategy) {
      case ScrollStrategies.block:
        return this.overlay.scrollStrategies.block();

      case ScrollStrategies.reposition:
        return this.overlay.scrollStrategies.reposition();

      case ScrollStrategies.noop:
        return this.overlay.scrollStrategies.noop();

      default:
        return this.overlay.scrollStrategies.block();
    }
  }
}
