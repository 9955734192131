import { Injectable } from '@angular/core';
import { GaDatalayerService } from './ga-datalayer.service';
import { Angulartics2, Angulartics2GoogleTagManager } from 'angulartics2';
import { ApplicationEventService } from '../../application-event/services/application-event.service';
import { ApplicationEvent } from '../../application-event/models/application-event.model';

@Injectable({
  providedIn: 'root',
})
export class GaTrackingService {
  constructor(
    private readonly gtmDataLayer: GaDatalayerService,
    private readonly angulartics2: Angulartics2,
    private readonly angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private readonly applicationEventService: ApplicationEventService,
  ) {}

  public startTracking(): void {
    this.gtmDataLayer.initDataLayer();
    this.angulartics2GoogleTagManager.startTracking();
    this.subscribeToApplicationEvents();
  }

  private subscribeToApplicationEvents(): void {
    this.applicationEventService
      .subscribe()
      .subscribe((applicationEvent: ApplicationEvent) => this.triggerEvent(applicationEvent));
  }

  private triggerEvent(applicationEvent: ApplicationEvent) {
    this.angulartics2.eventTrack.next({
      action: applicationEvent.action,
      properties: {
        event: applicationEvent.event,
        label: applicationEvent.label,
        gtmCustom: {
          ...applicationEvent.getCustomProperties(),
          ...this.gtmDataLayer.getDataLayerProperties(),
        },
      },
    });
  }
}
