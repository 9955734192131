export const companyType = {
  natural: 'natural',
  legal: 'legal',
};

export enum NON_MANDATORY_WIZARD_FIELDS {
  ADRESS_LINE2 = 'addressLine2',
  LOCAL_TAX_ID = 'localTaxId',
}

export const MAX_COMPANY_REPRESENTATIVE_COUNT = 10;
export const MAX_BO_COUNT = 10;

export const SOLE_TRADER_SPAIN = 'nat_aut';

export const COMPANY_TRADE_REGISTRATION_TEXT = {
  mainLabel: 'EXTENDED_REGISTRATION.COMPANY_DATA.COMPANY_TRADE_REGISTRATION.LABEL',
  spainSoleLabel: 'EXTENDED_REGISTRATION.COMPANY_DATA.COMPANY_TRADE_REGISTRATION.SOLE_TRADER.LABEL',
};

export const EDIT_COMPANY_TRADE_REGISTRATION_TEXT = {
  mainLabel: 'EDIT_ACCOUNT.EDIT_TRADE_REGISTRATION.DIALOG.HEADER.TITLE',
  spainSoleLabel: 'EDIT_ACCOUNT.EDIT_TRADE_REGISTRATION.SOLE_TRADER.DIALOG.HEADER.TITLE',
};

export enum ECONOMY_TITLE_TYPE {
  NO_BENEFICIAL = 'no beneficial',
  STOCK_CORPORATION = 'stock corporation',
  BENEFICIAL_OWNER = 'beneficial owner',
}
