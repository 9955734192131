import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { environment } from '~env/environment';
import { LanguageCodes, AVAILABLE_LANGUAGES } from '~shared/constants';
import { LanguagePickerService } from './language-picker.service';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerComponent implements OnInit, OnDestroy {
  public availableLanguages = AVAILABLE_LANGUAGES;
  public currentLanguage: string = LanguageCodes[environment.locale];

  private unsubscribe$ = new Subject();
  private previousLanguage: string;
  private nextLanguage: string;

  constructor(private languagePickerService: LanguagePickerService, private changeDetectorRef: ChangeDetectorRef) {}

  // Added 'window:unload' to resolve the problem when the component extends from 'BaseBeforeUnloadComponentDirective'
  // and we
  // try to change language when the User hadn't saved his form data. Because when the User clicked 'Cancel' on the
  // browser pop-up message, what informs about losing his data, we changing language in the dropdown and the
  // LocalStorage but didn't reload the page, and the language on the page remains the previous one. We added to set
  // the previous language when the User clicked 'Cancel' and added to set the next language when the User clicked
  // 'Leave' and the page reloaded.
  @HostListener('window:unload') pageUnload() {
    if (this.nextLanguage) {
      this.setNextLanguage();
    }
  }

  public ngOnInit(): void {
    this.languagePickerService
      .getLanguage()
      .pipe(filter(Boolean), takeUntil(this.unsubscribe$))
      .subscribe((currentLanguage: string) => {
        if (!this.previousLanguage) {
          this.previousLanguage = currentLanguage;
        }
        this.currentLanguage = currentLanguage;
        this.changeDetectorRef.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.complete();
  }

  public trackByLanguageCode(item: { code: string }): string {
    return item.code;
  }

  public onLanguageChange(event: { code: string }): void {
    const { code } = event;

    this.nextLanguage = code;
    this.languagePickerService.setLanguage(code);
    this.languagePickerService.redirectToDefaultLanguageUrl(code);
    this.languagePickerService.setLanguage(this.previousLanguage);
  }

  private setNextLanguage(): void {
    this.languagePickerService.setLanguage(this.nextLanguage);
  }
}
