import { DropdownInput } from '@root/shared/model/dropdown-input.model';

const PLACED = 1;
const CANCELLED = 2;
const CONFIRMED = 3;
const SHIPPED = 4;
const PAYMENT_FAILED = 5;
const RETURN_REQUESTED = 6;
const RETURN_ACCEPTED = 7;
const RETURN_DECLINED = 8;
const PAID = 9;
const PENDING_VERIFICATION = 10;
const BLOCKED = 11;
const WAITING_FOR_APPROVAL = 12;

export const statusOptions = [
  new DropdownInput(PLACED, 'Placed'),
  new DropdownInput(CANCELLED, 'Canceled'),
  new DropdownInput(CONFIRMED, 'Confirmed'),
  new DropdownInput(SHIPPED, 'Shipped'),
  new DropdownInput(PAYMENT_FAILED, 'Payment Failed'),
  new DropdownInput(RETURN_REQUESTED, 'Return Requested'),
  new DropdownInput(RETURN_ACCEPTED, 'Return Accepted'),
  new DropdownInput(RETURN_DECLINED, 'Return Declined'),
  new DropdownInput(PAID, 'Paid'),
  new DropdownInput(PENDING_VERIFICATION, 'Pending Verification'),
  new DropdownInput(BLOCKED, 'Blocked'),
  new DropdownInput(WAITING_FOR_APPROVAL, 'Waiting For Approval'),
];
