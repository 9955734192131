import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpErrorHandlerInterceptor } from './interceptors';

import { ErrorHandlerService } from './services';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorHandlerInterceptor, multi: true },
  ],
})
export class ErrorHandlerModule {}
