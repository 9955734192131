export const LANGUAGE_PICKER = 'employee-app/language';

export enum LanguageCodes {
  de = 'de',
  en = 'en',
}

export const AVAILABLE_LANGUAGES = [
  {
    code: LanguageCodes.de,
    name: 'LANGUAGES.DE.TITLE',
  },
  {
    code: LanguageCodes.en,
    name: 'LANGUAGES.EN.TITLE',
  },
];

export enum LanguageNames {
  de = 'deutsch',
  en = 'english',
}
