import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MmAlertModule, MmIconModule } from '@metromarkets/components-17';
import {
  FEATURE_TOGGLE_CONFIG_TOKEN,
  FeatureToggleConfig,
  FeatureToggleModule,
  QaLocatorsModule,
} from '@metromarkets/sdk-17';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionsSubject, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OAuthModule, OAuthModuleConfig, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { NgxMaskModule } from 'ngx-mask';
import { TOGGLE_URLS } from '~core/constants';
import { BaseHttpInterceptor } from '~core/interceptors/base-http-interceptor';
import { L10nInterceptor } from '~core/interceptors/l10n.interceptor';
import { ErrorHandlerModule } from '~core/modules/error-handler/error-handler.module';
import { FEATURE_FLAG_CONFIG_TOKEN, FeatureFlagConfig, FeatureFlagModule } from '~core/modules/feature-flag';
import { L10nModule } from '~core/modules/l10n/l10n.module';
import { RouterCustomSerializer, routerReducer } from '~core/store';
import { environment } from '~env/environment';
import { FeatureToggleUrls, HREFLANG_ID } from '~shared/constants';
import { MmPopoverModule } from '~shared/modules/popover/popover.module';
import { CustomDispatcher } from '~shared/services/custom-dispatcher.service';

import { TranslateModule } from '@ngx-translate/core';
import { SidenavMenuComponent } from '@root/components/sidenav-menu/sidenav-menu.component';
import { BreadcrumbModule } from '@root/modules/breadcrumb/breadcrumb.module';
import { AuthService } from '@root/modules/user-auth/services/auth.service';
import { orderReducers } from '@sales-orders-app/modules/orders/store';
import { ORDERS_FEATURE_NAME } from '@sales-orders-app/modules/orders/store/feature-name';
import { OrdersEffects } from '@sales-orders-app/modules/orders/store/orders';
import { ImagePreviewerModule } from '@seller-support-app/modules/edit-products/modules';
import { Angulartics2Module } from 'angulartics2';
import { NavmenuControllerModule } from '~core/directives/nav-menu-controller/navmenu-controller.module';
import { RequestInterceptor } from '~core/interceptors/request-interceptor';
import { LanguagePickerModule } from '~shared/modules/language-picker';
import { MarketsSelectorModule } from '~shared/modules/markets-selector/markets-selector.module';
import { MultiMarketModule } from '~shared/modules/multi-market';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideBarIconComponent } from './components/side-bar-icon/side-bar-icon.component';
import { SidenavIconComponent } from './components/sidenav-icon/sidenav-icon.component';
import { UnauthorizedComponent } from './modules/unauthorized/unauthorized.component';
import { SearchBarModule } from './shared/modules/search-bar/search-bar.module';
import { CastClassModule } from './shared/pipes/cast-class/cast-class.module';

registerLocaleData(localeDE);

export function iconFactory(): { baseUrl: string } {
  return { baseUrl: `${environment.cdnBaseUrl}/images/icons` };
}

export function authConfigFactory(): OAuthModuleConfig {
  return {
    resourceServer: {
      allowedUrls: [
        `${environment.svcPaymentBaseUrl}/api`,
        `${environment.svcEmployeeBaseUrl}/api`,
        `${environment.svcInvoicingBaseUrl}/api`,
        `${environment.svcSellerOfficeBaseUrl}/api`,
        `${environment.svcOrderManagementBaseUrl}/api`,
        `${environment.vendorPortalBaseUrl}/vendor/pim-api/v1`,
        `${environment.svcSellerPimBaseUrl}/admin/v1`,
        `${environment.svcSellerPimBaseUrl}/api/v1`,
        `${environment.svcSellerInventoryBaseUrl}/admin/v1`,
        `${environment.svcCategoryBaseUrl}/admin/v1`,
        `${environment.svcRefundRequestsBaseUrl}/api`,
        `${environment.svcAnonymousEmailsBaseUrl}/`,
        `${environment.svcDiscountBaseUrl}/api`,
        `${environment.svcAccountingBaseUrl}/`,
        `${environment.financialReportsUrl}/`,
        `${environment.disputesBaseUrl}/`,
        `${environment.svcFraudBaseUrl}/api`,
        `${environment.svcServicePimParsingBaseUrl}/admin/v1`,
        `${environment.svcImsBaseUrl}/api/admin`,
        `${environment.svcServicePimRowReviewUrl}/admin/v1`,
        `${environment.svcServicePimGoldenRecordMergeUrl}/admin/v1`,
        `${environment.svcServiceMMCUrl}/api/internal`,
      ],
      sendAccessToken: true,
    },
  };
}

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [AppComponent, SidenavMenuComponent, UnauthorizedComponent, SidenavIconComponent, SideBarIconComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    HttpClientModule,
    L10nModule,
    MmIconModule.forRoot(iconFactory),
    StoreModule.forRoot(routerReducer, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreModule.forFeature(ORDERS_FEATURE_NAME, orderReducers),
    EffectsModule.forRoot([OrdersEffects]),
    QaLocatorsModule.forRoot({ shouldSetupLocators: true }),
    // AuthModule.forRoot({
    //   provide: AUTH_CONFIG_TOKEN,
    //   useFactory: () => IMS_CONFIG
    // }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot(),
    NgxMaskModule.forRoot(),
    FeatureToggleModule.forRoot({
      provide: FEATURE_TOGGLE_CONFIG_TOKEN,
      useFactory: (): FeatureToggleConfig => ({
        toggleUrl: FeatureToggleUrls.TOGGLE_URL,
      }),
    }),
    OAuthModule.forRoot(),
    MmPopoverModule,
    ImagePreviewerModule,
    MmAlertModule,
    FeatureToggleModule.forRoot({
      provide: FEATURE_TOGGLE_CONFIG_TOKEN,
      useFactory: (): FeatureToggleConfig => ({
        toggleUrl: TOGGLE_URLS,
      }),
    }),
    ErrorHandlerModule,
    FeatureFlagModule.forRoot({
      provide: FEATURE_FLAG_CONFIG_TOKEN,
      useFactory: (): FeatureFlagConfig => ({
        apiKey: environment.configCatSdkKey,
      }),
    }),
    MarketsSelectorModule,
    MultiMarketModule,
    NavmenuControllerModule,
    BreadcrumbModule,
    LanguagePickerModule,
    SearchBarModule,
    CastClassModule,
    TranslateModule,
    Angulartics2Module.forRoot(),
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: RouterCustomSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: BaseHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: L10nInterceptor, multi: true },
    { provide: ActionsSubject, useClass: CustomDispatcher },
    { provide: LOCALE_ID, useValue: environment.locale },
    { provide: HREFLANG_ID, useValue: environment.hreflang },
    { provide: OAuthModuleConfig, useFactory: authConfigFactory },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
      deps: [OAuthService, AuthService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
