import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Collection, ErrorResponse, MultiMarket } from '~shared/model';
import { MultiMarketHttpService } from '../../services';
import { LoadMultiMarketFailure, LoadMultiMarketSuccess, MultiMarketActionTypes } from './multi-market.actions';

@Injectable()
export class MultiMarketEffects {
  loadMarkets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MultiMarketActionTypes.LOAD_MULTI_MARKETS),
      switchMap(() =>
        this.httpService.loadMarkets().pipe(
          map((data: Collection<MultiMarket>) => new LoadMultiMarketSuccess(data)),
          catchError((error: ErrorResponse) => of(new LoadMultiMarketFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private httpService: MultiMarketHttpService) {}
}
