import * as fromProductCategories from './product-categories';
import * as fromCountries from './countries';
import * as fromOrganizationStatuses from './organization-statuses';
import * as fromDocumentTypes from './document-types';
import * as fromEmployee from './employee';
import * as fromRegistrationDestination from './registration-destination';
import * as fromOrigins from './origins';

export interface DictionaryState {
  productCategories: fromProductCategories.ProductCategoriesState;
  countries: fromCountries.CountriesState;
  organizationStatuses: fromOrganizationStatuses.OrganizationStatusesState;
  documentTypes: fromDocumentTypes.DocumentTypesState;
  employees: fromEmployee.EmployeeState;
  registrationDestination: fromRegistrationDestination.RegistrationDestinationState;
  origins: fromOrigins.OriginsState;
}

export const reducers = {
  productCategories: fromProductCategories.productCategoriesReducer,
  countries: fromCountries.countriesReducer,
  organizationStatuses: fromOrganizationStatuses.organizationStatusesReducer,
  documentTypes: fromDocumentTypes.documentTypesReducer,
  employees: fromEmployee.employeeReducer,
  registrationDestination: fromRegistrationDestination.registrationDestinationReducer,
  origins: fromOrigins.originsReducer,
};
