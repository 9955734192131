import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FeatureFlagService } from '@root/core/modules/feature-flag';
import { FeatureToggleEnum } from '@root/shared/constants';

@Component({
  selector: 'root-unauthorized-page',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedComponent implements OnInit {
  channelName;
  channelLink;

  constructor(private featureFlagService: FeatureFlagService) {}

  ngOnInit(): void {
    this.channelName = this.featureFlagService.getFeatureValue(FeatureToggleEnum.SLACK_CHANNEL_BACKOFFICE_PERMISSIONS);
    this.channelLink = this.featureFlagService.getFeatureValue(
      FeatureToggleEnum.SLACK_CHANNEL_LINK_BACKOFFICE_PERMISSIONS,
    );
  }
}
