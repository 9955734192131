import { statusOptions } from '@sales-orders-app/modules/orders/constants/status-options';
import {
  DropdownSearchSingle,
  DropdownSearchDropdown,
  DropdownSearchRange,
  DropdownSearchDate,
} from '@root/shared/modules/search-bar/models';

export const SEARCH_OPTIONS = [
  new DropdownSearchSingle('orderNumber', 'Order Number'),
  new DropdownSearchSingle('buyer.publicId', 'Buyer Id'),
  new DropdownSearchSingle('buyer.name', 'Buyer Name'),
  new DropdownSearchSingle('buyer.email', 'Buyer E-mail'),
  new DropdownSearchSingle('buyer.telephone', 'Buyer Telephone'),
  new DropdownSearchDropdown('orderStatus', 'Status', statusOptions),
  new DropdownSearchRange('price', 'Order Price', 'orderFromValue', 'orderTillValue'),
  new DropdownSearchDate('orderDate', 'Order Date', 'orderStartDate', 'orderEndDate'),
  new DropdownSearchSingle('orderAddress', 'Shipment Address'),
  new DropdownSearchDropdown('salesChannelCountry', 'Sales Channel'),
];
