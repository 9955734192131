import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  OrderReturnsActions,
  OrderReturnReasonsActionsTypes,
  ReadReturnsReasonsFailure,
  ReadReturnsReasonsSuccess,
} from './return-reasons.actions';
import { ReturnReasonsService } from '../../services/return-reasons.service';

@Injectable()
export class ReturnReasonsEffects {
  readReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderReturnReasonsActionsTypes.READ_RETURNS_REASONS),
      switchMap(() =>
        this.returnReasonsService.getReturnReasons().pipe(
          map((data: any[]) => new ReadReturnsReasonsSuccess(data)),
          catchError(error => of(new ReadReturnsReasonsFailure(error))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions<OrderReturnsActions>, private returnReasonsService: ReturnReasonsService) {}
}
