import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '~env/environment';

export const AUTH_CONFIG: AuthConfig = {
  issuer: environment.idamConfig && environment.idamConfig.apiHost,
  redirectUri: `${location.origin}${location.pathname}`,
  silentRefreshRedirectUri: `${location.origin}/static/assets/silent-refresh-token.html`,
  clientId: environment.idamConfig && environment.idamConfig.adminClientId,
  scope: 'openid',
  postLogoutRedirectUri: location.origin,
  responseType: 'code',
  useSilentRefresh: false,
  disableIdTokenTimer: true,
};
