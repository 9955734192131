import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store/state';
import { getMarketItems } from '../store/multi-market';
import { map } from 'rxjs/operators';
import { MultiMarket } from '~shared/model';
import { Observable } from 'rxjs';
import { MarketsService } from '~shared/modules/markets-selector';

@Injectable({
  providedIn: 'root',
})
export class MultiMarketService {
  constructor(private store: Store<State>, private marketsService: MarketsService) {}

  public getCurrentMarket(): Observable<MultiMarket> {
    return this.store.select(getMarketItems).pipe(
      map((markets: MultiMarket[]) => {
        return markets.find((market: MultiMarket) => market.id === this.marketsService.getCurrentMarketId());
      }),
    );
  }
}
