import { Field } from 'serialize-ts/dist';

export class TabsBuyerOrderInfo {
  @Field()
  ordersTotal: number;

  @Field()
  ordersShipped: number;

  @Field()
  ordersMixed: number;

  @Field()
  ordersBlocked: number;

  @Field()
  ordersCanceled: number;

  @Field()
  ordersConfirmed: number;

  @Field()
  ordersPaid: number;

  @Field()
  ordersPendingVerification: number;

  @Field()
  ordersPlaced: number;

  @Field()
  ordersReturnAccepted: number;

  @Field()
  ordersReturnDeclined: number;

  @Field()
  ordersReturnRequest: number;

  @Field()
  ordersWaitingForPayment: number;
}
