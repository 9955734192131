import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { Collection } from '~shared/model';
import { OriginItem } from '../../models';

export enum OriginsActionTypes {
  LOAD_ORIGINS_DICTIONARY = '[Dictionary] Origins Load',
  LOAD_ORIGINS_DICTIONARY_SUCCESS = '[Dictionary] Origins Load Success',
  LOAD_ORIGINS_DICTIONARY_FAILURE = '[Dictionary] Origins Load Failure',
}

export class LoadOriginsDictionary implements Action {
  readonly type = OriginsActionTypes.LOAD_ORIGINS_DICTIONARY;
}

export class LoadOriginsDictionarySuccess implements Action {
  readonly type = OriginsActionTypes.LOAD_ORIGINS_DICTIONARY_SUCCESS;
  constructor(public payload: Collection<OriginItem>) {}
}

export class LoadOriginsDictionaryFailure implements Action {
  readonly type = OriginsActionTypes.LOAD_ORIGINS_DICTIONARY_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export type OriginsActions = LoadOriginsDictionary | LoadOriginsDictionarySuccess | LoadOriginsDictionaryFailure;
