import { Component, OnInit, TemplateRef } from '@angular/core';

import { MmPopoverRef } from '../classes/popover-ref';
import { MmPopoverContent } from '../models/popover-content.types';
import { RenderMethods } from '../constants/render-methods.enum';

@Component({
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  renderMethod: RenderMethods = RenderMethods.component;
  renderMethods = RenderMethods;
  content: MmPopoverContent;
  context: { [key: string]: any };
  popoverStyles: { [key: string]: string | number };
  isFullScreen = false;
  isStringContent = false;

  constructor(private readonly popoverRef: MmPopoverRef) {}

  ngOnInit() {
    this.content = this.popoverRef.content;

    if (typeof this.content === 'string') {
      this.renderMethod = RenderMethods.text;
      this.isStringContent = true;
    }

    if (this.content instanceof TemplateRef) {
      this.renderMethod = RenderMethods.template;
      this.context = {
        close: this.popoverRef.close.bind(this.popoverRef),
      };
    }
  }
}
