<section *ngIf="isShowMarketsSelector$ | async" [mmTestId]="'MARKETS_SELECTOR.TABS'">
  <div class="market-picker">
    <mm-icon class="market-picker__icon" name="globus"></mm-icon>
    <p class="market-picker__title">{{ 'MARKETS_SELECTOR.TITLE' | translate }}</p>
    <ng-select
      [mmTestTarget]="'market-selector'"
      [items]="markets$ | async"
      [(ngModel)]="selectedMarket"
      [virtualScroll]="true"
      [clearable]="false"
      [searchable]="false"
      (change)="setMarket($event)"
      class="market-picker__select"
    >
      <ng-template ng-label-tmp let-item="item" class="market-picker__option">
        <employee-app-country-icon [countryCode]="item.id" [isTooltipDisabled]="true"></employee-app-country-icon>
        {{ item.id | countryFlagName }}
      </ng-template>
      <ng-template id="temp-item" ng-option-tmp let-item="item" class="market-picker__option">
        <employee-app-country-icon [countryCode]="item.id" [isTooltipDisabled]="true"></employee-app-country-icon>
        <span>{{ item.id | countryFlagName }}</span>
      </ng-template>
    </ng-select>
  </div>
</section>
