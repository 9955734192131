import { OrderDetails } from '@sales-orders-app/modules/orders/models';
import { ErrorResponse } from '~shared/model';

export interface OrderDetailsState {
  payload: OrderDetails;
  loading: boolean;
  error: ErrorResponse;
}

export const orderDetailsInitialState: OrderDetailsState = {
  payload: null,
  loading: false,
  error: null,
};
