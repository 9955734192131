import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { Employee } from '../../models';

export enum EmployeeActionTypes {
  LOAD_EMPLOYEES_DICTIONARY = '[Dictionary] Employee Load',
  LOAD_EMPLOYEES_DICTIONARY_SUCCESS = '[Dictionary] Employee Load Success',
  LOAD_EMPLOYEES_DICTIONARY_FAILURE = '[Dictionary] Employee Load Failure',
}

export class LoadEmployeesDictionary implements Action {
  readonly type = EmployeeActionTypes.LOAD_EMPLOYEES_DICTIONARY;
}

export class LoadEmployeesDictionarySuccess implements Action {
  readonly type = EmployeeActionTypes.LOAD_EMPLOYEES_DICTIONARY_SUCCESS;

  constructor(public payload: { employees: Employee[] }) {}
}

export class LoadEmployeesDictionaryFailure implements Action {
  readonly type = EmployeeActionTypes.LOAD_EMPLOYEES_DICTIONARY_FAILURE;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export type EmployeesDictionaryActions =
  | LoadEmployeesDictionary
  | LoadEmployeesDictionarySuccess
  | LoadEmployeesDictionaryFailure;
