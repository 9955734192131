import { Params } from '@angular/router';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Order } from '@sales-orders-app/modules/orders/models';
import { ORDERS_FEATURE_NAME } from '@sales-orders-app/modules/orders/store/feature-name';
import { State } from '@sales-orders-app/modules/orders/store';
import { OrdersState } from '@sales-orders-app/modules/orders/store/orders/orders.state';
import { PaginatedResponse } from '~shared/model';
import * as fromRouter from '~core/store';

export const getModuleFeatureState = createFeatureSelector(ORDERS_FEATURE_NAME);

export const getOrdersState = createSelector(getModuleFeatureState, (state: State) => state.orders);

export const isOrderLoading = createSelector(getOrdersState, (state: OrdersState) => state && state.loading);

export const getOrdersPayload = createSelector(getOrdersState, (state: OrdersState) => state.payload);

export const getOrders = createSelector(
  getOrdersPayload,
  (payload: PaginatedResponse<Order>) => payload && payload.items,
);

export const getTotalCount = createSelector(
  getOrdersPayload,
  (payload: PaginatedResponse<Order>) => payload && payload.totalCount,
);

export const getFilterQuery = createSelector(fromRouter.getFilterFromQueryParams, (filter: Params) => filter);

export const getDisplayAllOrders = createSelector(getOrdersState, (state: OrdersState) => state.displayAllOrders);
