import { HorizontalPositions } from './horizontal-position.enum';
import { VerticalPositions } from './vertical-position.enum';

export const PreferablePositions = {
  bottomRight: {
    originX: HorizontalPositions.end,
    originY: VerticalPositions.bottom,
    overlayX: HorizontalPositions.end,
    overlayY: VerticalPositions.top,
  },
  bottomLeft: {
    originX: HorizontalPositions.start,
    originY: VerticalPositions.bottom,
    overlayX: HorizontalPositions.start,
    overlayY: VerticalPositions.top,
  },
  topRight: {
    originX: HorizontalPositions.end,
    originY: VerticalPositions.top,
    overlayX: HorizontalPositions.end,
    overlayY: VerticalPositions.bottom,
  },
  topLeft: {
    originX: HorizontalPositions.start,
    originY: VerticalPositions.top,
    overlayX: HorizontalPositions.start,
    overlayY: VerticalPositions.bottom,
  },
};
