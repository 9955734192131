import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Collection } from '~shared/model';
import { Employee, OriginItem, RegistrationDestinationItem } from '../models';
import {
  CountryItem,
  DocumentTypeModel,
  OrganizationStatusModel,
  ProductCategoryItemModel,
} from '../models/dictionaries.model';
import {
  API_DICTIONARY_CATEGORIES,
  API_DICTIONARY_COUNTRY,
  API_DICTIONARY_DESTINATIONS,
  API_DICTIONARY_DOCUMENT_TYPE,
  API_DICTIONARY_EMPLOYEE,
  API_DICTIONARY_ORIGINS,
  API_DICTIONARY_STATUSES,
} from '../constants/api.constant';

@Injectable({
  providedIn: 'root',
})
export class HttpDictionaryService {
  constructor(private http: HttpClient) {}

  loadCountries(): Observable<Collection<CountryItem>> {
    return this.http.get<Collection<CountryItem>>(API_DICTIONARY_COUNTRY);
  }

  loadProductCategoriesDictionary(): Observable<Collection<ProductCategoryItemModel>> {
    return this.http.get<Collection<ProductCategoryItemModel>>(API_DICTIONARY_CATEGORIES);
  }

  loadOrganizationStatusesDictionary(): Observable<Collection<OrganizationStatusModel>> {
    return this.http.get<Collection<OrganizationStatusModel>>(API_DICTIONARY_STATUSES);
  }

  loadDocumentTypesDictionary(): Observable<Collection<DocumentTypeModel>> {
    return this.http.get<Collection<DocumentTypeModel>>(API_DICTIONARY_DOCUMENT_TYPE);
  }

  loadEmployeeDictionary(): Observable<Collection<Employee>> {
    return this.http.get<Collection<Employee>>(API_DICTIONARY_EMPLOYEE);
  }

  loadRegistrationDestinationDictionary(): Observable<Collection<RegistrationDestinationItem>> {
    return this.http.get<Collection<RegistrationDestinationItem>>(API_DICTIONARY_DESTINATIONS);
  }

  loadOriginsDictionary(): Observable<Collection<OriginItem>> {
    return this.http.get<Collection<OriginItem>>(API_DICTIONARY_ORIGINS);
  }
}
