import { Field, Name, Serializer, Type, Model, ArraySerializer } from 'serialize-ts/dist';
import { PaymentInfoStatusTypes } from '~shared/model/payment-info-status-types.enum';
import { PaymentInfoStateTypes } from '~shared/model/payment-info-state-types.enum';
import { ModelMetadataSerializer } from 'serialize-ts/dist/serializers/model-metadata.serializer';

class PaymentInfoStatusSerializer implements Serializer<PaymentInfoStatusTypes> {
  deserialize(status: PaymentInfoStateTypes): PaymentInfoStatusTypes | null {
    const paymentInfoStatusesMap = new Map<PaymentInfoStateTypes, PaymentInfoStatusTypes>([
      [PaymentInfoStateTypes.Authorized, PaymentInfoStatusTypes.Authorized],
      [PaymentInfoStateTypes.Failed, PaymentInfoStatusTypes.Failed],
      [PaymentInfoStateTypes.Pending, PaymentInfoStatusTypes.Pending],
      [PaymentInfoStateTypes.Captured, PaymentInfoStatusTypes.Captured],
      [PaymentInfoStateTypes.CancelRequested, PaymentInfoStatusTypes.CancelRequested],
      [PaymentInfoStateTypes.CancelSuccessful, PaymentInfoStatusTypes.CancelSuccessful],
      [PaymentInfoStateTypes.Cancelled, PaymentInfoStatusTypes.Cancelled],
      [PaymentInfoStateTypes.RefundRequested, PaymentInfoStatusTypes.RefundRequested],
      [PaymentInfoStateTypes.RefundApproved, PaymentInfoStatusTypes.RefundApproved],
      [PaymentInfoStateTypes.Refunded, PaymentInfoStatusTypes.Refunded],
      [PaymentInfoStateTypes.RefundDeclined, PaymentInfoStatusTypes.RefundDeclined],
      [PaymentInfoStateTypes.RefundSuccessful, PaymentInfoStatusTypes.RefundSuccessful],
      [PaymentInfoStateTypes.RefundFailed, PaymentInfoStatusTypes.RefundFailed],
      [PaymentInfoStateTypes.CaptureRequested, PaymentInfoStatusTypes.CaptureRequested],
      [PaymentInfoStateTypes.CaptureError, PaymentInfoStatusTypes.CaptureError],
      [PaymentInfoStateTypes.CaptureFailed, PaymentInfoStatusTypes.CaptureFailed],
    ]);

    return paymentInfoStatusesMap.get(status);
  }

  serialize(model: PaymentInfoStatusTypes): Object | null {
    return undefined;
  }
}

@Model()
export class OrderlinePaymentInfo {
  @Field()
  orderLineId: string;

  @Field()
  @Type(new PaymentInfoStatusSerializer())
  status: PaymentInfoStatusTypes;
}

export class PaymentInfo {
  @Field()
  orderId: string;

  @Field()
  paymentMethod: string;

  @Field()
  invoiceAddress: string;

  @Field()
  @Name('orderLines')
  @Type(new ArraySerializer(new ModelMetadataSerializer(OrderlinePaymentInfo)))
  orderLines: OrderlinePaymentInfo[];
}
