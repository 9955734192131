import { isEmpty } from 'lodash';
import { SidenavNode } from '~core/models';
import { UserRolesEnum } from '~shared/model';
import { FeatureToggleEnum } from '~shared/constants';

export class NavTreeBuilder {
  static buildTreeWithPermissions(tree: SidenavNode[], permissions: UserRolesEnum[]): SidenavNode[] {
    if (isEmpty(permissions)) {
      return tree;
    }
    if (isEmpty(tree)) {
      return [];
    }

    const treeWithChildrenPermissions = tree.map(node => {
      const children = node.children;
      const hasChildren = children && children.length;
      if (!hasChildren) {
        return node;
      }
      return {
        ...node,
        children: [...this.buildTreeWithPermissions(children, permissions)],
      };
    });

    return treeWithChildrenPermissions.filter(
      node => node.permissions.some(permission => permissions.includes(permission)) && !node.hidden,
    );
  }

  static removeNodeByFeatureFlag(nodes: SidenavNode[], featureFlag: FeatureToggleEnum): void {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].featureFlag === featureFlag) {
        nodes.splice(i, 1);
        return;
      }
      if (nodes[i].children) {
        this.removeNodeByFeatureFlag(nodes[i].children, featureFlag);
      }
    }
  }
}
