import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ORDERS_FEATURE_NAME } from '@sales-orders-app/modules/orders/store/feature-name';
import { OrderDetailsState } from '@sales-orders-app/modules/orders/store/order-details/order-details.state';
import { State } from '@sales-orders-app/modules/orders/store/state';
import { OrderDetails } from '@sales-orders-app/modules/orders/models';

const getModuleFeatureState = createFeatureSelector(ORDERS_FEATURE_NAME);

export const selectOrderDetailsState = createSelector(getModuleFeatureState, (state: State) => state.orderDetails);

export const selectOrderDetailsPayload = createSelector(
  selectOrderDetailsState,
  (state: OrderDetailsState) => state.payload,
);

export const getOrderDetails = createSelector(selectOrderDetailsPayload, (payload: OrderDetails) => payload);

export const getOrderDetailsLoading = createSelector(
  selectOrderDetailsState,
  (state: OrderDetailsState) => state.loading,
);
