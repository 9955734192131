export enum PRODUCT_STATUS {
  PUBLISHED = 1,
  INCOMPLETE,
  BLACKLISTED,
  UNPUBLISHED,
}

export const PRODUCT_STATUS_READABLE = {
  [PRODUCT_STATUS.PUBLISHED]: 'DICTIONARIES.PRODUCT_STATUS.PUBLISHED',
  [PRODUCT_STATUS.INCOMPLETE]: 'DICTIONARIES.PRODUCT_STATUS.INCOMPLETE',
  [PRODUCT_STATUS.BLACKLISTED]: 'DICTIONARIES.PRODUCT_STATUS.BLACKLISTED',
  [PRODUCT_STATUS.UNPUBLISHED]: 'DICTIONARIES.PRODUCT_STATUS.UNPUBLISHED',
};

export const PRODUCT_STATUSES_OPTIONS = [
  { label: 'Published', value: 1 },
  { label: 'Incomplete', value: 2 },
  { label: 'Blacklisted', value: 3 },
  { label: 'Unpublished', value: 4 },
];
