import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { DEFAULT_TRANSLATION_LANGUAGE } from '~shared/constants';

export function getMarketTranslations(
  fb: UntypedFormBuilder,
  languageId: string,
  controlsConfig: { [key: string]: any },
): UntypedFormArray {
  const languages = [DEFAULT_TRANSLATION_LANGUAGE, languageId];
  const translations = languages.map(lang =>
    fb.group({
      lang,
      properties: fb.group(controlsConfig),
    }),
  );

  return fb.array(translations);
}
