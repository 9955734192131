import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { v1 } from 'uuid';

import { ErrorHandlerService } from '../services';

@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: ErrorHandlerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = req.clone({
      setHeaders: {
        'x-correlation-id': String(v1()),
      },
    });
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (request.body) {
            if (request.body.password) {
              request.body.password = null;
            }
            if (request.body.username) {
              request.body.username = null;
            }
          }
          this.errorHandlerService.handleExternalError(error, request);
        }
        return throwError(error);
      }),
    );
  }
}
