import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../store/state';
import { getMarketsItems } from '../store/markets';
import { map } from 'rxjs/operators';
import { Market } from '~shared/model';
import { DEFAULT_MARKET, MARKET_LOCAL_STORAGE_KEY } from '~shared/constants';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarketsService {
  public hasChanged = new Subject<Market>();

  constructor(private store: Store<State>) {}

  public getCurrentMarketId(): string {
    const market: Market = JSON.parse(localStorage.getItem(MARKET_LOCAL_STORAGE_KEY));
    return market?.id || DEFAULT_MARKET;
  }

  public getCurrentMarket(): Observable<Market> {
    return this.store.select(getMarketsItems).pipe(
      map((markets: Market[]) => {
        return markets.find((market: Market) => market.id === this.getCurrentMarketId());
      }),
    );
  }

  public marketChanges(market: Market): void {
    this.hasChanged.next(market);
  }
}
