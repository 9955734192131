import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { MmIconModule } from '@metromarkets/components-17';
import { FormsModule } from '@angular/forms';
import { LanguagePickerComponent } from './language-picker.component';
import { LanguagePickerService } from './language-picker.service';

@NgModule({
  declarations: [LanguagePickerComponent],
  imports: [CommonModule, TranslateModule, NgSelectModule, MmIconModule, FormsModule],
  exports: [LanguagePickerComponent],
  providers: [LanguagePickerService],
})
export class LanguagePickerModule {}
