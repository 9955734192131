import { ArraySerializer, Field, Name, Serializer, Type } from 'serialize-ts/dist';
import { OrderBase } from '@sales-orders-app/shared/model/order.model';
import { OrderStatusTypes } from '@sales-orders-app/shared/model/order-status-types.enum';
import { OrderStateTypes } from '@sales-orders-app/shared/model/order-state-types.enum';
import { OrderPayment } from '@sales-orders-app/modules/orders/models/order-payment.model';
import { BuyerDetails } from '@sales-orders-app/modules/orders/models/buyer-details.model';
import { SalesChannel } from '@sales-orders-app/modules/orders/models/sales-channel.model';
import { OrderAddress } from '@sales-orders-app/modules/orders/models/order-address.model';
import { ModelMetadataSerializer } from 'serialize-ts/dist/serializers/model-metadata.serializer';
import { Price } from '@sales-orders-app/modules/orders/models/price.model';
import { AssistedSales } from "@sales-orders-app/modules/orderlines/models/orderline-detail-info.model";

class OrderStatusSerializer implements Serializer<OrderStatusTypes> {
  deserialize(status: OrderStateTypes): OrderStatusTypes | null {
    const orderStatusesMap = new Map<OrderStateTypes, OrderStatusTypes>([
      [OrderStateTypes.Placed, OrderStatusTypes.Placed],
      [OrderStateTypes.Canceled, OrderStatusTypes.Canceled],
      [OrderStateTypes.Confirmed, OrderStatusTypes.Confirmed],
      [OrderStateTypes.Shipped, OrderStatusTypes.Shipped],
      [OrderStateTypes.WaitingForPayment, OrderStatusTypes.WaitingForPayment],
      [OrderStateTypes.ReturnRequested, OrderStatusTypes.ReturnRequested],
      [OrderStateTypes.ReturnAccepted, OrderStatusTypes.ReturnAccepted],
      [OrderStateTypes.ReturnDeclined, OrderStatusTypes.ReturnDeclined],
      [OrderStateTypes.Paid, OrderStatusTypes.Paid],
      [OrderStateTypes.PendingVerification, OrderStatusTypes.PendingVerification],
      [OrderStateTypes.Blocked, OrderStatusTypes.Blocked],
      [OrderStateTypes.Mixed, OrderStatusTypes.Mixed],
      [OrderStateTypes.WaitingForApproval, OrderStatusTypes.WaitingForApproval],
    ]);

    return orderStatusesMap.get(status);
  }

  serialize(model: OrderStatusTypes): Object | null {
    return undefined;
  }
}

export class Order extends OrderBase {
  @Field()
  @Name('orderId')
  id: string;

  @Field()
  @Name('orderNumber')
  orderNumber: string;

  @Field()
  @Name('createdAt')
  placedDate: string;

  @Field()
  buyerDetails: BuyerDetails;

  @Field()
  @Name('orderAddresses')
  @Type(new ArraySerializer(new ModelMetadataSerializer(OrderAddress)))
  orderAddresses: OrderAddress[];

  @Field()
  salesChannel?: SalesChannel;

  @Field()
  @Name('orderLines')
  orderLines: string;

  @Field()
  @Name('status')
  @Type(new OrderStatusSerializer())
  orderStatus: OrderStatusTypes;

  @Field()
  @Name('payment')
  payment: OrderPayment;

  @Field()
  @Name('total')
  total: Price;

  @Field()
  buyerCompanyId: string;

  @Field()
  buyerId: string;

  @Field()
  @Name('assistedSales')
  @Type(new ModelMetadataSerializer(String))
  assistedSales?: string = null;
}
