import { OrderReturnReasonsActionsTypes, OrderReturnsActions } from './return-reasons.actions';
import { ReturnReasonsState, initialState } from './return-reasons.state';

export function returnReasonReducer(state = initialState, action: OrderReturnsActions): ReturnReasonsState {
  switch (action.type) {
    case OrderReturnReasonsActionsTypes.READ_RETURNS_REASONS:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case OrderReturnReasonsActionsTypes.READ_RETURNS_REASONS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };

    case OrderReturnReasonsActionsTypes.READ_RETURNS_REASONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default: {
      return state;
    }
  }
}
