import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Collection } from '~shared/model';

import {
  DocumentTypesActionTypes,
  LoadDocumentTypesDictionarySuccess,
  LoadDocumentTypesDictionaryFailure,
} from './document-types.actions';
import { HttpDictionaryService } from '../../services/dictionaries.service';
import { DocumentTypeModel } from '../../models';

@Injectable()
export class DocumentTypesEffects {
  loadDocumentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTypesActionTypes.LOAD_DOCUMENT_TYPES_DICTIONARY),
      mergeMap(() =>
        this.httpDictionaryService.loadDocumentTypesDictionary().pipe(
          map((types: Collection<DocumentTypeModel>) => new LoadDocumentTypesDictionarySuccess({ types: types.items })),
          catchError(error => of(new LoadDocumentTypesDictionaryFailure(error))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private httpDictionaryService: HttpDictionaryService) {}
}
