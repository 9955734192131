import { createSelector } from '@ngrx/store';
import { getDictionaryFeatureState } from '../selectors';

export const getRegistrationDestinationRoot = createSelector(
  getDictionaryFeatureState,
  state => state.registrationDestination,
);

export const getRegistrationDestinationList = createSelector(
  getRegistrationDestinationRoot,
  state => state.payload.items,
);
