import { Field, Model } from 'serialize-ts/dist';
import { SalesChannelPlatform } from '@sales-orders-app/modules/orders/models/sales-channel-platform.model';
import { SalesChannelMarketplace } from '@sales-orders-app/modules/orders/models/sales-channel-marketplace.model';

@Model()
export class SalesChannel {
  @Field()
  platform: SalesChannelPlatform;

  @Field()
  marketplace: SalesChannelMarketplace;

  @Field()
  country: string;
}
