import {
  ATTRIBUTES_ROUTE,
  CATEGORY_TREE_ROUTE,
  EDIT_PRODUCTS,
  LOV_AND_UNITS_ROUTE,
  PRODUCT_DATA_UPLOADS,
} from '~shared/constants';

export const MARKET_SELECTOR_ALLOWED_ROUTES = [
  LOV_AND_UNITS_ROUTE,
  ATTRIBUTES_ROUTE,
  CATEGORY_TREE_ROUTE,
  EDIT_PRODUCTS,
  PRODUCT_DATA_UPLOADS,
];
