import { HttpErrorResponse } from '@angular/common/http';
import { Collection, ErrorResponse } from '~shared/model';
import { RegistrationDestinationItem } from '../../models';

export interface RegistrationDestinationState {
  payload: Collection<RegistrationDestinationItem>;
  errorData: HttpErrorResponse | ErrorResponse | null;
  loading: boolean;
}

export const initialRegistrationDestinationState: RegistrationDestinationState = {
  payload: {
    items: [],
    totalCount: 0,
  },
  errorData: null,
  loading: false,
};
