import { Action } from '@ngrx/store';
import { BreadcrumbState } from '@root/modules/breadcrumb/models/breadcrumb.state';

export enum BreadcrumbActionTypes {
  URL_CHANGE = '[Breadcrumb] url changed',
}

export class ChangeUrl implements Action {
  readonly type = BreadcrumbActionTypes.URL_CHANGE;

  constructor(public payload: { state: BreadcrumbState }) {}
}

export type BreadcrumbActionsUnion = ChangeUrl;
