import { Collection, ErrorResponse, MultiMarket } from '~shared/model';

export interface MultiMarketState {
  payload: Collection<MultiMarket>;
  error: ErrorResponse | null;
  loading: boolean;
}

export const initialState: MultiMarketState = {
  payload: {
    items: [],
    totalCount: 0,
  },
  error: null,
  loading: false,
};
