import { createSelector } from '@ngrx/store';
import { getDictionaryFeatureState } from '../selectors';

export const getDocumentTypesRoot = createSelector(getDictionaryFeatureState, state => state.documentTypes);

export const getDocumentTypesDictionaryPayload = createSelector(getDocumentTypesRoot, state => state.payload);

export const getDocumentTypesDictionaryForAccount = createSelector(getDocumentTypesDictionaryPayload, state =>
  state.filter(documentType => documentType.allowedForClientEdit),
);
