import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { OrganizationStatusModel } from '../../models';

export enum OrganizationStatusesActionTypes {
  LOAD_ORGANIZATION_STATUSES_DICTIONARY = '[Dictionary] Organization Statuses Load',
  LOAD_ORGANIZATION_STATUSES_DICTIONARY_SUCCESS = '[Dictionary] Organization Statuses Load Success',
  LOAD_ORGANIZATION_STATUSES_DICTIONARY_FAILURE = '[Dictionary] Organization Statuses Load Failure',
}

export class LoadOrganizationStatusesDictionary implements Action {
  readonly type = OrganizationStatusesActionTypes.LOAD_ORGANIZATION_STATUSES_DICTIONARY;
}

export class LoadOrganizationStatusesDictionarySuccess implements Action {
  readonly type = OrganizationStatusesActionTypes.LOAD_ORGANIZATION_STATUSES_DICTIONARY_SUCCESS;

  constructor(public payload: { statuses: OrganizationStatusModel[] }) {}
}

export class LoadOrganizationStatusesDictionaryFailure implements Action {
  readonly type = OrganizationStatusesActionTypes.LOAD_ORGANIZATION_STATUSES_DICTIONARY_FAILURE;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export type OrganizationStatusesDictionaryActions =
  | LoadOrganizationStatusesDictionary
  | LoadOrganizationStatusesDictionarySuccess
  | LoadOrganizationStatusesDictionaryFailure;
