import { Injectable } from '@angular/core';
import { Action, ActionsSubject } from '@ngrx/store';
import { StoreActions } from '~shared/services/store-actions.service';

@Injectable({
  providedIn: 'root',
})
export class CustomDispatcher extends ActionsSubject {
  constructor(private actions: StoreActions) {
    super();
  }

  next(action: Action): void {
    super.next(action);
    this.actions.nextAction(action);
  }
}
