import { Action, createReducer, on } from '@ngrx/store';
import { clearNotifyFraud, notifyFraud, notifyFraudFailure, notifyFraudSuccess } from './notify-fraud.actions';
import {
  notifyFraudInitialState,
  NotifyFraudState,
} from '@sales-orders-app/modules/orders/store/notify-fraud/notify-fraud.state';

const reducer = createReducer(
  notifyFraudInitialState,
  on(notifyFraud, state => ({
    ...state,
    loading: true,
  })),
  on(notifyFraudSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(notifyFraudFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(clearNotifyFraud, () => notifyFraudInitialState),
);

export function notifyFraudReducer(state: NotifyFraudState, action: Action) {
  return reducer(state, action);
}
