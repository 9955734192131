import '@angular/compiler';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { APP_BASE_HREF } from '@angular/common';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const configs = (window as any).config;

if (configs) {
  // @ts-ignore
  __webpack_public_path__ = configs.baseHref.endsWith('/')
    ? `${configs.baseHref}static/`
    : `${configs.baseHref}/static/`;
}

document.addEventListener('DOMContentLoaded', () => {
  const start = () => {
    platformBrowserDynamic([
      {
        provide: APP_BASE_HREF,
        useValue: (window as any).config.baseHref,
      },
    ])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  };
  start();
});
