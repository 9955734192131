import { Injectable } from '@angular/core';
import { AuthService } from '@root/modules/user-auth/services/auth.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getEmployeeTreeNav } from '~core/constants';
import { SidenavNode } from '~core/models';
import { FeatureFlagService } from '~core/modules/feature-flag';
import { NavTreeBuilder } from '~core/services/nav-tree-builder';
import { FeatureToggleEnum } from '~shared/constants';

@Injectable({
  providedIn: 'root',
})
export class NavigationTreeService {
  public constructor(private featureFlagService: FeatureFlagService, private authService: AuthService) {}

  public getNavigationTree(): Observable<SidenavNode[]> {
    return combineLatest([
      this.featureFlagService.isFeatureEnabled(FeatureToggleEnum.FF_PIMPCR_1020_ENABLE_UPLOADS_V1),
      this.featureFlagService.isFeatureEnabledForUser(
        FeatureToggleEnum.FF_PIMPCR_1020_ENABLE_UPLOADS_V2,
        this.authService.userName(),
      ),
      this.featureFlagService.isFeatureEnabled(FeatureToggleEnum.ENABLE_DEBT_RECONCILIATION_MENU),
    ]).pipe(
      map(([isProductUploadsV1Enabled, isProductUploadsV2Enabled, isDebtReconciliationEnabled]) => {
        let employeeNavTree = [...getEmployeeTreeNav];
        employeeNavTree = NavTreeBuilder.buildTreeWithPermissions(employeeNavTree, this.authService.userRoles);

        if (!isProductUploadsV1Enabled) {
          NavTreeBuilder.removeNodeByFeatureFlag(employeeNavTree, FeatureToggleEnum.FF_PIMPCR_1020_ENABLE_UPLOADS_V1);
        }
        if (!isProductUploadsV2Enabled) {
          NavTreeBuilder.removeNodeByFeatureFlag(employeeNavTree, FeatureToggleEnum.FF_PIMPCR_1020_ENABLE_UPLOADS_V2);
        }
        if (!isDebtReconciliationEnabled) {
          NavTreeBuilder.removeNodeByFeatureFlag(employeeNavTree, FeatureToggleEnum.ENABLE_DEBT_RECONCILIATION_MENU);
        }

        return employeeNavTree;
      }),
    );
  }
}
