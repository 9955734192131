import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '~shared/constants';
import { Observable } from 'rxjs';
import { Collection, MultiMarket } from '~shared/model';

@Injectable({
  providedIn: 'root',
})
export class MultiMarketHttpService {
  constructor(private http: HttpClient) {}

  loadMarkets(): Observable<Collection<MultiMarket>> {
    const url = `${ApiConfig.SERVICE_MMC_API_ENDPOINT}/markets`;

    return this.http.get<Collection<MultiMarket>>(url);
  }
}
