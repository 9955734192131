import { Field, Model } from 'serialize-ts/dist';

@Model()
export class BuyerOrganization {
  @Field()
  id: string;

  isUsed: boolean;

  @Field()
  addressLine1?: string;

  @Field()
  addressLine2?: string;

  @Field()
  city?: string;

  @Field()
  country?: string;

  @Field()
  firstName?: string;

  @Field()
  lastName?: string;

  @Field()
  name?: string;

  @Field()
  phone?: string;

  @Field()
  postalCode?: string;

  @Field()
  taxNumber?: string;

  @Field()
  vatNumber?: string;
}
