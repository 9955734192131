import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { Collection } from '~shared/model';
import { RegistrationDestinationItem } from '~shared/modules/dictionary/models';

export enum RegistrationDestinationDictionaryActionTypes {
  LOAD_REGISTRATION_DESTINATION_DICTIONARY = '[Dictionary] Registration Destination',
  LOAD_REGISTRATION_DESTINATION_DICTIONARY_SUCCESS = '[Dictionary] Registration Destination Success',
  LOAD_REGISTRATION_DESTINATION_DICTIONARY_FAILURE = '[Dictionary] Registration Destination Failure',
}

export class LoadRegistrationDestinationDictionary implements Action {
  readonly type = RegistrationDestinationDictionaryActionTypes.LOAD_REGISTRATION_DESTINATION_DICTIONARY;
}

export class LoadRegistrationDestinationDictionarySuccess implements Action {
  readonly type = RegistrationDestinationDictionaryActionTypes.LOAD_REGISTRATION_DESTINATION_DICTIONARY_SUCCESS;

  constructor(public payload: Collection<RegistrationDestinationItem>) {}
}

export class LoadRegistrationDestinationDictionaryFailure implements Action {
  readonly type = RegistrationDestinationDictionaryActionTypes.LOAD_REGISTRATION_DESTINATION_DICTIONARY_FAILURE;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export type RegistrationDestinationActions =
  | LoadRegistrationDestinationDictionary
  | LoadRegistrationDestinationDictionarySuccess
  | LoadRegistrationDestinationDictionaryFailure;
