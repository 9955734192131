import { Collection, ErrorResponse, Market } from '~shared/model';

export interface MarketsState {
  payload: Collection<Market>;
  error: ErrorResponse | null;
  loading: boolean;
}

export const initialState: MarketsState = {
  payload: {
    items: [],
    totalCount: 0,
  },
  error: null,
  loading: false,
};
