import { createSelector } from '@ngrx/store';
import { getDictionaryFeatureState } from '../selectors';
import isEmpty from 'lodash/isEmpty';

export const getCountriesRoot = createSelector(getDictionaryFeatureState, state => state.countries);

export const getCountriesDictionaryPayload = createSelector(getCountriesRoot, (state, props: any = {}) => {
  const { countryCodes } = props;
  if (isEmpty(countryCodes)) {
    return state.payload.list;
  }

  return state.payload.list.filter(country => countryCodes.includes(country.code));
});

export const getCountriesEntity = createSelector(getCountriesRoot, state => state.payload.entity);

export const getAvailableCountriesDictionaryPayload = createSelector(getCountriesRoot, state => {
  const availableCountries = state.payload.list.filter(country => country.allowedForRegistration === true);
  return isEmpty(availableCountries) ? [] : availableCountries;
});

export const getAvailableCountriesDestinationPayload = createSelector(getCountriesRoot, state => {
  const availableCountries = state.payload.list.filter(country => country.allowedForDestination === true);
  return isEmpty(availableCountries) ? [] : availableCountries;
});

export const areAvailableCountriesLoading = createSelector(getCountriesRoot, state => state.loading);
