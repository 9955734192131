import { Field, Model, Name } from 'serialize-ts/dist';
import { Money } from '~shared/model';

@Model()
export class Price {
  @Field()
  @Name('gross')
  gross: Money;

  @Field()
  @Name('net')
  net: Money;
}
