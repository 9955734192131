<div class="alert-wrapper">
  <div class="alert">
    <mat-icon class="alert__icon">error</mat-icon>
    <span class="alert__text">
      We are sorry, but it looks like you don’t have enough permissions to view this page. If you believe this is not
      correct, please raise this issue in
      <a href="{{channelLink|async}}" target="_blank">
        <b>{{channelName|async}}</b>
      </a>
      channel on Slack for further support.
    </span>
  </div>
</div>
