const ITEMS_SIZE_10 = 10;
const ITEMS_SIZE_15 = 15;
const ITEMS_SIZE_25 = 25;
const ITEM_SIZE_30 = 30;
const ITEMS_SIZE_50 = 50;
const ITEMS_SIZE_100 = 100;
const ITEMS_SIZE_200 = 200;
const ITEMS_SIZE_500 = 500;
const ITEMS_SIZE_1000 = 1000;

export enum ITEMS_PER_PAGE {
  SIZE_10 = ITEMS_SIZE_10,
  SIZE_15 = ITEMS_SIZE_15,
  SIZE_25 = ITEMS_SIZE_25,
  SIZE_30 = ITEM_SIZE_30,
  SIZE_50 = ITEMS_SIZE_50,
  SIZE_100 = ITEMS_SIZE_100,
  SIZE_200 = ITEMS_SIZE_200,
  SIZE_500 = ITEMS_SIZE_500,
  SIZE_1000 = ITEMS_SIZE_1000,
}
