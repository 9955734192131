import { ErrorResponse } from '~shared/model';

export interface NotifyFraudState {
  error: ErrorResponse;
  loading: boolean;
}

export const notifyFraudInitialState: NotifyFraudState = {
  error: null,
  loading: false,
};
