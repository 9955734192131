import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'employee-app-side-bar-icon',
  templateUrl: './side-bar-icon.component.html',
  styleUrls: ['./side-bar-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarIconComponent {
  @Output() sideBarToogle = new EventEmitter<boolean>();
  @Input() scrolled = false;

  showSideBar = false;

  toggleSideBar(): void {
    this.showSideBar = !this.showSideBar;
    this.sideBarToogle.emit(this.showSideBar);
  }
}
