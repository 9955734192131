import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryIconModule } from '~shared/modules/country-icon/country-icon.module';
import { StoreModule } from '@ngrx/store';
import { MULTI_MARKET_NAME } from './constants';
import { EffectsModule } from '@ngrx/effects';
import { MultiMarketEffects } from './store/multi-market';
import { MatTabsModule } from '@angular/material/tabs';
import { MmIconModule } from '@metromarkets/components-17';
import { TranslateModule } from '@ngx-translate/core';
import { QaLocatorsModule } from '@metromarkets/sdk-17';
import { NgSelectModule } from '@ng-select/ng-select';
import { reducers } from './store/reducer';
import { FormsModule } from '@angular/forms';
import { CountryNamesModule } from '@root/shared/pipes/country-names/country-names.module';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(MULTI_MARKET_NAME, reducers),
    EffectsModule.forFeature([MultiMarketEffects]),
    MatTabsModule,
    MmIconModule,
    TranslateModule,
    QaLocatorsModule,
    CountryIconModule,
    NgSelectModule,
    FormsModule,
    CountryNamesModule,
  ],
})
export class MultiMarketModule {}
