import { Action } from '@ngrx/store';
import { Collection, ErrorResponse, MultiMarket } from '~shared/model';

export enum MultiMarketActionTypes {
  LOAD_MULTI_MARKETS = '[Multi-Markets] Markets Load',
  LOAD_MULTI_MARKETS_SUCCESS = '[Multi-Markets] Markets Load Success',
  LOAD_MULTI_MARKETS_FAILURE = '[Multi-Markets] Markets Load Failure',
}

export class LoadMultiMarket implements Action {
  readonly type = MultiMarketActionTypes.LOAD_MULTI_MARKETS;
}

export class LoadMultiMarketSuccess implements Action {
  readonly type = MultiMarketActionTypes.LOAD_MULTI_MARKETS_SUCCESS;

  constructor(public payload: Collection<MultiMarket>) {}
}

export class LoadMultiMarketFailure implements Action {
  readonly type = MultiMarketActionTypes.LOAD_MULTI_MARKETS_FAILURE;

  constructor(public payload: { error: ErrorResponse }) {}
}

export type MultiMarketActions = LoadMultiMarket | LoadMultiMarketSuccess | LoadMultiMarketFailure;
