import { Field, ArraySerializer, Type } from 'serialize-ts/dist';
import { ModelMetadataSerializer } from 'serialize-ts/dist/serializers/model-metadata.serializer';
import { BuyerOrganization } from './buyer-organization';
import { BuyerAddress } from './buyer-address.model';

export class TabsBuyerInfo {
  @Field()
  id: string;

  @Field()
  accountNumber: string;

  @Field()
  firstName: string;

  @Field()
  lastName: string;

  @Field()
  email: string;

  @Field()
  @Type(new ArraySerializer(new ModelMetadataSerializer(BuyerAddress)))
  addresses: BuyerAddress[];

  @Field()
  @Type(new ArraySerializer(new ModelMetadataSerializer(BuyerOrganization)))
  organizations: BuyerOrganization[];

  @Field()
  accountStatus: string;

  @Field()
  phoneNumber: string;

  @Field()
  cardNumber: string;

  @Field()
  cdmId: string;
}
