import {
  LoadProductCategoriesDictionaryFailure,
  LoadProductCategoriesDictionarySuccess,
  ProductCategoriesActionTypes,
  ProductCategoriesDictionaryActions,
} from './product-categories.actions';
import { initialProductCategoriesState, ProductCategoriesState } from './product-categories.state';

export function productCategoriesReducer(
  state = initialProductCategoriesState,
  action: ProductCategoriesDictionaryActions,
): ProductCategoriesState {
  switch (action.type) {
    case ProductCategoriesActionTypes.LOAD_PRODUCT_CATEGORIES_DICTIONARY:
      return loadCategories(state);

    case ProductCategoriesActionTypes.LOAD_PRODUCT_CATEGORIES_DICTIONARY_SUCCESS:
      return loadCategoriesSuccess(state, action);

    case ProductCategoriesActionTypes.LOAD_PRODUCT_CATEGORIES_DICTIONARY_FAILURE:
      return loadCategoriesFailure(state, action);

    default:
      return state;
  }
}

function loadCategories(state: ProductCategoriesState): ProductCategoriesState {
  return {
    ...state,
    loading: true,
  };
}

function loadCategoriesSuccess(
  state: ProductCategoriesState,
  action: LoadProductCategoriesDictionarySuccess,
): ProductCategoriesState {
  return {
    ...state,
    loading: false,
    payload: action.payload,
  };
}

function loadCategoriesFailure(
  state: ProductCategoriesState,
  action: LoadProductCategoriesDictionaryFailure,
): ProductCategoriesState {
  return {
    ...state,
    loading: false,
    errorData: action.payload,
  };
}
