import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MmFormFieldModule } from '@metromarkets/components-17';
import { SearchDropdownComponent } from './components/search-dropdown.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SearchDropdownComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MmFormFieldModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [SearchDropdownComponent],
})
export class SearchDropdownModule {}
