import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BreadcrumbModel } from '@root/modules/breadcrumb/models/breadcrumb.model';
import { BreadcrumbState } from '@root/modules/breadcrumb/models/breadcrumb.state';

export const getModuleFeatureState = createFeatureSelector('breadcrumb');

export const getBreadcrumbState = createSelector(getModuleFeatureState, (state: BreadcrumbState) => state.breadCrumbs);

export const getBreadCrumb = createSelector(
  getBreadcrumbState,
  (breadCrumbs: { [key: string]: BreadcrumbModel }) => breadCrumbs,
);
