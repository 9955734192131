import { Field, Model } from 'serialize-ts/dist';
import { Payment } from '~shared/model/payment.model';
import { PaymentMethodTypes } from '~shared/model';
import { OrderPaymentStatus } from '@sales-orders-app/modules/orders/models/order-payment-status.model';

@Model()
export class OrderPayment extends Payment {
  @Field()
  buyerId: string;

  @Field()
  paymentMethod: PaymentMethodTypes;

  @Field()
  status: OrderPaymentStatus;
}
