import { Injectable } from '@angular/core';
import { ApplicationEvent } from '~shared/modules/application-event/models/application-event.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationEventService {
  private readonly events$ = new Subject<ApplicationEvent>();

  public subscribe(): Observable<ApplicationEvent> {
    return this.events$.asObservable();
  }

  public publish(event: ApplicationEvent) {
    this.events$.next(event);
  }
}
