import { Params } from '@angular/router';
import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import get from 'lodash/get';

import { RouterStateUrl } from '~core/store';

export const getRouterReducer = createFeatureSelector('routerReducer');

export const getRouterState = createSelector(
  getRouterReducer,
  (routerReducer: fromRouter.RouterReducerState<RouterStateUrl>) => routerReducer && routerReducer.state,
);

export const getQueryParams = createSelector(getRouterState, (state: RouterStateUrl) => state && state.queryParams);

export const getUrl = createSelector(getRouterState, (state: RouterStateUrl) => state && state.url);

export const getFilterFromQueryParams = createSelector(getQueryParams, (params: Params) => params && params.filter);

export const getSortParams = createSelector(getQueryParams, params => get(params, 'sort', {}));

export const getSortKey = createSelector(getSortParams, sort => Object.keys(sort)[0]);

export const getPageSize = createSelector(getQueryParams, params => get(params, 'limit', 0));

const getPageOffset = createSelector(getQueryParams, params => get(params, 'offset', 0));
export const getPageIndex = createSelector(getPageOffset, getPageSize, (offset, limit) => limit && offset / limit);
