import { Action } from '@ngrx/store';
import { ErrorResponse } from '~shared/model/error-response.model';
import { ReturnReason } from '../../models/return-reason.model';

export enum OrderReturnReasonsActionsTypes {
  READ_RETURNS_REASONS = '[Orders] Read Returns Reasons',
  READ_RETURNS_REASONS_SUCCESS = '[Orders] Read Returns Reasons Success',
  READ_RETURNS_REASONS_FAILURE = '[Orders] Read Returns Reasons Failure',
}

export class ReadReturnsReasons implements Action {
  readonly type = OrderReturnReasonsActionsTypes.READ_RETURNS_REASONS;
}

export class ReadReturnsReasonsSuccess implements Action {
  readonly type = OrderReturnReasonsActionsTypes.READ_RETURNS_REASONS_SUCCESS;

  constructor(public payload: ReturnReason[]) {}
}

export class ReadReturnsReasonsFailure implements Action {
  readonly type = OrderReturnReasonsActionsTypes.READ_RETURNS_REASONS_FAILURE;

  constructor(public payload: ErrorResponse) {}
}

export type OrderReturnsActions = ReadReturnsReasons | ReadReturnsReasonsSuccess | ReadReturnsReasonsFailure;
