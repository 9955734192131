import { DropdownFieldType } from './dropdown-field-type';
import { DropdownSearch } from './dropdown-search.model';

export class DropdownSearchDate extends DropdownSearch {
  startDateKey: string;
  endDateKey: string;
  constructor(id: string | number, value: string, startDateKey: string, endDateKey: string) {
    super(id, value, DropdownFieldType.DATE);
    this.startDateKey = startDateKey;
    this.endDateKey = endDateKey;
  }
}
