import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbModel } from '@root/modules/breadcrumb/models/breadcrumb.model';
import { BACK_OFFICE_TITLE } from '~shared/constants';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  constructor(private titleService: Title, private translateSrv: TranslateService) {}

  public setTitleFromBreadcrumb(breadCrumbs: BreadcrumbModel[]) {
    const title = breadCrumbs
      .reduce((acc, bCrumb, index) => `${acc} ${this.translateSrv.instant(bCrumb.title)} / `, '')
      .trim()
      .replace(/\/$/, '');
    this.titleService.setTitle(title || BACK_OFFICE_TITLE);
  }
}
