import { Field, Name, Serializer, Type } from 'serialize-ts/dist';
import { Money } from '~shared/model/money.model';
import { PaymentMethodTypes } from '~shared/model/payment-method-types.enum';
import { TransactionStatusTypes } from '~shared/model/transaction-status-types.enum';
import { TransactionStateTypes } from '~shared/model/transaction-state-types.enum';

class TransactionStatusSerializer implements Serializer<TransactionStatusTypes> {
  deserialize(state: TransactionStateTypes): TransactionStatusTypes | null {
    const transactionStatusesMap = new Map<TransactionStateTypes, TransactionStatusTypes>([
      [TransactionStateTypes.Authorized, TransactionStatusTypes.Authorized],
      [TransactionStateTypes.Failed, TransactionStatusTypes.Failed],
      [TransactionStateTypes.Pending, TransactionStatusTypes.Pending],
      [TransactionStateTypes.RefundApproved, TransactionStatusTypes.RefundApproved],
      [TransactionStateTypes.RefundDeclined, TransactionStatusTypes.RefundDeclined],
      [TransactionStateTypes.RefundRequested, TransactionStatusTypes.RefundRequested],
      [TransactionStateTypes.RefundCompleted, TransactionStatusTypes.RefundCompleted],
      [TransactionStateTypes.CaptureRequested, TransactionStatusTypes.CaptureRequested],
      [TransactionStateTypes.CaptureError, TransactionStatusTypes.CaptureError],
      [TransactionStateTypes.CaptureFailed, TransactionStatusTypes.CaptureFailed],
      [TransactionStateTypes.Captured, TransactionStatusTypes.Captured],
      [TransactionStateTypes.CaptureCompleted, TransactionStatusTypes.CaptureCompleted],
      [TransactionStateTypes.Cancelled, TransactionStatusTypes.Cancelled],
    ]);

    return transactionStatusesMap.get(state);
  }

  serialize(model: TransactionStatusTypes): Object | null {
    return undefined;
  }
}

export class OrderBase {
  @Field()
  balance: Money;

  @Field()
  paymentDate: string;

  @Field()
  paymentMethod: PaymentMethodTypes;

  @Field()
  buyer: string;

  @Field()
  order: string;

  @Field()
  @Name('state')
  @Type(new TransactionStatusSerializer())
  status: TransactionStatusTypes;
}
