import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StoreActions {
  private actions$ = new Subject<Action>();

  ofType(type: string): Observable<Action> {
    return this.actions$.pipe(filter((action: Action) => action.type === type));
  }

  nextAction(action: Action): void {
    this.actions$.next(action);
  }
}
