import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OrderDetails } from '@sales-orders-app/modules/orders/models';
import { OrderDetailsService } from '@sales-orders-app/modules/orders/services/order-details.service';
import {
  loadOrderDetails,
  loadOrderDetailsFailure,
  loadOrderDetailsSuccess,
} from '@sales-orders-app/modules/orders/store/order-details/order-details.actions';
import { ErrorResponse } from '~shared/model';

@Injectable()
export class OrderDetailsEffects {
  loadOrderDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrderDetails),
      switchMap(({ orderId }) =>
        this.orderDetailsService.loadOrderById(orderId).pipe(
          map((orderDetails: OrderDetails) => loadOrderDetailsSuccess({ orderDetails })),
          catchError((error: ErrorResponse) => {
            return of(loadOrderDetailsFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private orderDetailsService: OrderDetailsService) {}
}
