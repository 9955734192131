import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize } from 'serialize-ts/dist';
import { ORDERS_TAB_PAYMENT_API } from '@sales-orders-app/modules/orders/constants/api';
import { PaymentInfo, OrderlinePaymentInfo } from '@sales-orders-app/modules/orders/models/payment-info.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentInfosService {
  constructor(private http: HttpClient) {}

  getPaymentInfos(orderId: string): Observable<PaymentInfo> {
    return this.http.get<PaymentInfo>(`${ORDERS_TAB_PAYMENT_API}/${orderId}`).pipe(
      map(response => ({
        ...response,
        orderLines: response.orderLines.map(i => deserialize(i, OrderlinePaymentInfo)),
      })),
    );
  }
}
