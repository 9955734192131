import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Collection } from '~shared/model';
import {
  ProductCategoriesActionTypes,
  LoadProductCategoriesDictionaryFailure,
  LoadProductCategoriesDictionarySuccess,
} from './product-categories.actions';
import { ProductCategoriesState } from './product-categories.state';
import { HttpDictionaryService } from '../../services/dictionaries.service';
import { ProductCategoryItemModel } from '../../models';

@Injectable()
export class ProductCategoriesEffects {
  loadCategoriesTree = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductCategoriesActionTypes.LOAD_PRODUCT_CATEGORIES_DICTIONARY),
      mergeMap(() =>
        this.httpDictionaryService.loadProductCategoriesDictionary().pipe(
          map(
            (categories: Collection<ProductCategoryItemModel>) =>
              new LoadProductCategoriesDictionarySuccess(categories.items),
          ),
          catchError(error => of(new LoadProductCategoriesDictionaryFailure(error))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private httpDictionaryService: HttpDictionaryService,
    private store: Store<ProductCategoriesState>,
  ) {}
}
