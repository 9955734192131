import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { deserialize } from 'serialize-ts/dist';
import { ErrorResponse } from '~shared/model';

@Injectable()
export class BaseHttpInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          const customError =
            (Array.isArray(error.error) && error.error.length === 0) || Object.keys(error.error).length === 0
              ? {
                  status: error.status,
                }
              : error.error;

          const errorResponse = deserialize(customError, ErrorResponse);
          return throwError(errorResponse);
        }

        return throwError(error);
      }),
    );
  }
}
