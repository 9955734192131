const CONTINUE_STATUS_CODE = 100;
const SWITCHING_PROTOCOLS_STATUS_CODE = 101;
const PROCESSING_STATUS_CODE = 102;
const OK_STATUS_CODE = 200;
const CREATED_STATUS_CODE = 201;
const ACCEPTED_STATUS_CODE = 202;
const NON_AUTHORITATIVE_INFORMATION_STATUS_CODE = 203;
const NO_CONTENT_STATUS_CODE = 204;
const RESET_CONTENT_STATUS_CODE = 205;
const PARTIAL_CONTENT_STATUS_CODE = 206;
const MULTI_STATUS_STATUS_CODE = 207;
const ALREADY_REPORTED_STATUS_CODE = 208;
const IM_USED_STATUS_CODE = 226;
const MULTIPLE_CHOICES_STATUS_CODE = 300;
const MOVED_PERMANENTLY_STATUS_CODE = 301;
const FOUND_STATUS_CODE = 302;
const SEE_OTHER_STATUS_CODE = 303;
const NOT_MODIFIED_STATUS_CODE = 304;
const USE_PROXY_STATUS_CODE = 305;
const SWITCH_PROXY_STATUS_CODE = 306;
const TEMPORARY_REDIRECT_STATUS_CODE = 307;
const PERMANENT_REDIRECT_STATUS_CODE = 308;
const BAD_REQUEST_STATUS_CODE = 400;
const UNAUTHORIZED_STATUS_CODE = 401;
const PAYMENT_REQUIRED_STATUS_CODE = 402;
const FORBIDDEN_STATUS_CODE = 403;
const NOT_FOUND_STATUS_CODE = 404;
const METHOD_NOT_ALLOWED_STATUS_CODE = 405;
const NOT_ACCEPTABLE_STATUS_CODE = 406;
const PROXY_AUTHENTICATION_REQUIRED_STATUS_CODE = 407;
const REQUEST_TIMEOUT_STATUS_CODE = 408;
const CONFLICT_STATUS_CODE = 409;
const GONE_STATUS_CODE = 410;
const LENGTH_REQUIRED_STATUS_CODE = 411;
const PRECONDITION_FAILED_STATUS_CODE = 412;
const PAYLOAD_TOO_LARGE_STATUS_CODE = 413;
const URI_TOO_LONG_STATUS_CODE = 414;
const UNSUPPORTED_MEDIA_TYPE_STATUS_CODE = 415;
const RANGE_NOT_SATISFIABLE_STATUS_CODE = 416;
const EXPECTATION_FAILED_STATUS_CODE = 417;
const I_AM_A_TEAPOT_STATUS_CODE = 418;
const MISDIRECTED_REQUEST_STATUS_CODE = 421;
const UNPROCESSABLE_ENTITY_STATUS_CODE = 422;
const LOCKED_STATUS_CODE = 423;
const FAILED_DEPENDENCY_STATUS_CODE = 424;
const UPGRADE_REQUIRED_STATUS_CODE = 426;
const PRECONDITION_REQUIRED_STATUS_CODE = 428;
const TOO_MANY_REQUESTS_STATUS_CODE = 429;
const REQUEST_HEADER_FIELDS_TOO_LARGE_STATUS_CODE = 431;
const UNAVAILABLE_FOR_LEGAL_REASONS_STATUS_CODE = 451;
const INTERNAL_SERVER_ERROR_STATUS_CODE = 500;
const NOT_IMPLEMENTED_STATUS_CODE = 501;
const BAD_GATEWAY_STATUS_CODE = 502;
const SERVICE_UNAVAILABLE_STATUS_CODE = 503;
const GATEWAY_TIMEOUT_STATUS_CODE = 504;
const HTTP_VERSION_NOT_SUPPORTED_STATUS_CODE = 505;
const VARIANT_ALSO_NEGOTIATES_STATUS_CODE = 506;
const INSUFFICIENT_STORAGE_STATUS_CODE = 507;
const LOOP_DETECTED_STATUS_CODE = 508;
const NOT_EXTENDED_STATUS_CODE = 510;
const NETWORK_AUTHENTICATION_REQUIRED_STATUS_CODE = 511;

export enum HTTP_STATUS_CODE {
  CONTINUE = CONTINUE_STATUS_CODE,
  SWITCHING_PROTOCOLS = SWITCHING_PROTOCOLS_STATUS_CODE,
  PROCESSING = PROCESSING_STATUS_CODE,
  OK = OK_STATUS_CODE,
  CREATED = CREATED_STATUS_CODE,
  ACCEPTED = ACCEPTED_STATUS_CODE,
  NON_AUTHORITATIVE_INFORMATION = NON_AUTHORITATIVE_INFORMATION_STATUS_CODE,
  NO_CONTENT = NO_CONTENT_STATUS_CODE,
  RESET_CONTENT = RESET_CONTENT_STATUS_CODE,
  PARTIAL_CONTENT = PARTIAL_CONTENT_STATUS_CODE,
  MULTI_STATUS = MULTI_STATUS_STATUS_CODE,
  ALREADY_REPORTED = ALREADY_REPORTED_STATUS_CODE,
  IM_USED = IM_USED_STATUS_CODE,
  MULTIPLE_CHOICES = MULTIPLE_CHOICES_STATUS_CODE,
  MOVED_PERMANENTLY = MOVED_PERMANENTLY_STATUS_CODE,
  FOUND = FOUND_STATUS_CODE,
  SEE_OTHER = SEE_OTHER_STATUS_CODE,
  NOT_MODIFIED = NOT_MODIFIED_STATUS_CODE,
  USE_PROXY = USE_PROXY_STATUS_CODE,
  SWITCH_PROXY = SWITCH_PROXY_STATUS_CODE,
  TEMPORARY_REDIRECT = TEMPORARY_REDIRECT_STATUS_CODE,
  PERMANENT_REDIRECT = PERMANENT_REDIRECT_STATUS_CODE,
  BAD_REQUEST = BAD_REQUEST_STATUS_CODE,
  UNAUTHORIZED = UNAUTHORIZED_STATUS_CODE,
  PAYMENT_REQUIRED = PAYMENT_REQUIRED_STATUS_CODE,
  FORBIDDEN = FORBIDDEN_STATUS_CODE,
  NOT_FOUND = NOT_FOUND_STATUS_CODE,
  METHOD_NOT_ALLOWED = METHOD_NOT_ALLOWED_STATUS_CODE,
  NOT_ACCEPTABLE = NOT_ACCEPTABLE_STATUS_CODE,
  PROXY_AUTHENTICATION_REQUIRED = PROXY_AUTHENTICATION_REQUIRED_STATUS_CODE,
  REQUEST_TIMEOUT = REQUEST_TIMEOUT_STATUS_CODE,
  CONFLICT = CONFLICT_STATUS_CODE,
  GONE = GONE_STATUS_CODE,
  LENGTH_REQUIRED = LENGTH_REQUIRED_STATUS_CODE,
  PRECONDITION_FAILED = PRECONDITION_FAILED_STATUS_CODE,
  PAYLOAD_TOO_LARGE = PAYLOAD_TOO_LARGE_STATUS_CODE,
  URI_TOO_LONG = URI_TOO_LONG_STATUS_CODE,
  UNSUPPORTED_MEDIA_TYPE = UNSUPPORTED_MEDIA_TYPE_STATUS_CODE,
  RANGE_NOT_SATISFIABLE = RANGE_NOT_SATISFIABLE_STATUS_CODE,
  EXPECTATION_FAILED = EXPECTATION_FAILED_STATUS_CODE,
  I_AM_A_TEAPOT = I_AM_A_TEAPOT_STATUS_CODE,
  MISDIRECTED_REQUEST = MISDIRECTED_REQUEST_STATUS_CODE,
  UNPROCESSABLE_ENTITY = UNPROCESSABLE_ENTITY_STATUS_CODE,
  LOCKED = LOCKED_STATUS_CODE,
  FAILED_DEPENDENCY = FAILED_DEPENDENCY_STATUS_CODE,
  UPGRADE_REQUIRED = UPGRADE_REQUIRED_STATUS_CODE,
  PRECONDITION_REQUIRED = PRECONDITION_REQUIRED_STATUS_CODE,
  TOO_MANY_REQUESTS = TOO_MANY_REQUESTS_STATUS_CODE,
  REQUEST_HEADER_FIELDS_TOO_LARGE = REQUEST_HEADER_FIELDS_TOO_LARGE_STATUS_CODE,
  UNAVAILABLE_FOR_LEGAL_REASONS = UNAVAILABLE_FOR_LEGAL_REASONS_STATUS_CODE,
  INTERNAL_SERVER_ERROR = INTERNAL_SERVER_ERROR_STATUS_CODE,
  NOT_IMPLEMENTED = NOT_IMPLEMENTED_STATUS_CODE,
  BAD_GATEWAY = BAD_GATEWAY_STATUS_CODE,
  SERVICE_UNAVAILABLE = SERVICE_UNAVAILABLE_STATUS_CODE,
  GATEWAY_TIMEOUT = GATEWAY_TIMEOUT_STATUS_CODE,
  HTTP_VERSION_NOT_SUPPORTED = HTTP_VERSION_NOT_SUPPORTED_STATUS_CODE,
  VARIANT_ALSO_NEGOTIATES = VARIANT_ALSO_NEGOTIATES_STATUS_CODE,
  INSUFFICIENT_STORAGE = INSUFFICIENT_STORAGE_STATUS_CODE,
  LOOP_DETECTED = LOOP_DETECTED_STATUS_CODE,
  NOT_EXTENDED = NOT_EXTENDED_STATUS_CODE,
  NETWORK_AUTHENTICATION_REQUIRED = NETWORK_AUTHENTICATION_REQUIRED_STATUS_CODE,
}
