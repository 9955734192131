import { Injectable } from '@angular/core';
import {
  MmAlertConfig,
  MmAlertContentAlign,
  MmAlertMode,
  MmAlertService,
  MmAlertSize,
  MmAlertType,
} from '@metromarkets/components-17';
import { ErrorResponse } from '~shared/model';

@Injectable()
export class OrderErrorService {
  private readonly defaultAlertConfig = {
    type: MmAlertType.error,
    size: MmAlertSize.large,
    mode: MmAlertMode.banner,
    duration: 4000,
    contentAlign: MmAlertContentAlign.center,
  };

  constructor(private alertService: MmAlertService) {}

  private static getErrorMsg(error: ErrorResponse) {
    try {
      const [errorData] = Object.values(error.invalidParams);
      const { reason } = errorData;

      return reason;
    } catch (e) {
      return error.detail || error.title;
    }
  }

  public showError(error: ErrorResponse) {
    const message = OrderErrorService.getErrorMsg(error);
    this.openAlert({
      type: MmAlertType.error,
      message,
    });
  }

  private openAlert(config: MmAlertConfig) {
    this.alertService.addAlert({
      ...this.defaultAlertConfig,
      ...config,
    });
  }
}
