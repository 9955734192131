import { environment } from '~env/environment';

export class ApiConfig {
  public static IMS_API_ENDPOINT = environment.svcImsBaseUrl;
  public static SELLER_INVENTORY_API_ENDPOINT = `${environment.svcSellerInventoryBaseUrl}/api/v1`;
  public static SELLER_PIM_API_ENDPOINT = `${environment.svcSellerPimBaseUrl}/api/v1`;
  public static SELLER_PIM_ADMIN_ENDPOINT = `${environment.svcSellerPimBaseUrl}/admin/v1`;
  public static SELLER_INVENTORY_ADMIN_ENDPOINT = `${environment.svcSellerInventoryBaseUrl}/admin/v1`;
  public static SELLER_OFFICE_API_ENDPOINT = `${environment.svcSellerOfficeBaseUrl}/api/v1`;
  public static SELLER_OFFICE_API_ENDPOINT_V2 = `${environment.svcSellerOfficeBaseUrl}/api/v2/admin`;
  public static SELLER_OFFICE_API_INTERNAL = `${environment.svcSellerOfficeBaseUrl}/api/internal`;
  public static SERVICE_CATEGORY_API_ENDPOINT = `${environment.svcCategoryBaseUrl}/admin/v1`;
  public static VENDOR_PORTAL_URL = `${environment.vendorPortalBaseUrl}/vendor/pim-api/v1`;
  public static ANONYMOUS_EMAILS_BASE_URL = environment.svcAnonymousEmailsBaseUrl;
  public static SERVICE_PIM_PARSING_ENDPOINT = `${environment.svcServicePimParsingBaseUrl}/admin/v1`;
  public static SERVICE_PIM_ROW_REVIEW_ENDPOINT = `${environment.svcServicePimRowReviewUrl}/admin/v1`;
  public static SERVICE_PIM_GOLDEN_RECORD_MERGE = `${environment.svcServicePimGoldenRecordMergeUrl}/admin/v1`;
  public static SERVICE_MMC_API_ENDPOINT = `${environment.svcServiceMMCUrl}/api/internal`;
}

export const IMS_CONFIG = {
  baseUrl: ApiConfig.IMS_API_ENDPOINT,
  authEndpoints: {
    login: '/accounts/auth/login',
  },
  excludedUrls: [environment.cdnBaseUrl, environment.svcCmsBaseUrl],
};

export const ICECAT_CONFIG = `${environment.icecatConfig}`;
