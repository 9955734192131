import { Field, Model } from 'serialize-ts/dist';
import { Money } from '~shared/model/money.model';
import { PaymentMethodTypes } from '~shared/model/payment-method-types.enum';

@Model()
export class Payment {
  @Field()
  totalPrice: Money;

  @Field()
  method: PaymentMethodTypes;
}
