import {
  LoadEmployeesDictionaryFailure,
  LoadEmployeesDictionarySuccess,
  EmployeeActionTypes,
  EmployeesDictionaryActions,
} from './employee.actions';
import { initialEmployeesState, EmployeeState } from './employee.state';

export function employeeReducer(state = initialEmployeesState, action: EmployeesDictionaryActions): EmployeeState {
  switch (action.type) {
    case EmployeeActionTypes.LOAD_EMPLOYEES_DICTIONARY:
      return loadEmployees(state);

    case EmployeeActionTypes.LOAD_EMPLOYEES_DICTIONARY_SUCCESS:
      return loadEmployeesSuccess(state, action);

    case EmployeeActionTypes.LOAD_EMPLOYEES_DICTIONARY_FAILURE:
      return loadEmployeesFailure(state, action);

    default:
      return state;
  }
}

function loadEmployees(state: EmployeeState): EmployeeState {
  return {
    ...state,
    loading: true,
  };
}

function loadEmployeesSuccess(state: EmployeeState, action: LoadEmployeesDictionarySuccess): EmployeeState {
  return {
    ...state,
    loading: false,
    payload: action.payload.employees,
  };
}

function loadEmployeesFailure(state: EmployeeState, action: LoadEmployeesDictionaryFailure): EmployeeState {
  return {
    ...state,
    loading: false,
    errorData: action.payload.error,
  };
}
