import { Field, Model } from 'serialize-ts/dist';

export enum AddressTypeEnum {
  TYPE_DEFAULT = 1,
  TYPE_BILLING = 2,
  TYPE_SHIPPING = 3,
}

@Model()
export class Address {
  @Field()
  country?: string;

  @Field()
  type: number;

  @Field()
  postalCode?: string;

  @Field()
  addressLine1?: string;

  @Field()
  addressLine2?: string;

  @Field()
  city?: string;

  @Field()
  firstName?: string;

  @Field()
  lastName?: string;

  @Field()
  isUsed: boolean;
}
