import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ORDERS_FEATURE_NAME } from '@sales-orders-app/modules/orders/store/feature-name';
import { State } from '@sales-orders-app/modules/orders/store';
import { PaymentInfosState } from '@sales-orders-app/modules/orders/store/tabs-payment-info';

export const getModuleFeatureState = createFeatureSelector(ORDERS_FEATURE_NAME);

export const getPaymentInfosState = createSelector(getModuleFeatureState, (state: State) => state.paymentInfo);

export const isPaymentInfoLoading = createSelector(
  getPaymentInfosState,
  (state: PaymentInfosState) => state && state.loading,
);

export const getPaymentInfos = createSelector(getPaymentInfosState, (state: PaymentInfosState) => state.payload);
