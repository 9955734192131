import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Action, select, Store } from '@ngrx/store';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterStateUrl } from '~core/store';
import { ChangeUrl } from '@root/modules/breadcrumb/store/breadcrumb/breadcrumb.actions';
import { BreadcrumbService } from '@root/modules/breadcrumb/services/breadcrumb.service';
import { BreadcrumbModel } from '@root/modules/breadcrumb/models/breadcrumb.model';
import * as fromBreadcrumb from '@root/modules/breadcrumb/store/breadcrumb/breadcrumb.selectors';
import { PRIMARY_OUTLET, Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import { SELLER_SUPPORT_ROUTE } from '~shared/constants';
import { BreadcrumbState } from '@root/modules/breadcrumb/models/breadcrumb.state';

@Injectable()
export class BreadcrumbEffects {
  currentBreadCrumb: {
    [key: string]: BreadcrumbModel;
  };

  loadBreadcrumbFromRouter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map((action: RouterNavigatedAction) => get(action, 'payload.routerState', {})),
      map(({ url, queryParams, params }: RouterStateUrl) => ({ url, queryParams, params })),
      map(({ url, queryParams, params }) => {
        const tree: UrlTree = this.router.parseUrl(url);
        const group: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const isSellerSupport = url.indexOf(SELLER_SUPPORT_ROUTE) !== -1;
        return new ChangeUrl({
          state: this.breadcrumbservice.createBreadCrumbState(
            { url, params },
            this.currentBreadCrumb,
            group?.segments || [],
            isSellerSupport,
          ),
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private breadcrumbservice: BreadcrumbService,
    private store: Store<BreadcrumbState>,
    private router: Router,
  ) {
    this.store.pipe(select(fromBreadcrumb.getBreadCrumb)).subscribe(breadCrumbs => {
      this.currentBreadCrumb = breadCrumbs;
    });
  }
}
