import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChipTypes } from './chip.types';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  @Input() type: ChipTypes;

  get chipType() {
    return this.type || ChipTypes.blue;
  }
}
