import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ORDERLINES_API } from '@sales-orders-app/modules/orderlines/constants/api';
import { TabsFulfillmentInfo } from '@sales-orders-app/modules/orders/models/tabs-fulfillment-info.model';

@Injectable({
  providedIn: 'root',
})
export class TabsFulfillmentInfoService {
  constructor(private http: HttpClient) {}

  getTabsFulfillmentInfos(orderId: string): Observable<TabsFulfillmentInfo[]> {
    return this.http.get<TabsFulfillmentInfo[]>(ORDERLINES_API).pipe();
  }
}
