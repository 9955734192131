import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@root/modules/user-auth/services/auth.service';
import { filter } from 'rxjs/operators';
import { SidenavNode } from '~core/models';
import { GaTrackingService } from '~shared/modules/ga-tracking/services/ga-tracking.service';
import { NavigationTreeService } from '~core/services/navigation-tree.service';

const SAFE_SCROLL_TOP = 65;

@Component({
  selector: 'employee-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public showSidebar = false;
  public adminPanelTreeData: SidenavNode[] = [];
  public isNavigated: boolean;
  public isLoggedIn = false;
  public scrolled = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private gaTrackingService: GaTrackingService,
    private navigationTreeService: NavigationTreeService,
  ) {
    this.authService.configureImplicitFlowAuth();
    this.gaTrackingService.startTracking();
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    this.scrolled = document.body.scrollTop > SAFE_SCROLL_TOP || document.documentElement.scrollTop > SAFE_SCROLL_TOP;
  }

  public ngOnInit(): void {
    this.subscribeToIsLoggedIn();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(this.updateNavBarVisibility.bind(this));
  }

  public logout() {
    this.authService.logout();
  }

  public get userName() {
    return this.authService.userName();
  }

  private subscribeToIsLoggedIn(): void {
    this.authService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;

      if (this.isLoggedIn) {
        this.navigationTreeService.getNavigationTree().subscribe(navigationTree => {
          this.adminPanelTreeData = navigationTree;
        });
      }
    });
  }

  private updateNavBarVisibility(): void {
    this.isNavigated = true;
  }
}
