import { Field } from 'serialize-ts';
import { ArraySerializer, Type } from 'serialize-ts/dist';
import { ModelMetadataSerializer } from 'serialize-ts/dist/serializers/model-metadata.serializer';
import { InvalidParams } from '~shared/model/invalid-params.model';

export class ErrorResponse {
  @Field()
  type: string;

  @Field()
  title: string;

  @Field()
  status: number;

  @Field()
  detail: string;

  @Field()
  @Type(new ArraySerializer(new ModelMetadataSerializer(InvalidParams)))
  invalidParams: InvalidParams[];
}
