import { environment } from '~env/environment';
import { LanguageCodes } from '../constants';

export const redirectToLanguageUrl = (code: string, url: string) => {
  let languageCode = code === LanguageCodes.de ? '' : code;
  let setAppMountingPoint = removeLeadingSlash(environment.appMountPoint);

  let featureEnvironment = (window as any).config.baseHref;

  if (
    featureEnvironment &&
    featureEnvironment.length > 3 &&
    (featureEnvironment.includes('/de') || featureEnvironment.includes('/en'))
  ) {
    featureEnvironment = featureEnvironment.slice(0, -3);
    languageCode = code;
  }
  const formatAppMountingPoint = removeLeadingTrailingSlash(featureEnvironment);
  setAppMountingPoint =
    formatAppMountingPoint === LanguageCodes.de || formatAppMountingPoint === LanguageCodes.en
      ? ''
      : formatAppMountingPoint;

  window.location.href = [window.location.origin, setAppMountingPoint, languageCode, removeLeadingSlash(url)]
    .filter(item => item)
    .join('/');
};

const removeLeadingSlash = (value: string) => {
  try {
    return value.replace(/^\/+/g, '');
  } catch (e) {
    return '';
  }
};
const removeLeadingTrailingSlash = (value: string) => {
  if (value.startsWith('/')) {
    value = value.slice(1);
  }
  if (value.endsWith('/')) {
    value = value.slice(0, -1);
  }
  return value;
};
