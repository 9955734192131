import { createSelector } from '@ngrx/store';
import { getDictionaryFeatureState } from '../selectors';

export const getOrganizationStatusesRoot = createSelector(
  getDictionaryFeatureState,
  state => state.organizationStatuses,
);

export const getOrganizationStatusesDictionaryPayload = createSelector(
  getOrganizationStatusesRoot,
  state => state.payload,
);
