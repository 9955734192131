import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable } from 'rxjs';

const LANGUAGE_HEADER = 'Accept-Language';
@Injectable()
export class L10nInterceptor implements HttpInterceptor {
  constructor(@Inject(LOCALE_ID) protected localeId: string) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const setHeaders = {
      'Content-Language': this.localeId,
    };

    if (!request.headers.has(LANGUAGE_HEADER)) {
      setHeaders[LANGUAGE_HEADER] = this.localeId;
    }

    request = request.clone({ setHeaders });

    return next.handle(request);
  }
}
