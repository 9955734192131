import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ORDERS_FEATURE_NAME } from '@sales-orders-app/modules/orders/store/feature-name';
import { State } from '@sales-orders-app/modules/orders/store';
import { BuyerInfosState } from '@sales-orders-app/modules/orders/store/tabs-buyer-info';

export const getModuleFeatureState = createFeatureSelector(ORDERS_FEATURE_NAME);

export const getBuyerInfosState = createSelector(getModuleFeatureState, (state: State) => state.tabsBuyerInfo);

export const isBuyerInfoLoading = createSelector(
  getBuyerInfosState,
  (state: BuyerInfosState) => state && state.loading,
);

export const getBuyerInfos = createSelector(getBuyerInfosState, (state: BuyerInfosState) => state.buyerInfo);

export const getBuyerOrderInfos = createSelector(getBuyerInfosState, (state: BuyerInfosState) => state.buyerOrderInfo);

export const getBuyerCdmId = createSelector(getBuyerInfosState, (state: BuyerInfosState) => state.buyerCdmId);
