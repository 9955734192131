import { HTTP_STATUS_CODE } from '~shared/model/http-status-code';

export enum FORM_ERROR_RESPONSE_TYPE {
  VALIDATION = 'validation',
}

export interface FormErrorResponse {
  detail: string;
  instance: unknown;
  invalidParams: { name: string; reason: string }[];
  status: HTTP_STATUS_CODE;
  title: string;
  type: FORM_ERROR_RESPONSE_TYPE;
}
