import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { publishReplay, refCount } from 'rxjs/operators';
import { environment } from '~env/environment';
import { CountryEntity } from '~shared/modules/dictionary/models';
import { getCountriesEntity } from '~shared/modules/dictionary/store/countries';

@Component({
  selector: 'employee-app-country-icon',
  templateUrl: './country-icon.component.html',
  styleUrls: ['./country-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryIconComponent {
  @Input() countryCode: string;
  @Input() isTooltipDisabled = false;

  public countriesEntity$ = this.store.pipe(select(getCountriesEntity), publishReplay(1), refCount());

  public get countryFlagUrl(): string {
    return `${environment.cdnBaseUrl}/images/icons/country-flag-${this.countryCode.toLowerCase()}.svg`;
  }

  constructor(private store: Store<CountryEntity>) {}
}
