import { Field, Model } from 'serialize-ts/dist';

@Model()
export class Money {
  @Field()
  amount: string;

  @Field()
  currency: string;
}
