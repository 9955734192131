import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConfigProvider } from './models/config-provider.model';
import { FeatureFlagService } from './feature-flag.service';

@NgModule({
  imports: [CommonModule],
})
export class FeatureFlagModule {
  static forRoot(configProvider: ConfigProvider): ModuleWithProviders<FeatureFlagModule> {
    return {
      ngModule: FeatureFlagModule,
      providers: [configProvider, FeatureFlagService],
    };
  }
}
