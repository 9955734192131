import { HttpErrorResponse } from '@angular/common/http';

import { Collection, ErrorResponse } from '~shared/model';
import { OriginItem } from '../../models';

export interface OriginsState {
  payload: Collection<OriginItem>;
  error: HttpErrorResponse | ErrorResponse | null;
  loading: boolean;
}

export const initialOriginsState: OriginsState = {
  payload: {
    items: [],
    totalCount: 0,
  },
  error: null,
  loading: false,
};
