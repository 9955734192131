import { HttpErrorResponse } from '@angular/common/http';
import { Employee } from '../../models';

export interface EmployeeState {
  payload: Employee[];
  errorData: HttpErrorResponse | null;
  loading: boolean;
}

export const initialEmployeesState: EmployeeState = {
  payload: [],
  errorData: null,
  loading: false,
};
