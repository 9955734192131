import { Action } from '@ngrx/store';
import { Collection, ErrorResponse, Market } from '~shared/model';

export enum MarketsActionTypes {
  LOAD_MARKETS = '[Markets] Markets Load',
  LOAD_MARKETS_SUCCESS = '[Markets] Markets Load Success',
  LOAD_MARKETS_FAILURE = '[Markets] Markets Load Failure',
}

export class LoadMarkets implements Action {
  readonly type = MarketsActionTypes.LOAD_MARKETS;
}

export class LoadMarketsSuccess implements Action {
  readonly type = MarketsActionTypes.LOAD_MARKETS_SUCCESS;

  constructor(public payload: Collection<Market>) {}
}

export class LoadMarketsFailure implements Action {
  readonly type = MarketsActionTypes.LOAD_MARKETS_FAILURE;

  constructor(public payload: { error: ErrorResponse }) {}
}

export type MarketsActions = LoadMarkets | LoadMarketsSuccess | LoadMarketsFailure;
