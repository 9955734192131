import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Collection, ErrorResponse, Market } from '~shared/model';
import { MarketsSelectorHttpService } from '../../services';
import { LoadMarketsFailure, LoadMarketsSuccess, MarketsActionTypes } from './markets.actions';

@Injectable()
export class MarketsEffects {
  loadMarkets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketsActionTypes.LOAD_MARKETS),
      switchMap(() =>
        this.httpService.loadMarkets().pipe(
          map((data: Collection<Market>) => new LoadMarketsSuccess(data)),
          catchError((error: ErrorResponse) => of(new LoadMarketsFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private httpService: MarketsSelectorHttpService) {}
}
