import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'lodash';

@Pipe({
  name: 'countryFlagName',
})
export class CountryNamesPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string | string[]): string {
    const countryName = (locale: string) => this.translateService.instant(`MARKETS_SELECTOR.COUNTRY_NAMES.${locale}`);
    let label = '';

    if (isArray(value)) {
      value.forEach(locale => (label += `<div>${countryName(locale)}<div>`));
      return label;
    }

    return this.translateService.instant(`MARKETS_SELECTOR.COUNTRY_NAMES.${value}`);
  }
}
