import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BreadcrumbEffects } from '@root/modules/breadcrumb/store/breadcrumb/breadcrumb.effects';
import { reducers } from '@root/modules/breadcrumb/store/reducers';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature('breadcrumb', reducers.breadcrumb),
    EffectsModule.forFeature([BreadcrumbEffects]),
    TranslateModule,
  ],
  exports: [BreadcrumbComponent],
  providers: [TranslatePipe],
})
export class BreadcrumbModule {}
