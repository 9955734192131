import { Action, createReducer, on } from '@ngrx/store';

import {
  orderDetailsInitialState,
  OrderDetailsState,
} from '@sales-orders-app/modules/orders/store/order-details/order-details.state';
import {
  clearOrderDetails,
  loadOrderDetails,
  loadOrderDetailsFailure,
  loadOrderDetailsSuccess,
} from './order-details.actions';

const reducer = createReducer(
  orderDetailsInitialState,
  on(loadOrderDetails, state => ({
    ...state,
    loading: true,
  })),
  on(loadOrderDetailsSuccess, (state, { orderDetails }) => ({
    ...state,
    loading: false,
    payload: orderDetails,
  })),
  on(loadOrderDetailsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    payload: null,
    error,
  })),
  on(clearOrderDetails, () => orderDetailsInitialState),
);

export function orderDetailsReducer(state: OrderDetailsState, action: Action) {
  return reducer(state, action);
}
