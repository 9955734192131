import { Type } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { deserialize } from 'serialize-ts/dist';
import { ErrorResponse, PaginatedResponse } from '~shared/model';

interface Entity {
  id: string;
}

export namespace StoreUtils {
  export function fromArrayToDictionary<T extends Entity>(array: T[]): Dictionary<T> {
    return array.reduce((entity, item) => {
      entity[item.id] = item;
      return entity;
    }, {});
  }

  export function getIdsFromArray<T extends Entity>(array: T[]): string[] {
    return array.map(item => item.id);
  }

  export function deserializePaginatedCollection<T>(collection: PaginatedResponse<T>, model: Type<T>): T[] {
    return collection.items.map(i => deserialize(i, model));
  }

  export function requestHandler<T>(state: T, props: { [key: string]: any } = {}): T {
    return {
      ...state,
      loading: true,
      error: null,
      ...props,
    };
  }

  export function successHandler<T, K>(state: T, payload: K, props: { [key: string]: any } = {}): T {
    return {
      ...state,
      loading: false,
      payload,
      ...props,
    };
  }

  export function failureHandler<T>(state: T, error: ErrorResponse, props: { [key: string]: any } = {}): T {
    return {
      ...state,
      loading: false,
      error,
      ...props,
    };
  }
}
