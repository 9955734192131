import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { MmButtonModule, MmIconModule } from '@metromarkets/components-17';

import { MmPopoverModule } from '~shared/modules/popover/popover.module';

import { ImagePreviewerComponent } from './components/image-previewer/image-previewer.component';
import { ImagePreviewModalComponent } from './components/image-preview-modal/image-preview-modal.component';

@NgModule({
  declarations: [ImagePreviewerComponent, ImagePreviewModalComponent],
  exports: [ImagePreviewerComponent, ImagePreviewModalComponent],
  imports: [CommonModule, MmIconModule, MmPopoverModule, MmButtonModule, TranslateModule],
})
export class ImagePreviewerModule {}
