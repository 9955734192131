import {
  LoadCountriesDictionaryFailure,
  LoadCountriesDictionarySuccess,
  CountriesActionTypes,
  CountriesDictionaryActions,
} from './countries.actions';
import { initialCountriesState, CountriesState } from './countries.state';

export function countriesReducer(state = initialCountriesState, action: CountriesDictionaryActions): CountriesState {
  switch (action.type) {
    case CountriesActionTypes.LOAD_COUNTRIES_DICTIONARY:
      return loadCountries(state);

    case CountriesActionTypes.LOAD_COUNTRIES_DICTIONARY_SUCCESS:
      return loadCountriesSuccess(state, action);

    case CountriesActionTypes.LOAD_COUNTRIES_DICTIONARY_FAILURE:
      return loadCountriesFailure(state, action);

    default:
      return state;
  }
}

function loadCountries(state: CountriesState): CountriesState {
  return {
    ...state,
    loading: true,
  };
}

function loadCountriesSuccess(state: CountriesState, action: LoadCountriesDictionarySuccess): CountriesState {
  return {
    ...state,
    loading: false,
    payload: action.payload.countries,
  };
}

function loadCountriesFailure(state: CountriesState, action: LoadCountriesDictionaryFailure): CountriesState {
  return {
    ...state,
    loading: false,
    errorData: action.payload,
  };
}
