import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';

import { PopoverComponent } from './components/popover.component';

@NgModule({
  declarations: [PopoverComponent],
  imports: [CommonModule, OverlayModule],
  exports: [OverlayModule],
})
export class MmPopoverModule {}

export { MmPopoverService } from './services/popover.service';
export { MmPopoverRef } from './classes/popover-ref';
export { MmPopoverCloseEvent } from './models/popover-event.interface';
export { MmPopoverConfig } from './models/popover-params.interface';
export { ScrollStrategies } from './models/popover-scroll-strategy.enum';
export { PreferablePositions } from './constants/positions.constant';
