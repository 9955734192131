import { initialState, MarketsState } from './markets.state';
import { LoadMarketsFailure, LoadMarketsSuccess, MarketsActions, MarketsActionTypes } from './markets.actions';

export function reducer(state = initialState, action: MarketsActions): MarketsState {
  switch (action.type) {
    case MarketsActionTypes.LOAD_MARKETS:
      return loadCategoryAttributes(state);

    case MarketsActionTypes.LOAD_MARKETS_SUCCESS:
      return loadCategoryAttributesSuccess(state, action);

    case MarketsActionTypes.LOAD_MARKETS_FAILURE:
      return loadCategoryAttributesFailure(state, action);

    default:
      return state;
  }
}

function loadCategoryAttributes(state: MarketsState) {
  return {
    ...state,
    loading: true,
  };
}

function loadCategoryAttributesSuccess(state: MarketsState, action: LoadMarketsSuccess) {
  return {
    ...state,
    loading: false,
    payload: action.payload,
    error: null,
  };
}

function loadCategoryAttributesFailure(state: MarketsState, action: LoadMarketsFailure) {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  };
}
