import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Action, Store, select } from '@ngrx/store';
import { isEmpty, get } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { RouterStateUrl } from '~core/store';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from '@sales-orders-app/modules/orders/constants';
import { OrdersService } from '@sales-orders-app/modules/orders/services/orders.service';
import * as fromOrders from '@sales-orders-app/modules/orders/store/orders/orders.selectors';
import {
  LoadOrders,
  LoadOrdersFailure,
  LoadOrdersSuccess,
  OrdersActionTypes,
} from '@sales-orders-app/modules/orders/store/orders/orders.actions';
import { ORDERS_ROUTE, SALES_ORDERS_ROUTE } from '~shared/constants';
import { isUrlMatchRoute } from '~shared/utils/common';
import { OrdersState } from '@sales-orders-app/modules/orders/store/orders/orders.state';

@Injectable()
export class OrdersEffects {
  displayAllOrders: boolean;

  loadOrders$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActionTypes.LOAD_ORDERS),
      map((action: LoadOrders) => action.payload),
      switchMap(({ params }) =>
        this.ordersService.getOrders(params).pipe(
          map(orders => new LoadOrdersSuccess({ orders })),
          catchError(error => of(new LoadOrdersFailure({ error }))),
        ),
      ),
    ),
  );

  loadOrdersByRouter$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map((action: RouterNavigatedAction) => get(action, 'payload.routerState', {})),
      map(({ url, queryParams }: RouterStateUrl) => ({ url, queryParams })),
      filter(({ url }) => isUrlMatchRoute(url, `${SALES_ORDERS_ROUTE}/${ORDERS_ROUTE}`)),
      map(({ queryParams }) =>
        !isEmpty(queryParams) ? queryParams : { offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT },
      ),
      filter(() => this.displayAllOrders === true),
      map(params => new LoadOrders({ params })),
    ),
  );

  constructor(private actions$: Actions, private ordersService: OrdersService, private store: Store<OrdersState>) {
    this.store.pipe(select(fromOrders.getDisplayAllOrders)).subscribe(displayAllOrders => {
      this.displayAllOrders = displayAllOrders;
    });
  }
}
