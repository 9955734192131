import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MmFormFieldModule } from '@metromarkets/components-17';
import { SearchInputComponent } from './components/search-input.component';

@NgModule({
  declarations: [SearchInputComponent],
  imports: [CommonModule, MmFormFieldModule, MatIconModule, MatButtonModule, ReactiveFormsModule],
  exports: [SearchInputComponent],
})
export class SearchInputModule {}
