import {
  LoadOrganizationStatusesDictionaryFailure,
  LoadOrganizationStatusesDictionarySuccess,
  OrganizationStatusesActionTypes,
  OrganizationStatusesDictionaryActions,
} from './organization-statuses.actions';
import { initialOrganizationStatusesState, OrganizationStatusesState } from './organization-statuses.state';

export function organizationStatusesReducer(
  state = initialOrganizationStatusesState,
  action: OrganizationStatusesDictionaryActions,
): OrganizationStatusesState {
  switch (action.type) {
    case OrganizationStatusesActionTypes.LOAD_ORGANIZATION_STATUSES_DICTIONARY:
      return loadStatuses(state);

    case OrganizationStatusesActionTypes.LOAD_ORGANIZATION_STATUSES_DICTIONARY_SUCCESS:
      return loadStatusesSuccess(state, action);

    case OrganizationStatusesActionTypes.LOAD_ORGANIZATION_STATUSES_DICTIONARY_FAILURE:
      return loadStatusesFailure(state, action);

    default:
      return state;
  }
}

function loadStatuses(state: OrganizationStatusesState): OrganizationStatusesState {
  return {
    ...state,
    loading: true,
  };
}

function loadStatusesSuccess(
  state: OrganizationStatusesState,
  action: LoadOrganizationStatusesDictionarySuccess,
): OrganizationStatusesState {
  return {
    ...state,
    loading: false,
    payload: action.payload.statuses,
  };
}

function loadStatusesFailure(
  state: OrganizationStatusesState,
  action: LoadOrganizationStatusesDictionaryFailure,
): OrganizationStatusesState {
  return {
    ...state,
    loading: false,
    errorData: action.payload.error,
  };
}
