import { createAction, props } from '@ngrx/store';
import { OrderDetails } from '@sales-orders-app/modules/orders/models';
import { ErrorResponse } from '~shared/model/error-response.model';

export const loadOrderDetails = createAction('[OrderDetails] OrderDetails load', props<{ orderId: string }>());

export const loadOrderDetailsSuccess = createAction(
  '[OrderDetails] OrderDetails load success',
  props<{ orderDetails: OrderDetails }>(),
);

export const loadOrderDetailsFailure = createAction(
  '[OrderDetails] OrderDetails load failure',
  props<{ error: ErrorResponse }>(),
);

export const clearOrderDetails = createAction('[OrderDetails] OrderDetails clear state');
