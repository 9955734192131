import { OrdersActionsUnion, OrdersActionTypes } from '@sales-orders-app/modules/orders/store/orders/orders.actions';
import { ordersInitialState, OrdersState } from '@sales-orders-app/modules/orders/store/orders/orders.state';

export function ordersReducer(state = ordersInitialState, action: OrdersActionsUnion): OrdersState {
  switch (action.type) {
    case OrdersActionTypes.LOAD_ORDERS:
      return { ...state, loading: true };
    case OrdersActionTypes.LOAD_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: { ...action.payload.orders },
        error: null,
      };
    case OrdersActionTypes.LOAD_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        payload: {
          items: [],
          totalCount: 0,
        },
        error: action.payload.error,
      };
    case OrdersActionTypes.TOGGLE_DISPLAY_ORDERS:
      return {
        ...state,
        displayAllOrders: action.payload.value,
      };
    default:
      return state;
  }
}
