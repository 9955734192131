import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../store/state';
import { getMarketsItems, LoadMarkets } from '../../store/markets';
import { Observable, Subject } from 'rxjs';
import { Market } from '~shared/model';
import { ATTRIBUTES_ROUTE, DE_MARKET, MARKET_LOCAL_STORAGE_KEY } from '~shared/constants';
import { MARKET_SELECTOR_ALLOWED_ROUTES } from '../../constants';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import isNull from 'lodash/isNull';
import { StorageService } from '@metromarkets/sdk-17';
import { MarketsService } from '../../services';

@Component({
  selector: 'employee-app-market-dropdown',
  templateUrl: './market-dropdown.component.html',
  styleUrls: ['./market-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MarketDropdownComponent implements OnInit, OnDestroy {
  @Output() toggleChanged: EventEmitter<void> = new EventEmitter();
  public isShowMarketsSelector$: Observable<boolean>;
  private markets$: Observable<Market[]> = this.store.select(getMarketsItems);
  private unsubscribe$ = new Subject();
  private storedMarket: Market = JSON.parse(localStorage.getItem(MARKET_LOCAL_STORAGE_KEY));
  public selectedMarket: Market = this.storedMarket || DE_MARKET;

  constructor(
    private marketService: MarketsService,
    private storageService: StorageService,
    private store: Store<State>,
    private router: Router,
  ) {}

  public get currentMarket(): string {
    return this.storageService.local.get(MARKET_LOCAL_STORAGE_KEY);
  }

  ngOnInit(): void {
    this.loadMarkets();
    this.setInitialMarket();
    this.isShowMarketsSelector$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map((route: NavigationEnd) => MARKET_SELECTOR_ALLOWED_ROUTES.some(v => route.url.includes(`/${v}`))),
    );
  }

  public setMarket(market = DE_MARKET): void {
    if (this.storedMarket === market) {
      return;
    }
    this.selectedMarket = market;
    localStorage.setItem(MARKET_LOCAL_STORAGE_KEY, JSON.stringify(this.selectedMarket));
    this.marketService.marketChanges(market);
    if (!this.router.url.includes(`${ATTRIBUTES_ROUTE}?sort`)) {
      window.location.reload();
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next(void 0);
    this.unsubscribe$.complete();
  }

  private loadMarkets(): void {
    this.markets$.pipe(takeUntil(this.unsubscribe$)).subscribe(items => {
      if (items.length) {
        return;
      }
      this.store.dispatch(new LoadMarkets());
    });
  }

  private setInitialMarket(): void {
    if (isNull(this.currentMarket)) {
      this.storageService.local.set(MARKET_LOCAL_STORAGE_KEY, DE_MARKET);
    }
  }
}
