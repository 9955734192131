import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NOTIFY_FRAUD_FEATURE_NAME } from '@sales-orders-app/modules/orders/store/feature-name';
import { State } from '@sales-orders-app/modules/orders/store/state';
import { NotifyFraudState } from '@sales-orders-app/modules/orders/store/notify-fraud/notify-fraud.state';

const getModuleFeatureState = createFeatureSelector(NOTIFY_FRAUD_FEATURE_NAME);

export const selectNotifyFraudState = createSelector(getModuleFeatureState, (state: State) => state.notifyFraud);

export const getNotifyFraudError = createSelector(selectNotifyFraudState, (state: NotifyFraudState) => state.error);

export const getNotifyFraudLoading = createSelector(selectNotifyFraudState, (state: NotifyFraudState) => state.loading);
