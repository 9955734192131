import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { DocumentTypeModel } from '../../models';

export enum DocumentTypesActionTypes {
  LOAD_DOCUMENT_TYPES_DICTIONARY = '[Dictionary] Document Types Load',
  LOAD_DOCUMENT_TYPES_DICTIONARY_SUCCESS = '[Dictionary] Document Types Load Success',
  LOAD_DOCUMENT_TYPES_DICTIONARY_FAILURE = '[Dictionary] Document Types Load Failure',
}

export class LoadDocumentTypesDictionary implements Action {
  readonly type = DocumentTypesActionTypes.LOAD_DOCUMENT_TYPES_DICTIONARY;
}

export class LoadDocumentTypesDictionarySuccess implements Action {
  readonly type = DocumentTypesActionTypes.LOAD_DOCUMENT_TYPES_DICTIONARY_SUCCESS;

  constructor(public payload: { types: DocumentTypeModel[] }) {}
}

export class LoadDocumentTypesDictionaryFailure implements Action {
  readonly type = DocumentTypesActionTypes.LOAD_DOCUMENT_TYPES_DICTIONARY_FAILURE;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export type DocumentTypesDictionaryActions =
  | LoadDocumentTypesDictionary
  | LoadDocumentTypesDictionarySuccess
  | LoadDocumentTypesDictionaryFailure;
