import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { ProductCategoryItemModel } from '../../models';

export enum ProductCategoriesActionTypes {
  LOAD_PRODUCT_CATEGORIES_DICTIONARY = '[Dictionary] Product Categories Load',
  LOAD_PRODUCT_CATEGORIES_DICTIONARY_SUCCESS = '[Dictionary] Product Categories Load Success',
  LOAD_PRODUCT_CATEGORIES_DICTIONARY_FAILURE = '[Dictionary] Product Categories Load Failure',
}

export class LoadProductCategoriesDictionary implements Action {
  readonly type = ProductCategoriesActionTypes.LOAD_PRODUCT_CATEGORIES_DICTIONARY;
}

export class LoadProductCategoriesDictionarySuccess implements Action {
  readonly type = ProductCategoriesActionTypes.LOAD_PRODUCT_CATEGORIES_DICTIONARY_SUCCESS;

  constructor(public payload: ProductCategoryItemModel[]) {}
}

export class LoadProductCategoriesDictionaryFailure implements Action {
  readonly type = ProductCategoriesActionTypes.LOAD_PRODUCT_CATEGORIES_DICTIONARY_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export type ProductCategoriesDictionaryActions =
  | LoadProductCategoriesDictionary
  | LoadProductCategoriesDictionarySuccess
  | LoadProductCategoriesDictionaryFailure;
