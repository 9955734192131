import {
  PaymentInfosActionsUnion,
  PaymentInfosActionTypes,
} from '@sales-orders-app/modules/orders/store/tabs-payment-info/payment-info.actions';
import {
  paymentInfosInitialState,
  PaymentInfosState,
} from '@sales-orders-app/modules/orders/store/tabs-payment-info/payment-info.state';

export function paymentInfosReducer(
  state = paymentInfosInitialState,
  action: PaymentInfosActionsUnion,
): PaymentInfosState {
  switch (action.type) {
    case PaymentInfosActionTypes.LOAD_PAYMENT_INFOS:
      return { ...state, loading: true };
    case PaymentInfosActionTypes.LOAD_PAYMENT_INFOS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: { ...action.payload },
        error: null,
      };
    case PaymentInfosActionTypes.LOAD_PAYMENT_INFOS_FAILURE:
      return {
        ...state,
        loading: false,
        payload: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
