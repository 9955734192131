import {
  TabsFulfillmentInfoInitialState,
  TabsFulfillmentInfoState,
} from '@sales-orders-app/modules/orders/store/tabs-fulfillment-info/fulfillment-info.state';
import { TabFulfillmentActionsUnion, TabFulfillmentsActionTypes } from './fulfillment-info.actions';

export function tabFulfillmentReducer(
  state = TabsFulfillmentInfoInitialState,
  action: TabFulfillmentActionsUnion,
): TabsFulfillmentInfoState {
  switch (action.type) {
    case TabFulfillmentsActionTypes.LOAD_TAB_FULLFILMENT_INFO:
      return { ...state, loading: true };
    case TabFulfillmentsActionTypes.LOAD_TAB_FULLFILMENT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
        error: null,
      };
    case TabFulfillmentsActionTypes.LOAD_TAB_FULLFILMENT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        payload: [],
        error: action.payload.error,
      };
    default:
      return state;
  }
}
