import { ErrorResponse } from '~shared/model/error-response.model';
import { BuyerCdmId, TabsBuyerInfo, TabsBuyerOrderInfo } from '@sales-orders-app/modules/orders/models';

export interface BuyerInfosState {
  buyerInfo: TabsBuyerInfo;
  buyerOrderInfo: TabsBuyerOrderInfo;
  buyerCdmId: BuyerCdmId;
  loading: boolean;
  error: ErrorResponse;
}

export const buyerInfosInitialState: BuyerInfosState = {
  buyerInfo: null,
  buyerOrderInfo: null,
  buyerCdmId: null,
  loading: false,
  error: null,
};
