import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ORDER_MANAGEMENT_BO_API } from '@sales-orders-app/modules/orderlines/constants/api';

@Injectable({
  providedIn: 'root',
})
export class ReturnReasonsService {
  constructor(private http: HttpClient) {}

  getReturnReasons() {
    return this.http.get(`${ORDER_MANAGEMENT_BO_API}/dictionary/return-reasons`);
  }
}
