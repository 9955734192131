import { Field, Model } from 'serialize-ts/dist';

@Model()
export class BuyerDetails {
  @Field()
  type: string;

  @Field()
  companyVat: string;

  @Field()
  taxNumber: string;

  @Field()
  firstName: string;

  @Field()
  lastName: string;

  @Field()
  email: string;

  @Field()
  telephone: string;

  @Field()
  account: string;

  @Field()
  id: number;

  @Field()
  cdmId: string;
}
