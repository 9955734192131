import { Order } from '@sales-orders-app/modules/orders/models/order.model';
import { PaginatedResponse } from '~shared/model';
import { ErrorResponse } from '~shared/model/error-response.model';

export interface OrdersState {
  payload: PaginatedResponse<Order>;
  loading: boolean;
  error: ErrorResponse;
  displayAllOrders: boolean;
}

export const ordersInitialState: OrdersState = {
  payload: {
    items: [],
    totalCount: 0,
  },
  loading: false,
  error: null,
  displayAllOrders: false,
};
