import { Inject, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AUTH_CONFIG } from '@root/modules/user-auth/constants';
import { AccessToken } from '@root/modules/user-auth/models';
import { environment } from '~env/environment';
import { UserRolesEnum } from '~shared/model';
import { FeatureFlagService } from '~core/modules/feature-flag';
import { FeatureToggleEnum } from '~shared/constants';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private extraLocalParameters = false;
  private readonly _isLoggedIn$ = new BehaviorSubject<boolean>(this.oAuthService.hasValidAccessToken());

  constructor(
    private oAuthService: OAuthService,
    private featureFlagService: FeatureFlagService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.featureFlagService
      .isFeatureEnabled(FeatureToggleEnum.FF_EXTRA_LOGIN_PARAMETERS)
      .subscribe(res => (this.extraLocalParameters = res));

    this.subscribeToToken();
  }

  public get isLoggedIn$(): Observable<boolean> {
    return this._isLoggedIn$.asObservable();
  }

  public get userRoles(): UserRolesEnum[] {
    return this.decodedToken.authorization.reduce(
      (values, currentValue) => [...values, ...Object.keys(currentValue)],
      [],
    );
  }

  private get decodedToken(): AccessToken {
    return jwtDecode(this.oAuthService.getAccessToken());
  }

  public getUserAvailableCountries(role: string): string[] {
    const roleArray = this.decodedToken.authorization.filter(key => key[role])[0];
    if (roleArray) {
      const roleObj = roleArray[role];
      for (const value of Object.values(roleObj)) {
        if (value.country) {
          return value.country;
        }
      }
    }
    return [];
  }

  public configureImplicitFlowAuth(): void {
    let authenticationConfigs = AUTH_CONFIG;
    const featureEnvPath = (window as any).config.baseHref;

    if (featureEnvPath && featureEnvPath !== '/') {
      const postfix = featureEnvPath.endsWith('/') ? featureEnvPath : `${featureEnvPath}/`;
      authenticationConfigs = {
        ...authenticationConfigs,
        postLogoutRedirectUri: `${authenticationConfigs.postLogoutRedirectUri}${postfix}`,
      };
    }
    this.oAuthService.configure(authenticationConfigs);
    this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (!this.oAuthService.hasValidAccessToken()) {
        const realmId = environment.idamConfig && environment.idamConfig.realmId;
        let extraLocal = '';
        if (this.extraLocalParameters) {
          extraLocal = '&locale_id=en-GB&country_code=DE';
        }
        this.oAuthService.initCodeFlow(`realm_id=${realmId}${extraLocal}`);
      }
    });
    this.oAuthService.setupAutomaticSilentRefresh();
  }

  public logout(): void {
    this.oAuthService.logOut();
  }

  public getUserUuid(): string {
    const claims = this.oAuthService.getIdentityClaims();
    if (claims === null) {
      return null;
    }
    const UNWANTED_STRING_LENGTH = 2;
    // stripping U_ from IDAM uuid. Ex : U_d9e00a59-*
    return claims['sub'].substring(UNWANTED_STRING_LENGTH);
  }

  public userName(): string {
    const claims = this.oAuthService.getIdentityClaims();
    if (claims === null) {
      return null;
    }

    return claims['email'];
  }

  private subscribeToToken(): void {
    this.oAuthService.events
      .pipe(
        filter(event => event.type === 'token_received'),
        take(1),
      )
      .subscribe(() => this._isLoggedIn$.next(this.oAuthService.hasValidAccessToken()));
  }
}
