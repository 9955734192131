import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  LoadTabsFulfillmentInfo,
  LoadTabsFulfillmentInfoFailure,
  LoadTabsFulfillmentInfoSuccess,
  TabFulfillmentsActionTypes,
} from '@sales-orders-app/modules/orders/store/tabs-fulfillment-info/fulfillment-info.actions';
import { TabsFulfillmentInfoService } from '../../services/tabs-fulfillment-info.service';

@Injectable()
export class TabsFulfillmentInfoEffects {
  loadTabsFulfillmentInfo$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TabFulfillmentsActionTypes.LOAD_TAB_FULLFILMENT_INFO),
      map((action: LoadTabsFulfillmentInfo) => action.payload),
      switchMap(({ orderId }) =>
        this.tabsFulfillmentInfoService.getTabsFulfillmentInfos(orderId).pipe(
          map(fulfillments => new LoadTabsFulfillmentInfoSuccess(fulfillments)),
          catchError(error => of(new LoadTabsFulfillmentInfoFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private tabsFulfillmentInfoService: TabsFulfillmentInfoService) {}
}
