export enum countryCodes {
  DE = 'DE',
  NL = 'NL',
  ES = 'ES',
  EN = 'EN',
  IT = 'IT',
  US = 'US',
  CA = 'CA',
  PT = 'PT',
  FR = 'FR',
}

export const COMPANY_COUNTRY_WITH_SPECIFIC_VAT_LIST: string[] = [countryCodes.NL];

export const COMPANY_COUNTRY_WITH_VISIBLE_LOCAL_TAX_LIST: string[] = [
  countryCodes.DE,
  countryCodes.NL,
  countryCodes.IT,
];

export const COMPANY_COUNTRY_WITH_REGISTRATION_NUMBER: string[] = [countryCodes.DE, countryCodes.IT];

export const COMPANY_COUNTRY_WITH_REGISTRY_COURT: string[] = [countryCodes.DE];

export const COMPANY_COUNTRY_WITH_REA: string[] = [countryCodes.IT];

export const COUNTRIES_WITH_INVOICING: string[] = [countryCodes.DE, countryCodes.ES, countryCodes.FR, countryCodes.NL];
