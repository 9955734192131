import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '~env/environment';
import { Market } from '~shared/model';
import { LANGUAGE_PICKER, LanguageCodes, LanguageNames, MARKET_LOCAL_STORAGE_KEY } from '~shared/constants';
import { DataLayerProperties } from '../models/datalayer-config.model';
import { AuthService } from '@root/modules/user-auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class GaDatalayerService {
  constructor(@Inject(DOCUMENT) private readonly document: Document, private readonly authService: AuthService) {}

  private readonly gaGtmId = environment.gaGtmId;
  private readonly GTM_DATALAYER_SCRIPT_CONTAINER = 'gtm-datalayer-script-container';
  private readonly languageNames = LanguageNames;
  private hashedUserId: string;

  public initDataLayer() {
    const userId = this.authService.getUserUuid();
    this.hash(userId).then(hashedUserId => {
      this.hashedUserId = hashedUserId;
    });

    const gtmContent =
      `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
      `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
      `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
      `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})` +
      `(window,document,'script','dataLayer','${this.gaGtmId}');`;
    const gtmUrl = `//www.googletagmanager.com/ns.html?id=${this.gaGtmId}`;

    this.loadScript(gtmContent);
    this.loadNoScriptContainer(gtmUrl);
  }

  public getDataLayerProperties(): DataLayerProperties {
    return {
      language: this.getLanguage(),
      market: this.getMarketId(),
      pagePath: this.getPagePath(),
      pageTitle: this.getPageTittle(),
      userId: this.hashedUserId,
    };
  }

  private loadScript(content: string) {
    const node = this.document.createElement('script');
    node.innerText = content;
    const gtmDataLayerScriptContainer = document.getElementById(this.GTM_DATALAYER_SCRIPT_CONTAINER);
    gtmDataLayerScriptContainer.parentNode.insertBefore(node, gtmDataLayerScriptContainer.nextSibling);
  }

  private loadNoScriptContainer(url: string) {
    const node = this.document.createElement('noscript');
    const iframe = this.document.createElement('iframe');
    const targetContainer = this.document.getElementsByTagName('body')[0];
    const firstChild = targetContainer.firstChild;

    iframe.setAttribute(`src`, url);
    iframe.setAttribute(`height`, `0`);
    iframe.setAttribute(`width`, `0`);
    iframe.setAttribute(`style`, `display:none; visibility:hidden;`);
    node.appendChild(iframe);

    targetContainer.insertBefore(node, firstChild);
  }

  private getPagePath(): string {
    return window.location.pathname;
  }

  private getPageTittle(): string {
    return window.location.pathname.split('/').pop() || '';
  }

  private getMarketId(): string {
    const market: Market = JSON.parse(localStorage.getItem(MARKET_LOCAL_STORAGE_KEY));
    return market?.id;
  }

  private getLanguage(): string {
    const currentLocale: string = JSON.parse(localStorage.getItem(LANGUAGE_PICKER)) || LanguageCodes.de;
    return this.languageNames[currentLocale];
  }

  private async hash(userId: string): Promise<string> {
    const utf8 = new TextEncoder().encode(userId);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(bytes => bytes.toString(16).padStart(2, '0')).join('');
  }
}
