import { Component, Input } from '@angular/core';

@Component({
  selector: 'employee-app-sidenav-icon',
  templateUrl: './sidenav-icon.component.html',
  styleUrls: ['./sidenav-icon.component.scss'],
})
export class SidenavIconComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() showSidebar: false;
  svgStyle: { [key: string]: string } = {
    width: '25px',
  };
}
