import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OrderDetailsService } from '@sales-orders-app/modules/orders/services/order-details.service';
import {
  notifyFraud,
  notifyFraudFailure,
  notifyFraudSuccess,
} from '@sales-orders-app/modules/orders/store/notify-fraud/notify-fraud.actions';
import { ErrorResponse } from '~shared/model';

@Injectable()
export class NotifyFraudEffects {
  notifyFraud$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(notifyFraud),
      switchMap(({ fraudPayload }) =>
        this.orderDetailsService.notifyFraud(fraudPayload).pipe(
          map(() => notifyFraudSuccess()),
          catchError((error: ErrorResponse) => {
            return of(notifyFraudFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private orderDetailsService: OrderDetailsService) {}
}
