import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BreadcrumbModel } from '@root/modules/breadcrumb/models/breadcrumb.model';
import { select, Store } from '@ngrx/store';
import * as fromBreadcrumb from '@root/modules/breadcrumb/store/breadcrumb';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { PageTitleService } from '@root/modules/breadcrumb/services/page-title.service';
import { SidenavNode } from '~core/models';
import { BreadcrumbService } from '@root/modules/breadcrumb/services/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  treeData: SidenavNode[];
  public breadcrumbs: BreadcrumbModel[] = [];

  private destroy$ = new Subject();

  constructor(
    private store: Store<BreadcrumbModel>,
    private router: Router,
    private pageTitleService: PageTitleService,
    private breadCrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(fromBreadcrumb.getBreadCrumb), takeUntil(this.destroy$)).subscribe(breadcrumbs => {
      const bcrumbs = [];
      if (!isEmpty(breadcrumbs)) {
        Object.keys(breadcrumbs).forEach(key => {
          bcrumbs.push(breadcrumbs[key]);
        });
      }
      this.breadcrumbs = [...bcrumbs].sort((a, b) => a.level - b.level);
      this.pageTitleService.setTitleFromBreadcrumb(this.breadcrumbs);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(void 0);
    this.destroy$.complete();
  }

  public navigate(url: string, navigateBlock: boolean): void {
    if (!navigateBlock) {
      this.router.navigateByUrl(url);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!isEmpty(changes['treeData']?.currentValue)){
      this.breadCrumbService.setTreeData(this.treeData);
    }
  }
}
