<mat-tree
  #tree
  [dataSource]="dataSourceNested"
  [treeControl]="nestedTreeControl"
  class="sidenav-menu"
  [ngClass]="{ 'sidenav-menu__closed': !showSidebar }"
>
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <a
      matTreeNodePadding
      [routerLink]="[node.link]"
      class="nav-item nav-container__item"
      routerLinkActive="sidenav-menu__link--active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="nav-item__title" [mmTestTarget]="'navigation-item-title'">{{ node.title | translate }}</span>
    </a>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
    <div class="mat-treenode-container" [appNavmenuController] [showSidebar]="showSidebar" [node]="node">
      <div class="mat-tree-node">
        <a
          matTreeNodeToggle
          [ngClass]="[parentClass(node), nestedTreeControl.isExpanded(node) ? 'open' : '']"
          class="nav-item nav-container__item tree-nav-item"
        >
          <employee-app-sidenav-icon
            *ngIf="node.navIcon"
            [icon]="node.navIcon"
            [title]="node.subTitle"
            [showSidebar]="showSidebar"
          >
          </employee-app-sidenav-icon>
          <div class="nav-item__title-container">
            <span class="nav-item__title" [mmTestTarget]="'navigation-item-title'">{{ node.title | translate }}</span>
            <mat-icon class="mat-icon-rtl-mirror">
              {{ nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
            </mat-icon>
          </div>
        </a>
      </div>
      <ul
        [ngClass]="{
          submenu__invisible: !nestedTreeControl.isExpanded(node),
          submenu__inner: node.level > 1,
          'submenu__inner-expanded': node.level > 1 && nestedTreeControl.isExpanded(node)
        }"
        class="submenu"
      >
        <li *ngIf="node.level === 1" class="submenu__collapsed-title">{{ node.title | translate }}</li>
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </div>
  </mat-nested-tree-node>
</mat-tree>
