import { DropdownFieldType } from './dropdown-field-type';
import { DropdownInput } from '@root/shared/model/dropdown-input.model';

export abstract class DropdownSearch extends DropdownInput {
  fieldType: DropdownFieldType;

  constructor(id: string | number, value: string, fieldType = DropdownFieldType.DROPDOWN) {
    super(id, value);
    this.fieldType = fieldType;
  }
}
