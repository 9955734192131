import { Field, Name } from 'serialize-ts/dist';

export class TabsSellerInfo {
  @Field()
  @Name('sellerName')
  organizationName: string;

  @Field()
  @Name('firstName')
  firstName: string;

  @Field()
  @Name('lastName')
  lastName: string;

  @Field()
  @Name('address')
  address: string;

  @Field()
  @Name('email')
  email: string;

  @Field()
  @Name('phoneNumber')
  phone: string;

  @Field()
  @Name('shopName')
  shopName: string;
}
