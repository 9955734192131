import { Action } from '@ngrx/store';
import { CancelOrderRequest, CancelReturnRequestModel, ReturnOrderRequest } from '../../models';
import { ErrorResponse } from '~shared/model/error-response.model';
import { RequestParams, PaginatedResponse } from '~shared/model';
import { CheckEmailRequestParam, CheckEmailSuccessResponse } from '@sales-orders-app/modules/orders/models/check-email';
import { OrderLineDetails } from '@sales-orders-app/modules/orderlines/models/orderline-detail-info.model';

export enum TabOrderlinesActionTypes {
  LOAD_TAB_ORDERLINES = '[TabsOrderline] TabsOrderline load',
  LOAD_TAB_ORDERLINES_SUCCESS = '[TabsOrderline] TabsOrderline load success',
  LOAD_TAB_ORDERLINES_FAILURE = '[TabsOrderline] TabsOrderline load failure',

  CANCEL_ORDERLINES = '[TabsOrderline] Cancel Orderlines',
  CANCEL_ORDERLINES_SUCCESS = '[TabsOrderline] Cancel Orderlines success',
  CANCEL_ORDERLINES_FAILURE = '[TabsOrderline] Cancel Orderlines failure',

  RETURN_ORDERLINES = '[TabsOrderline] Return Orderlines',
  RETURN_ORDERLINES_SUCCESS = '[TabsOrderline] Return Orderlines Success',
  RETURN_ORDERLINES_FAILURE = '[TabsOrderline] Return Orderlines Failure',

  CHECK_EMAIL = '[TabsOrderline] Check email',
  CHECK_EMAIL_SUCCESS = '[TabsOrderline] Check email success',

  CANCEL_RETURN_REQUEST = '[TabsOrderline] Cancel return request of order line',
  CANCEL_RETURN_REQUEST_SUCCESS = '[TabsOrderline] Cancel return request of order line success',
  CANCEL_RETURN_REQUEST_FAILURE = '[TabsOrderline] Cancel return request of order line failure',
}

export class LoadTabsOrderline implements Action {
  readonly type = TabOrderlinesActionTypes.LOAD_TAB_ORDERLINES;

  constructor(public payload: { params?: RequestParams; orderId: string }) {}
}

export class LoadTabsOrderlineSuccess implements Action {
  readonly type = TabOrderlinesActionTypes.LOAD_TAB_ORDERLINES_SUCCESS;

  constructor(public payload: { orderlines: PaginatedResponse<OrderLineDetails> }) {}
}

export class LoadTabsOrderlineFailure implements Action {
  readonly type = TabOrderlinesActionTypes.LOAD_TAB_ORDERLINES_FAILURE;

  constructor(public payload: { error: ErrorResponse }) {}
}

export class CancelOrderLines implements Action {
  readonly type = TabOrderlinesActionTypes.CANCEL_ORDERLINES;

  constructor(public payload: CancelOrderRequest) {}
}

export class CancelOrderLinesSuccess implements Action {
  readonly type = TabOrderlinesActionTypes.CANCEL_ORDERLINES_SUCCESS;
}

export class CancelOrderLinesFailure implements Action {
  readonly type = TabOrderlinesActionTypes.CANCEL_ORDERLINES_FAILURE;
}

export class ReturnOrderLines implements Action {
  readonly type = TabOrderlinesActionTypes.RETURN_ORDERLINES;

  constructor(public payload: ReturnOrderRequest) {}
}

export class ReturnOrderLinesSuccess implements Action {
  readonly type = TabOrderlinesActionTypes.RETURN_ORDERLINES_SUCCESS;
}

export class ReturnOrderLinesFailure implements Action {
  readonly type = TabOrderlinesActionTypes.RETURN_ORDERLINES_FAILURE;
}

export class CheckEmail implements Action {
  readonly type = TabOrderlinesActionTypes.CHECK_EMAIL;

  constructor(public payload: CheckEmailRequestParam) {}
}

export class CheckEmailSuccess implements Action {
  readonly type = TabOrderlinesActionTypes.CHECK_EMAIL_SUCCESS;

  constructor(public payload: CheckEmailSuccessResponse) {}
}

export class CancelReturnRequest implements Action {
  readonly type = TabOrderlinesActionTypes.CANCEL_RETURN_REQUEST;

  constructor(public payload: CancelReturnRequestModel) {}
}

export class CancelReturnRequestSuccess implements Action {
  readonly type = TabOrderlinesActionTypes.CANCEL_RETURN_REQUEST_SUCCESS;
}

export class CancelReturnRequestFailure implements Action {
  readonly type = TabOrderlinesActionTypes.CANCEL_RETURN_REQUEST_FAILURE;
}

export type TabOrderlinesActionsUnion =
  | LoadTabsOrderline
  | LoadTabsOrderlineSuccess
  | LoadTabsOrderlineFailure
  | CheckEmail
  | CheckEmailSuccess;
