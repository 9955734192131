import { initialRegistrationDestinationState, RegistrationDestinationState } from './registration-destination.state';
import {
  LoadRegistrationDestinationDictionaryFailure,
  LoadRegistrationDestinationDictionarySuccess,
  RegistrationDestinationActions,
  RegistrationDestinationDictionaryActionTypes,
} from './registration-destination.actions';

export function registrationDestinationReducer(
  state = initialRegistrationDestinationState,
  action: RegistrationDestinationActions,
) {
  switch (action.type) {
    case RegistrationDestinationDictionaryActionTypes.LOAD_REGISTRATION_DESTINATION_DICTIONARY:
      return loadDestinations(state);

    case RegistrationDestinationDictionaryActionTypes.LOAD_REGISTRATION_DESTINATION_DICTIONARY_SUCCESS:
      return loadDestinationsSuccess(state, action);

    case RegistrationDestinationDictionaryActionTypes.LOAD_REGISTRATION_DESTINATION_DICTIONARY_FAILURE:
      return loadDestinationsFailure(state, action);

    default:
      return state;
  }
}

function loadDestinations(state: RegistrationDestinationState): RegistrationDestinationState {
  return {
    ...state,
    loading: true,
  };
}

function loadDestinationsSuccess(
  state: RegistrationDestinationState,
  action: LoadRegistrationDestinationDictionarySuccess,
): RegistrationDestinationState {
  return {
    ...state,
    loading: false,
    payload: action.payload,
  };
}

function loadDestinationsFailure(
  state: RegistrationDestinationState,
  action: LoadRegistrationDestinationDictionaryFailure,
): RegistrationDestinationState {
  return {
    ...state,
    loading: false,
    errorData: action.payload.error,
  };
}
