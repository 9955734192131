import { DropdownFieldType } from './dropdown-field-type';
import { DropdownSearch } from './dropdown-search.model';

export class DropdownSearchRange extends DropdownSearch {
  fromKey: string;
  tillKey: string;
  constructor(id: string | number, value: string, fromKey: string, tillKey: string) {
    super(id, value, DropdownFieldType.RANGE);
    this.fromKey = fromKey;
    this.tillKey = tillKey;
  }
}
