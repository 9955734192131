import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { queryBuilder } from '@metromarkets/sdk-17';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  ORDERS_TAB_BUYER_INFO_API_V2,
  ORDERS_TAB_BUYER_ORDER_INFO_API,
} from '@sales-orders-app/modules/orders/constants/api';
import { PaginatedResponse } from '~shared/model';
import { deserialize } from 'serialize-ts';
import { BuyerCdmId, TabsBuyerInfo, TabsBuyerOrderInfo } from '@sales-orders-app/modules/orders/models';

@Injectable({
  providedIn: 'root',
})
export class BuyerInfosService {
  constructor(private http: HttpClient) {}
  private buyerInfoCache = new Map<string, Observable<TabsBuyerInfo>>();

  getBuyerInfo(buyerEmail: string): Observable<TabsBuyerInfo> {
    const filter = {
      email: buyerEmail,
    };

    const params = queryBuilder.toParams(
      queryBuilder.toParams({
        filter,
      }),
    ) as HttpParams;
    return this.http
      .get<PaginatedResponse<TabsBuyerInfo>>(ORDERS_TAB_BUYER_INFO_API_V2, {
        params,
      })
      .pipe(map(response => deserialize(response.items[0], TabsBuyerInfo)));
  }

  getBuyerOrderInfo(buyerId: string): Observable<TabsBuyerOrderInfo> {
    return this.http.get<TabsBuyerOrderInfo>(`${ORDERS_TAB_BUYER_ORDER_INFO_API}/${buyerId}`).pipe();
  }

  getBuyerCDMId(buyerId: string): Observable<BuyerCdmId> {
    return this.http
      .get<PaginatedResponse<BuyerCdmId>>(`${ORDERS_TAB_BUYER_INFO_API_V2}/${buyerId}`)
      .pipe(map(response => ({ cdmId: response.items[0].cdmId })));
  }

  getBuyerById(buyerId: string): Observable<TabsBuyerInfo> {
    const cachedResult = this.buyerInfoCache.get(buyerId);
    if (cachedResult) {
      return cachedResult;
    } else {
      const request = this.http.get<PaginatedResponse<BuyerCdmId>>(`${ORDERS_TAB_BUYER_INFO_API_V2}/${buyerId}`).pipe(
        map((response: any) => deserialize(response.items[0], TabsBuyerInfo)),
        shareReplay(1),
      );
      this.buyerInfoCache.set(buyerId, request);
      return request;
    }
  }
}
