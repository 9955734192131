import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Collection } from '~shared/model';

import {
  OrganizationStatusesActionTypes,
  LoadOrganizationStatusesDictionaryFailure,
  LoadOrganizationStatusesDictionarySuccess,
} from './organization-statuses.actions';
import { HttpDictionaryService } from '../../services/dictionaries.service';
import { OrganizationStatusModel } from '../../models';

@Injectable()
export class OrganizationStatusesEffects {
  loadOrganizationStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationStatusesActionTypes.LOAD_ORGANIZATION_STATUSES_DICTIONARY),
      mergeMap(() =>
        this.httpDictionaryService.loadOrganizationStatusesDictionary().pipe(
          map(
            (statuses: Collection<OrganizationStatusModel>) =>
              new LoadOrganizationStatusesDictionarySuccess({ statuses: statuses.items }),
          ),
          catchError(error => of(new LoadOrganizationStatusesDictionaryFailure(error))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private httpDictionaryService: HttpDictionaryService) {}
}
