import { Field, Model, Serializer, Type, ArraySerializer } from 'serialize-ts/dist';

class BuyerAddressTypeSerializer implements Serializer<BuyerAddressType> {
  deserialize(type: BuyerAddressTypeEnum): BuyerAddressType | null {
    const addressTypeMap = new Map<BuyerAddressTypeEnum, BuyerAddressType>([
      [BuyerAddressTypeEnum.SHIPPING_ADDRESS, BuyerAddressType.SHIPPING_ADDRESS],
      [BuyerAddressTypeEnum.BILLING_ADDRESS, BuyerAddressType.BILLING_ADDRESS],
    ]);
    return addressTypeMap.get(type);
  }

  serialize(model: BuyerAddressType): Object | null {
    return undefined;
  }
}
enum BuyerAddressType {
  SHIPPING_ADDRESS = 'Shipping',
  BILLING_ADDRESS = 'Billing',
}
export enum BuyerAddressTypeEnum {
  SHIPPING_ADDRESS = 'SHIPPING_ADDRESS',
  BILLING_ADDRESS = 'BILLING_ADDRESS',
}
@Model()
export class BuyerAddress {
  @Field()
  country?: string;

  @Field()
  @Type(new ArraySerializer(new BuyerAddressTypeSerializer()))
  type: BuyerAddressType[];

  @Field()
  postalCode?: string;

  @Field()
  addressLine1?: string;

  @Field()
  addressLine2?: string;

  @Field()
  city?: string;

  @Field()
  firstName?: string;

  @Field()
  lastName?: string;

  @Field()
  isUsed: boolean;
}
