import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ORDERS_TAB_SELLER_INFO_API, AFTERSALES_ADMIN_API } from '@sales-orders-app/modules/orders/constants/api';
import { TabsSellerInfo } from '@sales-orders-app/modules/orders/models/tabs-seller-info.model';
import { OrderDetails } from '@sales-orders-app/modules/orders/models';
import { switchMap, map } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '~env/environment';
import { FeatureFlagService } from '~core/modules/feature-flag';
import { FeatureToggleEnum } from '~shared/constants';

@Injectable({
  providedIn: 'root',
})
export class TabsSellerInfoService {
  isIDAMAuthFeatureFlagEnabled$: Observable<boolean> = this.featureFlagService.isFeatureEnabled(
    FeatureToggleEnum.FF_CCS_BO_AFTERSALES_V2_ADMIN_IDAM_AUTHENTICATED,
  );

  constructor(
    private http: HttpClient,
    private oAuthService: OAuthService,
    private featureFlagService: FeatureFlagService
  ) {}

  getTabsSellerInfo(order: OrderDetails): Observable<TabsSellerInfo[]> {
    return this.isIDAMAuthFeatureFlagEnabled$.pipe(
      switchMap(isFFEnabled => {
        let api_endpoint = `${ORDERS_TAB_SELLER_INFO_API}`;
        let headers = this.requestHeader(`Basic ${btoa(`${environment.svcAftersalesBasicAuthUser}:${environment.svcAftersalesBasicAuthPass}`)}`);

        if (isFFEnabled) {
          api_endpoint = `${AFTERSALES_ADMIN_API}/sellers`;
          headers = this.requestHeader(`Bearer ${this.oAuthService.getAccessToken()}`);
        }

        return this.http.get<TabsSellerInfo[]>(`${api_endpoint}?filter[orderNumber]=${order.orderNumber}`, { headers });
      }),
      map(sellerInfo => {
        const data = sellerInfo['items'].map(response => {
          const sellerAddress = response.addresses.filter(x => x.type === 'Default')[0];
          const contactPersons = response.contactPersons[0];
          const { addressLine1, addressLine2, city, country, postalCode } = sellerAddress;
          const address = `${addressLine1 ? `${addressLine1}, ` : ''}` +
            `${addressLine2 ? `${addressLine2}, ` : ''}` +
            `${city ? `${city}, ` : ''}` +
            `${country ? `${country}, ` : ''}` +
            `${postalCode ? `${postalCode}` : ''}`;
          return {
            organizationName: response?.name,
            firstName: contactPersons?.firstName,
            lastName: contactPersons?.lastName,
            phone: contactPersons?.phone,
            email: contactPersons?.email,
            address: address || 'N/A',
            shopName: response?.shopName,
          } as TabsSellerInfo;
        });
        return data as TabsSellerInfo[];
      }),
    );
  }

  private requestHeader(auth: string = ''): HttpHeaders {
    return new HttpHeaders({
      Authorization: auth,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }
}
