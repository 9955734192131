export const FILTER_TITLES = {
  orderNumber: 'Order Number',
  orderStatus: 'Status',
  orderStartDate: 'Start Date',
  orderEndDate: 'End Date ',
  orderFromValue: 'Order Value (min)',
  orderTillValue: 'Order Value (max)',
  'buyer.publicId': 'Buyer Id',
  'buyer.name': 'Buyer Name',
  'buyer.email': 'Buyer E-mail',
  'buyer.telephone': 'Buyer Telephone',
  orderAddress: 'Shipment Address',
  salesChannelCountry: 'Sales Channel',
};
