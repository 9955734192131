import { Pipe, PipeTransform } from '@angular/core';
import { Market, PropertiesTranslation } from '~shared/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import isUndefined from 'lodash/isUndefined';
import { MarketsService } from '../../services/markets.service';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_TRANSLATION_LANGUAGE } from '~shared/constants';

@Pipe({
  name: 'translateProperty',
})
export class TranslatePropertyPipe<T> implements PipeTransform {
  constructor(private marketsService: MarketsService) {}

  transform(translations: PropertiesTranslation<T>[], property: string): Observable<string> {
    return this.marketsService.getCurrentMarket().pipe(
      map((market: Market) => {
        const translation = translations.find(item => item.lang === market?.lang.id);

        if (isUndefined(translation)) {
          return '';
        }

        if (isEmpty(translation.properties[property])) {
          const englishTranslation = translations.find(item => item.lang === DEFAULT_TRANSLATION_LANGUAGE);
          return englishTranslation.properties[property];
        }

        return translation.properties[property];
      }),
    );
  }
}
