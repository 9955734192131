import { NestedTreeControl } from '@angular/cdk/tree';
import { Location } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatTree, MatTreeNestedDataSource } from '@angular/material/tree';
import { SidenavNode } from '~core/models/sidenav.interface';

const SIDENAV_MENU_TREE_ACTIVE = 'sidenav-menu__tree--active level-';
const MIN_NODE_LEVEL_AUTO_EXPAND = 1;

@Component({
  selector: 'employee-app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
})
export class SidenavMenuComponent implements AfterViewInit, OnChanges {
  @ViewChild('tree', { static: true }) tree: MatTree<SidenavNode>;
  @Input() treeData: SidenavNode[];
  @Input() showSidebar: boolean;

  nestedTreeControl = new NestedTreeControl<SidenavNode>((node: SidenavNode) => node.children);

  dataSourceNested = new MatTreeNestedDataSource<SidenavNode>();
  mappedTreeData: SidenavNode[];
  nestedTreeLevel = 0;

  constructor(private locationS: Location) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.treeData && this.treeData.length > 0) {
      this.mappedTreeData = this.mapNestedTreeData(this.treeData, 1);
      this.dataSourceNested.data = this.mappedTreeData;
    }
    if (!this.showSidebar) {
      this.expandAll(this.dataSourceNested.data);
    }
  }

  ngAfterViewInit() {
    this.expand(this.dataSourceNested.data, this.getCurrentPath());
  }

  isActive(node: SidenavNode): boolean {
    for (const child of node.children) {
      if (location.pathname.includes(child.link)) {
        return true;
      }
    }
    return false;
  }

  parentClass(node): string {
    if (this.isActive(node)) {
      this.tree.treeControl.expand(node);
      return SIDENAV_MENU_TREE_ACTIVE + node.level;
    }

    return '';
  }

  hasNestedChild = (_: number, nodeData: SidenavNode) => nodeData.expandable;

  private getCurrentPath(): string {
    const fullPathWithParams = this.locationS.path();
    const URL_PLACE = 0;
    return fullPathWithParams.split('?')[URL_PLACE];
  }

  private expand(data: SidenavNode[], link: string): void {
    data.forEach(node => {
      if (node.children && node.children.find(c => c.link === link)) {
        this.tree.treeControl.expand(node);
      } else if (node.children && node.children.find(c => c.children)) {
        this.expand(node.children, link);
      }
    });
  }

  private expandAll(data: SidenavNode[]): void {
    data.forEach(node => {
      if (node.children && node.level > MIN_NODE_LEVEL_AUTO_EXPAND) {
        this.nestedTreeControl.expand(node);
      }
      if (node.children) {
        this.expandAll(node.children);
      }
    });
  }

  private mapNestedTreeData(arr: SidenavNode[], level = 0): SidenavNode[] {
    return arr.map(item => {
      if (item.children && item.children.length > 0) {
        return {
          ...item,
          expandable: true,
          level,
          children: this.mapNestedTreeData(item.children, level + 1),
        };
      }
      return { ...item, level, expandable: false };
    });
  }
}
