import { Field, Model, Name } from 'serialize-ts';

@Model()
export class OrderlineExpectedDeliveryPeriod {
  @Field()
  @Name('start')
  start: string;

  @Field()
  @Name('end')
  end: string;
}
