import { Field, Model, Name } from 'serialize-ts';

@Model()
export class OrderlineCancellationReason {
  @Field()
  @Name('title')
  title: string;

  @Field()
  @Name('value')
  value: number;
}
