import { DropdownFieldType } from './dropdown-field-type';
import { DropdownSearch } from './dropdown-search.model';
import { DropdownInput } from '@root/shared/model/dropdown-input.model';

export class DropdownSearchDropdown extends DropdownSearch {
  dropdownOptions: DropdownInput[];
  multiple: boolean;

  constructor(id: string | number, value: string, dropdownOptions: DropdownInput[] = [], multiple?: boolean) {
    super(id, value, DropdownFieldType.DROPDOWN);
    this.dropdownOptions = dropdownOptions;
    this.multiple = multiple;
  }
}
