import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { LANGUAGE_PICKER, LanguageCodes } from '~shared/constants';
import { redirectToLanguageUrl } from '~shared/utils/redirect-to-language-url';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class LanguagePickerService {
  private languageCodes = LanguageCodes;
  private currentLanguage$ = new BehaviorSubject<string>(this.languageCodes.de);
  private languagePicker = LANGUAGE_PICKER;
  private languageCode: string;

  constructor(private router: Router, private translateService: TranslateService) {}

  public getLanguage(): Observable<string> {
    if (!this.languageCode) {
      const languageCode = JSON.parse(localStorage.getItem(this.languagePicker));
      if (languageCode) {
        this.languageCode = languageCode;
        this.currentLanguage$.next(this.languageCode);
        this.translateService.use(this.languageCode);
      }
    }

    return this.currentLanguage$;
  }

  public setLanguage(languageCode: string): void {
    if (languageCode) {
      localStorage.setItem(this.languagePicker, JSON.stringify(languageCode));
      this.currentLanguage$.next(languageCode);
      this.languageCode = languageCode;
    }
  }

  public redirectToDefaultLanguageUrl(languageCode: string): void {
    redirectToLanguageUrl(languageCode, this.router.url);
  }
}
