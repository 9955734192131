import { Field, Name, Type, ArraySerializer } from 'serialize-ts/dist';
import { ModelMetadataSerializer } from 'serialize-ts/dist/serializers/model-metadata.serializer';
import { OrderlineExpectedDeliveryPeriod } from '@sales-orders-app/modules/orderlines/models/orderline-expected-delivery-period';
import { Money } from '~shared/model';
import { OrderlineCancellationReason } from '@sales-orders-app/modules/orderlines/models/orderline-cancellation-reason.model';
import { OrderlineReturnReason } from '@sales-orders-app/modules/orderlines/models/orderline-return-reason';

export class FinancialDetailPerItem {
  @Field()
  @Name('originalPriceNet')
  originalPriceNet: Money;

  @Field()
  @Name('originalPriceGross')
  originalPriceGross: Money;

  @Field()
  @Name('priceNet')
  priceNet: Money;

  @Field()
  @Name('priceGross')
  priceGross: Money;

  @Field()
  @Name('shippingNet')
  shippingNet: Money;

  @Field()
  @Name('shippingGross')
  shippingGross: Money;
}

export class FinancialDetailTotals {
  @Field()
  @Name('originalPriceNet')
  originalPriceNet: Money;

  @Field()
  @Name('originalPriceGross')
  originalPriceGross: Money;

  @Field()
  @Name('priceNet')
  priceNet: Money;

  @Field()
  @Name('priceGross')
  priceGross: Money;

  @Field()
  @Name('totalNet')
  totalNet: Money;

  @Field()
  @Name('totalGross')
  totalGross: Money;

  @Field()
  @Name('shippingNet')
  shippingNet: Money;

  @Field()
  @Name('shippingGross')
  shippingGross: Money;
}

export class AssistedSales {
  @Field()
  @Name('employeeId')
  employeeId: string;

  @Field()
  @Name('email')
  email: string;
}

export class Promotion {
  @Field()
  @Name('code')
  code: string;

  @Field()
  @Name('type')
  type: string;

  @Field()
  @Name('gross')
  gross: Money;

  @Field()
  @Name('net')
  net: Money;
}

export class File {
  @Field()
  @Name('mimeType')
  mimeType: string;

  @Field()
  @Name('name')
  name: string;

  @Field()
  @Name('fileId')
  fileId: string;
}

export class Document {
  @Field()
  @Type(new ModelMetadataSerializer(File))
  @Name('file')
  file: File;

  @Field()
  @Name('type.value')
  type: string;
}

export class Carrier {
  @Field()
  @Name('name')
  name: string;

  @Field()
  @Name('carrierId')
  carrierId?: string;
}

export class Tracking {
  @Field()
  @Name('trackingId')
  trackingId: string;

  @Field()
  @Name('trackingUrl')
  trackingUrl?: string;

  @Field()
  @Name('trackingCarrierId')
  trackingCarrierId?: string;

  @Field()
  @Name('carrier')
  @Type(new ModelMetadataSerializer(Carrier))
  carrier?: Carrier;

  @Field()
  @Name('status')
  status?: string;

  @Field()
  @Name('deliveredAt')
  deliveredAt?: string;
}

export class OrderLineDetails {
  anonymousEmailLink?: string;

  @Field()
  @Name('orderNumber')
  orderNumber: string;

  @Field()
  @Name('orderLineId')
  orderLineId: string;

  @Field()
  @Name('status')
  status: number;

  @Field()
  @Name('createdAt')
  createdAt: string;

  @Field()
  @Name('shippingType')
  shippingType: string;

  @Field()
  @Name('gtin')
  gtin?: string;

  @Field()
  @Name('productUuid')
  productUuid: string;

  @Field()
  @Name('categoryId')
  categoryId: string;



  @Field()
  @Name('productId')
  productId: string;

  @Field()
  @Name('productName')
  productName: string;

  @Field()
  @Name('quantity')
  quantity: number;

  @Field()
  @Name('financialDetailPerItem')
  @Type(new ModelMetadataSerializer(FinancialDetailPerItem))
  financialDetailPerItem: FinancialDetailPerItem;

  @Field()
  @Name('isShippingThresholdReached')
  isShippingThresholdReached: boolean;

  @Field()
  @Name('financialDetailTotals')
  @Type(new ModelMetadataSerializer(FinancialDetailTotals))
  financialDetailTotals: FinancialDetailTotals;

  @Field()
  @Name('sellerName')
  sellerName: string;

  @Field()
  @Name('sellerOrganizationId')
  sellerOrganizationId: string;

  @Field()
  @Name('sellerAccountId')
  sellerAccountId: string;

  @Field()
  @Name('sellerEmail')
  sellerEmail?: string;

  @Field()
  @Name('buyerFirstName')
  buyerFirstName: string;

  @Field()
  @Name('buyerLastName')
  buyerLastName: string;

  @Field()
  @Name('origin')
  origin: string;

  @Field()
  @Name('destination')
  destination: string;

  @Field()
  @Name('expectedDeliveryPeriod')
  expectedDeliveryPeriod?: OrderlineExpectedDeliveryPeriod;

  @Field()
  @Name('referenceLine')
  referenceLine?: string;

  @Field()
  @Name('assistedSales')
  @Type(new ModelMetadataSerializer(AssistedSales))
  assistedSales?: AssistedSales = null;

  @Field()
  @Name('documents')
  @Type(new ArraySerializer(new ModelMetadataSerializer(Document)))
  documents?: Document[];

  @Field()
  @Type(new ArraySerializer(new ModelMetadataSerializer(Promotion)))
  @Name('promotions')
  promotions?: Promotion[];

  @Field()
  @Name('cancellationReason')
  cancellationReason?: OrderlineCancellationReason;

  @Field()
  @Name('returnReason')
  returnReason?: OrderlineReturnReason;

  @Field()
  @Name('returnResolution')
  returnResolution?: string;

  @Field()
  @Name('returnedBy')
  returnedBy?: string;

  @Field()
  @Name('trackingDto')
  @Type(new ModelMetadataSerializer(Tracking))
  trackingDto?: Tracking;

  @Field()
  @Name('isReturnable')
  isReturnable: boolean;
}

export class OrderLineWarrantyDetails {
  @Field()
  @Name('id')
  orderLineId: string;

  @Field()
  @Name('gtin')
  Gtin: string;

  @Field()
  @Name('product_name')
  ProductName: number;

  @Field()
  @Name('description')
  Description: string;

  @Field()
  @Name('weight')
  Weight: number;

  @Field()
  @Name('height')
  Height: string;

  @Field()
  @Name('length')
  Length: string;

  @Field()
  @Name('country_code')
  Country: string;

  @Field()
  @Name('is_eligible')
  Eligiblility: boolean;

  @Field()
  @Name('valid_until')
  ValidUntil: string;

  @Field()
  @Name('man_handling')
  ManHandling: string;

  @Field()
  @Name('disabled_at')
  DisabledAt: string;

  @Field()
  @Name('vendor_price')
  VendorPrice: number;

  @Field()
  @Name('id_service_provider')
  ServiceProviderId: string;


  @Field()
  @Name('service_provider_name')
  ServiceProviderName: string;

  @Field()
  @Name('service_provider_repair_ticket_url')
  ServiceProviderRepairTicketUrl: string;
}

export class OrderLineDropShipperDetails {
  @Field()
  @Name('name')
  name: string;

  @Field()
  @Name('type')
  type: string;

  @Field()
  @Name('email')
  email: number;
}
