import { AttributeType } from './attribute-types';

export const ATTRIBUTE_TYPE_TRANSLATIONS = {
  [AttributeType.LIST_OF_VALUES]: 'PRODUCT_ATTRIBUTES.ATTRIBUTE_TYPE.LIST_OF_VALUES.TITLE',
  [AttributeType.BOOLEAN]: 'PRODUCT_ATTRIBUTES.ATTRIBUTE_TYPE.BOOLEAN.TITLE',
  [AttributeType.TEXT]: 'PRODUCT_ATTRIBUTES.ATTRIBUTE_TYPE.TEXT.TITLE',
  [AttributeType.INTEGER]: 'PRODUCT_ATTRIBUTES.ATTRIBUTE_TYPE.INTEGER.TITLE',
  [AttributeType.FILE]: 'PRODUCT_ATTRIBUTES.ATTRIBUTE_TYPE.FILE.TITLE',
  [AttributeType.DECIMAL]: 'PRODUCT_ATTRIBUTES.ATTRIBUTE_TYPE.DECIMAL.TITLE',
  [AttributeType.IMAGE]: 'PRODUCT_ATTRIBUTES.ATTRIBUTE_TYPE.IMAGE.TITLE',
  [AttributeType.LONG_TEXT]: 'PRODUCT_ATTRIBUTES.ATTRIBUTE_TYPE.LONG_TEXT.TITLE',
};
