import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  CancelOrderLines,
  CancelOrderLinesFailure,
  CancelOrderLinesSuccess,
  ReturnOrderLinesSuccess,
  ReturnOrderLinesFailure,
  LoadTabsOrderline,
  LoadTabsOrderlineFailure,
  LoadTabsOrderlineSuccess,
  ReturnOrderLines,
  TabOrderlinesActionTypes,
  CheckEmailSuccess,
  CheckEmail,
  CancelReturnRequest,
  CancelReturnRequestSuccess,
  CancelReturnRequestFailure,
} from '@sales-orders-app/modules/orders/store/tabs-orderline/tabs-orderline.actions';
import { TabsOrderlineService } from '../../services/tabs-orderline.service';
import { RequestParams } from '~shared/model';
import { OrderErrorService } from '@sales-orders-app/modules/orders/services/order-error.service';
import { CancelOrderRequest, CancelReturnRequestModel } from '@sales-orders-app/modules/orders/models';
import { CheckEmailRequestParam, ThreadEmail } from '@sales-orders-app/modules/orders/models/check-email';
import { TabsOrderlineState } from '@sales-orders-app/modules/orders/store/tabs-orderline/tabs-orderline.state';
import { ANONYMOUS_EMAIL_THREAD_PREFIX } from '@sales-orders-app/modules/orders/constants/tab-orderline-details';
import { environment } from '~env/environment';

@Injectable()
export class TabsOrderlineEffects {
  private currentParams: RequestParams;
  private orderId: string;

  loadTabsOrderline$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TabOrderlinesActionTypes.LOAD_TAB_ORDERLINES),
      map((action: LoadTabsOrderline) => action.payload),
      switchMap(({ params, orderId }) =>
        this.tabsOrderlineService.getTabsOrderlines(params, orderId).pipe(
          map(orderlines => {
            this.currentParams = params;
            this.orderId = orderId;
            const { orderNumber } = orderlines.items[0];
            const sellerIds = [
              ...new Set(
                orderlines.items
                  .filter(item => item.sellerOrganizationId !== environment.metroSellerId)
                  .map(item => item.sellerAccountId),
              ),
            ];
            sellerIds.forEach(sellerId => {
              this.store.dispatch(
                new CheckEmail({
                  orderNumber,
                  threadNumber: sellerId,
                }),
              );
            });
            return new LoadTabsOrderlineSuccess({ orderlines });
          }),
          catchError(error => of(new LoadTabsOrderlineFailure({ error }))),
        ),
      ),
    ),
  );

  cancelOrderLines$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TabOrderlinesActionTypes.CANCEL_ORDERLINES),
      map((action: CancelOrderLines) => action.payload),
      switchMap((payload: CancelOrderRequest) =>
        this.tabsOrderlineService.cancelOrderLines(payload).pipe(
          switchMap(() => [
            new CancelOrderLinesSuccess(),
            new LoadTabsOrderline({ params: this.currentParams, orderId: this.orderId }),
          ]),
          catchError(error => {
            this.errorService.showError(error);
            return of(new CancelOrderLinesFailure());
          }),
        ),
      ),
    ),
  );

  returnOrderLines$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TabOrderlinesActionTypes.RETURN_ORDERLINES),
      map((action: ReturnOrderLines) => action.payload),
      switchMap(orderLines =>
        this.tabsOrderlineService.returnOrderLines(orderLines).pipe(
          map(() => new LoadTabsOrderline({ params: this.currentParams, orderId: this.orderId })),
          switchMap(() => [
            new ReturnOrderLinesSuccess(),
            new LoadTabsOrderline({ params: this.currentParams, orderId: this.orderId }),
          ]),
          catchError(error => {
            this.errorService.showError(error);
            return of(new ReturnOrderLinesFailure());
          }),
        ),
      ),
    ),
  );

  checkEmails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TabOrderlinesActionTypes.CHECK_EMAIL),
      map((action: CheckEmail) => action.payload),
      mergeMap((params: CheckEmailRequestParam) =>
        this.tabsOrderlineService.checkEmails(params).pipe(
          map(
            (emails: ThreadEmail) =>
              new CheckEmailSuccess({
                email: emails.emails > 0 ? `${ANONYMOUS_EMAIL_THREAD_PREFIX}${params.orderNumber}` : '',
                sellerId: params.threadNumber,
              }),
          ),
          catchError(error => of(new LoadTabsOrderlineFailure({ error }))),
        ),
      ),
    ),
  );

  cancelReturnRequest$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(TabOrderlinesActionTypes.CANCEL_RETURN_REQUEST),
      map((action: CancelReturnRequest) => action.payload),
      switchMap((payload: CancelReturnRequestModel) =>
        this.tabsOrderlineService.cancelReturnRequest(payload).pipe(
          switchMap(() => [
            new CancelReturnRequestSuccess(),
            new LoadTabsOrderline({ params: this.currentParams, orderId: this.orderId }),
          ]),
          catchError(error => {
            this.errorService.showError(error);
            return of(new CancelReturnRequestFailure());
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private tabsOrderlineService: TabsOrderlineService,
    private errorService: OrderErrorService,
    private store: Store<TabsOrderlineState>,
  ) {}
}
