import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Collection } from '~shared/model';
import { HttpDictionaryService } from '../../services/dictionaries.service';
import { OriginItem } from '../../models';
import { LoadOriginsDictionaryFailure, LoadOriginsDictionarySuccess, OriginsActionTypes } from './origins.actions';

@Injectable()
export class OriginsEffects {
  loadOriginsDictionary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OriginsActionTypes.LOAD_ORIGINS_DICTIONARY),
      switchMap(() =>
        this.httpDictionaryService.loadOriginsDictionary().pipe(
          map((res: Collection<OriginItem>) => new LoadOriginsDictionarySuccess(res)),
          catchError(error => of(new LoadOriginsDictionaryFailure(error))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private httpDictionaryService: HttpDictionaryService) {}
}
