import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryIconModule } from '~shared/modules/country-icon/country-icon.module';
import { MarketDropdownComponent } from './components';
import { StoreModule } from '@ngrx/store';
import { MARKETS_SELECTOR_FEATURE_NAME } from './constants';
import { EffectsModule } from '@ngrx/effects';
import { MarketsEffects } from './store/markets';
import { MatTabsModule } from '@angular/material/tabs';
import { MmIconModule } from '@metromarkets/components-17';
import { TranslateModule } from '@ngx-translate/core';
import { QaLocatorsModule } from '@metromarkets/sdk-17';
import { TranslatePropertyPipe } from './pipes';
import { NgSelectModule } from '@ng-select/ng-select';
import { reducers } from './store/reducer';
import { FormsModule } from '@angular/forms';
import { CountryNamesModule } from '@root/shared/pipes/country-names/country-names.module';

@NgModule({
  declarations: [MarketDropdownComponent, TranslatePropertyPipe],
  imports: [
    CommonModule,
    StoreModule.forFeature(MARKETS_SELECTOR_FEATURE_NAME, reducers),
    EffectsModule.forFeature([MarketsEffects]),
    MatTabsModule,
    MmIconModule,
    TranslateModule,
    QaLocatorsModule,
    CountryIconModule,
    NgSelectModule,
    FormsModule,
    CountryNamesModule,
  ],
  exports: [MarketDropdownComponent, TranslatePropertyPipe],
})
export class MarketsSelectorModule {}
