import { HttpErrorResponse } from '@angular/common/http';
import { DocumentTypeModel } from '../../models';

export interface DocumentTypesState {
  payload: DocumentTypeModel[];
  errorData: HttpErrorResponse | null;
  loading: boolean;
}

export const initialDocumentTypesState: DocumentTypesState = {
  payload: [],
  errorData: null,
  loading: false,
};
