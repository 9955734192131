import { ConnectedOverlayPositionChange, OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';

import { MmPopoverCloseEvent } from '../models/popover-event.interface';
import { MmPopoverContent } from '../models/popover-content.types';

export class MmPopoverRef<T = any> {
  private readonly afterClosed = new Subject<MmPopoverCloseEvent<T>>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  afterClosed$ = this.afterClosed.asObservable();

  constructor(
    public overlay: OverlayRef,
    public content: MmPopoverContent,
    public data: T,
    private readonly closeOnBackdropClick = true,
    public positionChanged?: Observable<ConnectedOverlayPositionChange | null>,
  ) {
    overlay.backdropClick().subscribe(() => {
      if (this.closeOnBackdropClick) {
        this._close('backdropClick', null);
      }
    });
  }

  close(data?: T) {
    this._close('close', data);
  }

  getOverlayClientRect() {
    return this.overlay.overlayElement.getBoundingClientRect();
  }

  private _close(type: MmPopoverCloseEvent['type'], data?: T) {
    this.overlay.dispose();
    this.afterClosed.next({
      type,
      data,
    });
    this.afterClosed.complete();
  }
}
