export enum AttributeType {
  UNKNOWN,
  LIST_OF_VALUES,
  BOOLEAN,
  TEXT,
  INTEGER,
  FILE,
  DECIMAL,
  IMAGE,
  LONG_TEXT,
}
