import { initialState, MultiMarketState } from './multi-market.state';
import {
  LoadMultiMarketFailure,
  LoadMultiMarketSuccess,
  MultiMarketActions,
  MultiMarketActionTypes,
} from './multi-market.actions';

export function reducer(state = initialState, action: MultiMarketActions): MultiMarketState {
  switch (action.type) {
    case MultiMarketActionTypes.LOAD_MULTI_MARKETS:
      return loadMarkets(state);

    case MultiMarketActionTypes.LOAD_MULTI_MARKETS_SUCCESS:
      return loadMarketsSuccess(state, action);

    case MultiMarketActionTypes.LOAD_MULTI_MARKETS_FAILURE:
      return loadMarketsFailure(state, action);

    default:
      return state;
  }
}

function loadMarkets(state: MultiMarketState) {
  return {
    ...state,
    loading: true,
  };
}

function loadMarketsSuccess(state: MultiMarketState, action: LoadMultiMarketSuccess) {
  return {
    ...state,
    loading: false,
    payload: action.payload,
    error: null,
  };
}

function loadMarketsFailure(state: MultiMarketState, action: LoadMultiMarketFailure) {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  };
}
