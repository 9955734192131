import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Collection } from '~shared/model';
import { Employee } from '../../models';
import { HttpDictionaryService } from '../../services/dictionaries.service';
import {
  EmployeeActionTypes,
  LoadEmployeesDictionaryFailure,
  LoadEmployeesDictionarySuccess,
} from './employee.actions';

@Injectable()
export class EmployeeEffects {
  loadEmployees = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActionTypes.LOAD_EMPLOYEES_DICTIONARY),
      switchMap(() =>
        this.httpDictionaryService.loadEmployeeDictionary().pipe(
          map((employees: Collection<Employee>) => {
            return new LoadEmployeesDictionarySuccess({ employees: employees.items });
          }),
          catchError((error: HttpErrorResponse) => {
            return of(new LoadEmployeesDictionaryFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private httpDictionaryService: HttpDictionaryService) {}
}
