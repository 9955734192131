import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Collection } from '~shared/model';
import {
  CountriesActionTypes,
  LoadCountriesDictionaryFailure,
  LoadCountriesDictionarySuccess,
} from './countries.actions';
import { HttpDictionaryService } from '../../services/dictionaries.service';
import { CountryItem } from '../../models';

@Injectable()
export class CountriesEffects {
  loadCountries = createEffect(() =>
    this.actions$.pipe(
      ofType(CountriesActionTypes.LOAD_COUNTRIES_DICTIONARY),
      switchMap(() =>
        this.httpDictionaryService.loadCountries().pipe(
          map((countries: Collection<CountryItem>) => {
            const countriesEntity = countries.items.reduce((acc, cur) => {
              acc[cur.code] = cur.name;

              return acc;
            }, {});
            const payload = {
              entity: countriesEntity,
              list: countries.items,
            };
            return new LoadCountriesDictionarySuccess({ countries: payload });
          }),
          catchError(error => of(new LoadCountriesDictionaryFailure(error))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private httpDictionaryService: HttpDictionaryService) {}
}
