import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PaymentInfosService } from '@sales-orders-app/modules/orders/services/payment-info.service';
import {
  LoadPaymentInfos,
  LoadPaymentInfosFailure,
  LoadPaymentInfosSuccess,
  PaymentInfosActionTypes,
} from '@sales-orders-app/modules/orders/store/tabs-payment-info/payment-info.actions';

@Injectable()
export class PaymentInfosEffects {
  loadPaymentInfos$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentInfosActionTypes.LOAD_PAYMENT_INFOS),
      map((action: LoadPaymentInfos) => action.payload),
      switchMap(({ orderId }) =>
        this.paymentInfosService.getPaymentInfos(orderId).pipe(
          map(paymentInfo => new LoadPaymentInfosSuccess(paymentInfo)),
          catchError(error => of(new LoadPaymentInfosFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private paymentInfosService: PaymentInfosService) {}
}
