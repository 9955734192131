import { Field, Name } from 'serialize-ts/dist';

export class TabsFulfillmentInfo {
  @Field()
  @Name('id')
  id: string;

  @Field()
  @Name('productName')
  productName: string;

  @Field()
  @Name('gtin')
  gtin: string;

  @Field()
  @Name('productId')
  productId: string;

  @Field()
  @Name('status')
  status: string;

  @Field()
  @Name('deliveryType')
  deliveryType: string;

  @Field()
  @Name('trackingId')
  trackingId: string;

  @Field()
  @Name('sellerName')
  sellerName: string;

  @Field()
  @Name('shippingType')
  shippingType: string;
}
