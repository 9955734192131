export enum UPLOAD_STATUS {
  AUTO = 'auto',
  AUTO_PROCESS = 'auto_process',
  AUTO_PROCESSED = 'auto_processed',
  APPROVED = 'approved',
  UPLOADED = 'uploaded',
  IN_REVIEW = 'in_review',
  NEED_REVIEW = 'need_review',
  REVIEW_REJECTED = 'review_rejected',
  REJECTED = 'rejected',
  PROCESSING = 'processing',
  REPORT_GENERATING = 'report_generation',
  PARSED_SUCCESS = 'success',
  PARSED_WITH_ERRORS = 'with_errors',
  REJECTED_BY_OPERATOR = 'rejected_operator',
  REVIEWED = 'reviewed',
}

const AUTO_PROCESS_MESSAGE = 'DICTIONARIES.UPLOAD_STATUS.AUTO_PROCESS';
const REJECTED_MESSAGE = 'DICTIONARIES.UPLOAD_STATUS.REVIEW_REJECTED';
export const UPLOAD_STATUS_READABLE = {
  [UPLOAD_STATUS.AUTO]: AUTO_PROCESS_MESSAGE,
  [UPLOAD_STATUS.AUTO_PROCESS]: AUTO_PROCESS_MESSAGE,
  [UPLOAD_STATUS.AUTO_PROCESSED]: AUTO_PROCESS_MESSAGE,
  [UPLOAD_STATUS.APPROVED]: 'DICTIONARIES.UPLOAD_STATUS.APPROVED',
  [UPLOAD_STATUS.UPLOADED]: 'DICTIONARIES.UPLOAD_STATUS.UPLOADED',
  [UPLOAD_STATUS.IN_REVIEW]: 'DICTIONARIES.UPLOAD_STATUS.IN_REVIEW',
  [UPLOAD_STATUS.NEED_REVIEW]: 'DICTIONARIES.UPLOAD_STATUS.NEED_REVIEW',
  [UPLOAD_STATUS.REVIEW_REJECTED]: REJECTED_MESSAGE,
  [UPLOAD_STATUS.REJECTED]: REJECTED_MESSAGE,
  [UPLOAD_STATUS.PROCESSING]: 'DICTIONARIES.UPLOAD_STATUS.PROCESSING',
  [UPLOAD_STATUS.REPORT_GENERATING]: 'DICTIONARIES.UPLOAD_STATUS.REPORT_GENERATING',
  [UPLOAD_STATUS.PARSED_SUCCESS]: 'DICTIONARIES.UPLOAD_STATUS.PARSED_SUCCESS',
  [UPLOAD_STATUS.PARSED_WITH_ERRORS]: 'DICTIONARIES.UPLOAD_STATUS.PARSED_WITH_ERRORS',
  [UPLOAD_STATUS.REJECTED_BY_OPERATOR]: REJECTED_MESSAGE,
};
