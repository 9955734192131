import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { queryBuilder } from '@metromarkets/sdk-17';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize } from 'serialize-ts/dist';
import {
  ORDER_MANAGEMENT_BO_API,
  ORDER_MANAGEMENT_ROOT_API,
  ORDERLINES_API_V2,
} from '@sales-orders-app/modules/orderlines/constants/api';
import { PaginatedResponse, RequestParams } from '~shared/model';
import {
  CancelOrderRequest,
  CancelReturnRequestModel,
  ReturnOrderRequest,
} from '@sales-orders-app/modules/orders/models';
import { CHECK_EMAILS_API } from '@sales-orders-app/modules/orders/constants';
import { ThreadEmail } from '@sales-orders-app/modules/orders/models/check-email';
import { LANGUAGE_PICKER } from '~shared/constants';
import { TranslateService } from '@ngx-translate/core';
import { OrderLineDetails } from '@sales-orders-app/modules/orderlines/models/orderline-detail-info.model';

@Injectable({
  providedIn: 'root',
})
export class TabsOrderlineService {
  constructor(private http: HttpClient, private translate: TranslateService) {}

  getTabsOrderlines(requestParams: RequestParams, orderId: string): Observable<PaginatedResponse<OrderLineDetails>> {
    const params = queryBuilder.toParams(requestParams) as HttpParams;
    return this.http
      .get<PaginatedResponse<OrderLineDetails>>(`${ORDERLINES_API_V2}/${orderId}`, {
        headers: new HttpHeaders({
          'accept-language': JSON.parse(localStorage.getItem(LANGUAGE_PICKER)) || this.translate.defaultLang,
        }),
        params,
      })
      .pipe(
        map(response => ({
          totalCount: response.totalCount,
          items: response.items.map(i => deserialize(i, OrderLineDetails)),
        })),
      );
  }

  cancelOrderLines(payload: CancelOrderRequest) {
    const url = `${ORDER_MANAGEMENT_BO_API}/order-lines/cancel`;

    return this.http.post(url, payload);
  }

  cancelReturnRequest(payload: CancelReturnRequestModel) {
    const url = `${ORDER_MANAGEMENT_BO_API}/order-lines/cancel-return`;

    return this.http.post(url, payload);
  }

  returnOrderLines(data: ReturnOrderRequest) {
    const url = `${ORDER_MANAGEMENT_BO_API}/order-lines/return`;

    return this.http.post(url, data);
  }

  getReturnReasons() {
    return this.http.get(`${ORDER_MANAGEMENT_ROOT_API}/dictionary/return-reasons`);
  }

  checkEmails(params): Observable<ThreadEmail> {
    return this.http.get<ThreadEmail>(CHECK_EMAILS_API, { params });
  }
}
