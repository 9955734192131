const NEW_ORGANIZATION_STATUS = 1;
const PENDING_VERIFICATION_ORGANIZATION_STATUS = 2;
const VERIFICATION_IN_PROGRESS_ORGANIZATION_STATUS = 3;
const APPROVED_ORGANIZATION_STATUS = 4;
const REJECTED_ORGANIZATION_STATUS = 5;
const IN_PROGRESS_ORGANIZATION_STATUS = 8;
const COMPLETED_ORGANIZATION_STATUS = 9;
const VERIFICATION_REQUESTED_ORGANIZATION_STATUS = 10;
const VERIFICATION_REQUEST_FAILED_ORGANIZATION_STATUS = 11;
const VERIFICATION_SUCCESS_ORGANIZATION_STATUS = 12;
const VERIFICATION_ERROR_ORGANIZATION_STATUS = 13;
const DELETED_ORGANIZATION_STATUS = 99;

export enum ORGANIZATION_STATUS {
  NEW = NEW_ORGANIZATION_STATUS,
  PENDING_VERIFICATION = PENDING_VERIFICATION_ORGANIZATION_STATUS,
  VERIFICATION_IN_PROGRESS = VERIFICATION_IN_PROGRESS_ORGANIZATION_STATUS,
  APPROVED = APPROVED_ORGANIZATION_STATUS,
  REJECTED = REJECTED_ORGANIZATION_STATUS,
  IN_PROGRESS = IN_PROGRESS_ORGANIZATION_STATUS,
  COMPLETED = COMPLETED_ORGANIZATION_STATUS,
  VERIFICATION_REQUESTED = VERIFICATION_REQUESTED_ORGANIZATION_STATUS,
  VERIFICATION_REQUEST_FAILED = VERIFICATION_REQUEST_FAILED_ORGANIZATION_STATUS,
  VERIFICATION_SUCCESS = VERIFICATION_SUCCESS_ORGANIZATION_STATUS,
  VERIFICATION_ERROR = VERIFICATION_ERROR_ORGANIZATION_STATUS,
  DELETED = DELETED_ORGANIZATION_STATUS,
}

enum ORGANIZATION_STATUS_COLOR {
  APPROVED = 'approved-status',
  REJECTED = 'rejected-status',
  NEW = 'new-status',
  PENDING = 'pending-status',
  VERIFICATION = 'verification-status',
}

export const OrganizationStatusColorMap = {
  [ORGANIZATION_STATUS.NEW]: ORGANIZATION_STATUS_COLOR.NEW,
  [ORGANIZATION_STATUS.PENDING_VERIFICATION]: ORGANIZATION_STATUS_COLOR.PENDING,
  [ORGANIZATION_STATUS.VERIFICATION_SUCCESS]: ORGANIZATION_STATUS_COLOR.PENDING,
  [ORGANIZATION_STATUS.VERIFICATION_IN_PROGRESS]: ORGANIZATION_STATUS_COLOR.VERIFICATION,
  [ORGANIZATION_STATUS.VERIFICATION_REQUESTED]: ORGANIZATION_STATUS_COLOR.VERIFICATION,
  [ORGANIZATION_STATUS.APPROVED]: ORGANIZATION_STATUS_COLOR.APPROVED,
  [ORGANIZATION_STATUS.REJECTED]: ORGANIZATION_STATUS_COLOR.REJECTED,
  [ORGANIZATION_STATUS.VERIFICATION_REQUEST_FAILED]: ORGANIZATION_STATUS_COLOR.REJECTED,
  [ORGANIZATION_STATUS.VERIFICATION_ERROR]: ORGANIZATION_STATUS_COLOR.REJECTED,
};

export const ORGANIZATION_STATUS_READABLE = {
  [ORGANIZATION_STATUS.NEW]: 'DICTIONARIES.ORGANIZATION_STATUS.NEW',
  [ORGANIZATION_STATUS.PENDING_VERIFICATION]: 'DICTIONARIES.ORGANIZATION_STATUS.PENDING_VERIFICATION',
  [ORGANIZATION_STATUS.VERIFICATION_IN_PROGRESS]: 'DICTIONARIES.ORGANIZATION_STATUS.VERIFICATION_IN_PROGRESS',
  [ORGANIZATION_STATUS.APPROVED]: 'DICTIONARIES.ORGANIZATION_STATUS.APPROVED',
  [ORGANIZATION_STATUS.REJECTED]: 'DICTIONARIES.ORGANIZATION_STATUS.REJECTED',
  [ORGANIZATION_STATUS.IN_PROGRESS]: 'DICTIONARIES.ORGANIZATION_STATUS.IN_PROGRESS',
  [ORGANIZATION_STATUS.COMPLETED]: 'DICTIONARIES.ORGANIZATION_STATUS.COMPLETED',
  [ORGANIZATION_STATUS.VERIFICATION_REQUESTED]: 'DICTIONARIES.ORGANIZATION_STATUS.VERIFICATION_REQUESTED',
  [ORGANIZATION_STATUS.VERIFICATION_REQUEST_FAILED]: 'DICTIONARIES.ORGANIZATION_STATUS.VERIFICATION_REQUEST_FAILED',
  [ORGANIZATION_STATUS.VERIFICATION_SUCCESS]: 'DICTIONARIES.ORGANIZATION_STATUS.VERIFICATION_SUCCESS',
  [ORGANIZATION_STATUS.VERIFICATION_ERROR]: 'DICTIONARIES.ORGANIZATION_STATUS.VERIFICATION_ERROR',
};
