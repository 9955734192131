import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiConfig } from '~shared/constants';
import { Observable, take } from 'rxjs';
import { Collection, Market } from '~shared/model';
import { LanguagePickerService } from '../../language-picker';

@Injectable({
  providedIn: 'root',
})
export class MarketsSelectorHttpService {
  private language: string;

  constructor(private http: HttpClient, private languageService: LanguagePickerService) {
    this.languageService
      .getLanguage()
      .pipe(take(1))
      .subscribe(value => (this.language = value));
  }

  loadMarkets(): Observable<Collection<Market>> {
    const url = `${ApiConfig.SERVICE_CATEGORY_API_ENDPOINT}/markets`;

    return this.http.get<Collection<Market>>(url, {
      headers: new HttpHeaders({ 'Accept-Language': this.language || 'en' }),
    });
  }
}
