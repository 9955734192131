import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { environment } from '~env/environment';
import { ErrorType } from '../constants';
import { SentryError, SentryExtra, SentryTags } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  constructor() {
    super();
    ErrorHandlerService.init();
  }

  static registerError(error: SentryError, errorType: ErrorType): void {
    if (!environment.sentryEnabled) {
      return;
    }
    if (errorType === ErrorType.EXTERNAL) {
      Sentry.setExtras({
        request: error.apiRequest,
        response: error.apiResponse,
      });
      Sentry.setTags({
        errorCode: error.responseErrorCode,
        correlationId: error.correlationId,
        errorType,
      });
      Sentry.captureMessage(ErrorHandlerService.getPreparedMessage(error.apiResponse), Sentry.Severity.Error);
    } else if (errorType === ErrorType.INTERNAL) {
      ErrorHandlerService.resetSettings();
      Sentry.setTags({
        errorType,
      });
      Sentry.captureException(error);
    }
  }

  static getPreparedMessage(response: any): string {
    if (response && response.error) {
      if (response.error.detail) {
        return response.error.detail;
      } else {
        return response.error;
      }
    }
    return response;
  }

  static resetSettings(): void {
    Sentry.setExtras({});
    Sentry.setTags({});
  }

  static init(): void {
    if (environment.sentryEnabled && environment.sentryDsn) {
      Sentry.init({ dsn: environment.sentryDsn, release: environment.sentryRelease });
    }
  }

  public handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      return;
    }
    ErrorHandlerService.registerError(error, ErrorType.INTERNAL);
    super.handleError(error);
  }

  public handleExternalError(error: any, request: HttpRequest<any>): void {
    if (!error.status) {
      return;
    }
    ErrorHandlerService.registerError(
      {
        correlationId: request.headers.get('x-correlation-id'),
        responseErrorCode: error.status,
        apiRequest: request,
        apiResponse: error,
      },
      ErrorType.EXTERNAL,
    );
  }

  public registerCustomError(
    message: string,
    extras?: SentryExtra,
    tags?: SentryTags,
    level: Sentry.Severity = Sentry.Severity.Error,
  ) {
    ErrorHandlerService.resetSettings();
    if (extras) {
      Sentry.setExtras(extras);
    }
    if (tags) {
      Sentry.setTags(tags);
    }
    Sentry.captureMessage(message, level);
  }
}
