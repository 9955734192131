import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { SidenavNode } from '~core/models';

const SUBMENU_TRANSFORM_LEVEL = 1;
const DEFAULT_TOP_POSITION = 0;

@Directive({
  selector: '[appNavmenuController]',
})
export class NavmenuControllerDirective {
  @Input() showSidebar: boolean;
  @Input() node: SidenavNode;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    this.placeSubmenu();
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.renderer.removeAttribute(this.elementRef.nativeElement.querySelector('.submenu'), 'style');
  }

  public placeSubmenu() {
    if (!this.showSidebar && this.node.level === SUBMENU_TRANSFORM_LEVEL) {
      const positionY = this.calculateTopPosition();
      this.renderer.setAttribute(
        this.elementRef.nativeElement.querySelector('.submenu'),
        'style',
        `transform: translate3d(61px, ${positionY}px, 0px)`,
      );
    }
  }

  private calculateTopPosition(): number {
    const elementBoundBox = this.elementRef.nativeElement.getBoundingClientRect();
    const subMenuheight = this.elementRef.nativeElement.querySelector('.submenu').getBoundingClientRect().height;
    if (window.innerHeight < elementBoundBox.top + subMenuheight) {
      return window.innerHeight - (elementBoundBox.top + subMenuheight);
    }
    return DEFAULT_TOP_POSITION;
  }
}
