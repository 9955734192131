export enum CountryPhoneCodes {
  DE = '+49', // Germany
  AT = '+43', // Austria
  ES = '+34', // Spain
  NL = '+31', // Netherlands
  AF = '+93', // Afghanistan
  AL = '+355', // Albania
  DZ = '+213', // Algeria
  AD = '+376', // Andorra
  AO = '+244', // Angola
  AQ = '+672', // Antarctica
  AR = '+54', // Argentina
  AM = '+374', // Armenia
  AW = '+297', // Aruba
  AU = '+61', // Australia
  AZ = '+994', // Azerbaijan
  BH = '+973', // Bahrain
  BD = '+880', // Bangladesh
  BY = '+375', // Belarus
  BE = '+32', // Belgium
  BZ = '+501', // Belize
  BJ = '+229', // Benin
  BT = '+975', // Bhutan
  BO = '+591', // Bolivia
  BA = '+387', // Bosnia
  BW = '+267', // Botswana
  BR = '+55', // Brazil
  BN = '+673', // Brunei
  BG = '+359', // Bulgaria
  BF = '+226', // Burkina Faso
  BI = '+257', // Burundi
  KH = '+855', // Cambodia
  CM = '+237', // Cameroon
  CA = '+1', // Canada
  CV = '+238', // Cape Verde
  CF = '+236', // Central African Republic
  TD = '+235', // Chad
  CL = '+56', // Chile
  CN = '+86', // China
  CO = '+57', // Colombia
  KM = '+269', // Comoros
  CK = '+682', // Cook Islands
  CR = '+506', // Costa Rica
  HR = '+385', // Croatia
  CU = '+53', // Cuba
  CW = '+599', // Curacao
  CY = '+357', // Cyprus
  CZ = '+420', // Czech Republic
  CD = '+243', // Democratic Republic of the Congo
  DK = '+45', // Denmark
  DJ = '+253', // Djibouti
  DM = '+1-767', // Dominica
  DO1 = '+1-809', // Dominican Republic
  DO2 = '+1-829', // Dominican Republic
  DO3 = '+1-849', // Dominican Republic
  TL = '+670', // East Timor
  EC = '+593', // Ecuador
  EG = '+20', // Egypt
  SV = '+503', // El Salvador
  GQ = '+240', // Equatorial Guinea
  ER = '+291', // Eritrea
  EE = '+372', // Estonia
  ET = '+251', // Ethiopia
  FK = '+500', // Falkland Islands
  FO = '+298', // Faroe Islands
  FJ = '+679', // Fiji
  FI = '+358', // Finland
  FR = '+33', // France
  PF = '+689', // French Polynesia
  GA = '+241', // Gabon
  GM = '+220', // Gambia
  GE = '+995', // Georgia
  GH = '+233', // Ghana
  GI = '+350', // Gibraltar
  GR = '+30', // Greece
  GL = '+299', // Greenland
  GT = '+502', // Guatemala
  GN = '+224', // Guinea
  GW = '+245', // Guinea-Bissau
  GY = '+592', // Guyana
  HT = '+509', // Haiti
  HN = '+504', // Honduras
  HK = '+852', // Hong Kong
  HU = '+36', // Hungary
  IS = '+354', // Iceland
  IN = '+91', // India
  ID = '+62', // Indonesia
  IR = '+98', // Iran
  IQ = '+964', // Iraq
  IE = '+353', // Ireland
  IL = '+972', // Israel
  IT = '+39', // Italy
  CI = '+225', // Ivory Coast
  JM = '+1-876', // Jamaica
  JP = '+81', // Japan
  JO = '+962', // Jordan
  KZ = '+7', // Kazakhstan
  KE = '+254', // Kenya
  KI = '+686', // Kiribati
  XK = '+383', // Kosovo
  KW = '+965', // Kuwait
  KG = '+996', // Kyrgyzstan
  LA = '+856', // Laos
  LV = '+371', // Latvia
  LB = '+961', // Lebanon
  LS = '+266', // Lesotho
  LR = '+231', // Liberia
  LY = '+218', // Libya
  LI = '+423', // Liechtenstein
  LT = '+370', // Lithuania
  LU = '+352', // Luxembourg
  MO = '+853', // Macau
  MK = '+389', // Macedonia
  MG = '+261', // Madagascar
  MW = '+265', // Malawi
  MY = '+60', // Malaysia
  MV = '+960', // Maldives
  ML = '+223', // Mali
  MT = '+356', // Malta
  MX = '+52', // Mexico
  MD = '+373', // Moldova
  MC = '+377', // Monaco
  MN = '+976', // Mongolia
  ME = '+382', // Montenegro
  MA = '+212', // Morocco
  MZ = '+258', // Mozambique
  MM = '+95', // Myanmar
  NA = '+264', // Namibia
  NR = '+674', // Nauru
  NP = '+977', // Nepal
  AN = '+599', // Netherlands Antilles
  NC = '+687', // New Caledonia
  NZ = '+64', // New Zealand
  NI = '+505', // Nicaragua
  NE = '+227', // Niger
  NG = '+234', // Nigeria
  NO = '+47', // Norway
  OM = '+968', // Oman
  PK = '+92', // Pakistan
  PW = '+680', // Palau
  PS = '+970', // Palestine
  PA = '+507', // Panama
  PG = '+675', // Papua New Guinea
  PY = '+595', // Paraguay
  PE = '+51', // Peru
  PH = '+63', // Philippines
  PN = '+64', // Pitcairn
  PL = '+48', // Poland
  PT = '+351', // Portugal
  PR1 = '+1-787', // Puerto Rico
  PR2 = '+1-939', // Puerto Rico
  QA = '+974', // Qatar
  CG = '+242', // Republic of the Congo
  RE = '+262', // Reunion
  RO = '+40', // Romania
  RU = '+7', // Russia
  RW = '+250', // Rwanda
  WS = '+685', // Samoa
  SM = '+378', // San Marino
  SA = '+966', // Saudi Arabia
  SN = '+221', // Senegal
  RS = '+381', // Serbia
  SC = '+248', // Seychelles
  SL = '+232', // Sierra Leone
  SG = '+65', // Singapore
  SK = '+421', // Slovakia
  SI = '+386', // Slovenia
  SO = '+252', // Somalia
  ZA = '+27', // South Africa
  KR = '+82', // South Korea
  SS = '+211', // South Sudan
  LK = '+94', // Sri Lanka
  SD = '+249', // Sudan
  SR = '+597', // Suriname
  SZ = '+268', // Swaziland
  SE = '+46', // Sweden
  CH = '+41', // Switzerland
  SY = '+963', // Syria
  TW = '+886', // Taiwan
  TJ = '+992', // Tajikistan
  TZ = '+255', // Tanzania
  TH = '+66', // Thailand
  TG = '+228', // Togo
  TO = '+676', // Tonga
  TN = '+216', // Tunisia
  TR = '+90', // Turkey
  TM = '+993', // Turkmenistan
  UG = '+256', // Uganda
  UA = '+380', // Ukraine
  AE = '+971', // United Arab Emirates
  GB = '+44', // United Kingdom
  US = '+1', // United States
  UY = '+598', // Uruguay
  UZ = '+998', // Uzbekistan
  VA = '+379', // Vatican
  VE = '+58', // Venezuela
  VN = '+84', // Vietnam
  EH = '+212', // Western Sahara
  YE = '+967', // Yemen
  ZM = '+260', // Zambia
  ZW = '+263', // Zimbabwe
}

export const PHONE_CODES_SORTED = () => {
  let nums: number[] = []; // strings that can be converted into nums will be stored here
  let nanNums: string[] = []; // strings that can't be converted into nums (1-787) will be stored here
  let sortedList: string[] = [];
  Object.values(CountryPhoneCodes).forEach(code => {
    const canConvert = isNaN(+code);

    if (canConvert) {
      nanNums = [...nanNums, code];
    } else {
      nums = [...nums, +code];
    }
  });
  nums = [...new Set(nums)];
  nums.sort((a, b) => a - b);
  nums.forEach(num => (sortedList = [...sortedList, `+${num}`]));
  sortedList = [...sortedList, ...nanNums];
  return sortedList;
};
