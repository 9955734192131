import { Action } from '@ngrx/store';
import { Order } from '@sales-orders-app/modules/orders/models/order.model';
import { ErrorResponse } from '~shared/model/error-response.model';
import { PaginatedResponse, RequestParams } from '~shared/model';

export enum OrdersActionTypes {
  LOAD_ORDERS = '[Orders] Orders load',
  LOAD_ORDERS_SUCCESS = '[Orders] Orders load success',
  LOAD_ORDERS_FAILURE = '[Orders] Orders load failure',
  TOGGLE_DISPLAY_ORDERS = '[Orders] Toggle display orders',
}

export class LoadOrders implements Action {
  readonly type = OrdersActionTypes.LOAD_ORDERS;

  constructor(public payload: { params: RequestParams }) {}
}

export class LoadOrdersSuccess implements Action {
  readonly type = OrdersActionTypes.LOAD_ORDERS_SUCCESS;

  constructor(public payload: { orders: PaginatedResponse<Order> }) {}
}

export class LoadOrdersFailure implements Action {
  readonly type = OrdersActionTypes.LOAD_ORDERS_FAILURE;

  constructor(public payload: { error: ErrorResponse }) {}
}

export class ToggleDisplayOrders implements Action {
  readonly type = OrdersActionTypes.TOGGLE_DISPLAY_ORDERS;

  constructor(public payload: { value: boolean }) {}
}

export type OrdersActionsUnion = LoadOrders | LoadOrdersSuccess | LoadOrdersFailure | ToggleDisplayOrders;
