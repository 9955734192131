<div class="popover" [ngClass]="{ 'popover__text-content': isStringContent }">
  <ng-container [ngSwitch]="renderMethod">
    <div *ngSwitchCase="renderMethods.text" [innerHTML]="content"></div>

    <ng-container *ngSwitchCase="renderMethods.template">
      <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="renderMethods.component">
      <ng-container *ngComponentOutlet="content"></ng-container>
    </ng-container>
  </ng-container>
</div>
