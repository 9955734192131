import { HttpErrorResponse } from '@angular/common/http';
import { OrganizationStatusModel } from '../../models';

export interface OrganizationStatusesState {
  payload: OrganizationStatusModel[];
  errorData: HttpErrorResponse | null;
  loading: boolean;
}

export const initialOrganizationStatusesState: OrganizationStatusesState = {
  payload: [],
  errorData: null,
  loading: false,
};
