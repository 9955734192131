import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HTTP_STATUS_CODE } from '~shared/model/http-status-code';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '~env/environment';
import { AuthService } from '@root/modules/user-auth/services/auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private oAuthService: OAuthService, private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        const isAuthorizationError = error && error.status === HTTP_STATUS_CODE.UNAUTHORIZED;
        if (isAuthorizationError && !this.oAuthService.hasValidAccessToken()) {
          this.handleUnAuthorized();
          return new Observable<never>();
        }
        return throwError(error);
      }),
    );
  }

  handleUnAuthorized() {
    this.authService.isLoggedIn$.subscribe(res => {
      if (res) {
        const realmId = environment.idamConfig && environment.idamConfig.realmId;
        this.oAuthService.initCodeFlow(`realm_id=${realmId}`);
      }
    });
  }
}
