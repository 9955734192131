import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BuyerInfosService } from '@sales-orders-app/modules/orders/services/tabs-buyer-info.service';
import {
  LoadBuyerInfos,
  LoadBuyerInfosFailure,
  LoadBuyerInfosSuccess,
  BuyerInfosActionTypes,
  LoadBuyerOrderInfos,
  LoadBuyerOrderInfosSuccess,
  LoadBuyerOrderInfosFailure,
  GetBuyerCDMId,
  GetBuyerCDMIdSuccess,
  GetBuyerCDMIdFailure,
} from '@sales-orders-app/modules/orders/store/tabs-buyer-info/buyer-info.actions';

@Injectable()
export class BuyerInfosEffects {
  loadBuyerInfos$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BuyerInfosActionTypes.LOAD_BUYER_INFOS),
      map((action: LoadBuyerInfos) => action.payload),
      switchMap(({ buyerId, saleChannel, buyerEmail }) => {
                const getBuyer$ = buyerId
          ? this.buyerInfosService.getBuyerById(buyerId)
          : this.buyerInfosService.getBuyerInfo(buyerEmail);
        return getBuyer$.pipe(
          map(buyerInfo => new LoadBuyerInfosSuccess(buyerInfo)),
          catchError(error => of(new LoadBuyerInfosFailure({ error }))),
        );
      }),
    ),
  );

  loadBuyerOrderInfos$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BuyerInfosActionTypes.LOAD_BUYER_ORDER_INFOS),
      map((action: LoadBuyerOrderInfos) => action.payload),
      switchMap(({ buyerId }) =>
        this.buyerInfosService.getBuyerOrderInfo(buyerId).pipe(
          map(buyerOrderInfo => new LoadBuyerOrderInfosSuccess(buyerOrderInfo)),
          catchError(error => of(new LoadBuyerOrderInfosFailure({ error }))),
        ),
      ),
    ),
  );

  getBuyerCDMId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(BuyerInfosActionTypes.GET_BUYER_CDM_ID),
      map((action: GetBuyerCDMId) => action.payload),
      switchMap(({ buyerId }) =>
        this.buyerInfosService.getBuyerCDMId(buyerId).pipe(
          map(buyerCdmId => new GetBuyerCDMIdSuccess(buyerCdmId)),
          catchError(error => of(new GetBuyerCDMIdFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private buyerInfosService: BuyerInfosService) {}
}
