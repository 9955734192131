import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@root/modules/user-auth/services/auth.service';
import { environment } from '~env/environment';
import { NOAUTH_URL } from '@root/modules/unauthorized/unauthorized.constants';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private authService: AuthService, private oAuthService: OAuthService, private router: Router) {}

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.verifyAccessToken(route);
  }

  canLoad(route: Route): Observable<boolean> | boolean {
    return this.verifyAccessToken(route);
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.verifyAccessToken(route);
  }

  verifyAccessToken(route: Route | ActivatedRouteSnapshot) {
    return from(this.oAuthService.loadDiscoveryDocument()).pipe(
      map(() => {
        if (this.oAuthService.hasValidAccessToken()) {
          return this.checkIfUserHasValidAccessToken(route);
        } else {
          this.authService.isLoggedIn$.subscribe(res => {
            if (res) {
              const realmId = environment.idamConfig && environment.idamConfig.realmId;
              this.oAuthService.initCodeFlow(`realm_id=${realmId}`);
            }
          });
        }
        return false;
      }),
    );
  }

  checkIfUserHasValidAccessToken(route: Route | ActivatedRouteSnapshot) {
    const userRoles = this.authService.userRoles;
    if (route.data.roles && route.data.roles.some(role => userRoles.includes(role))) {
      return true;
    }
    this.router.navigateByUrl(NOAUTH_URL, { skipLocationChange: true });

    return false;
  }
}
