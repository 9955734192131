export const ORDER_LINES_TABLE_COLUMNS = {
  sellerName: 'sellerName',
  product: 'product',
  productQuantity: 'productQuantity',
  itemPrice: 'itemPrice',
  shippingCost: 'shippingCost',
  totalPrice: 'totalPrice',
  totalShippingCost: 'totalShippingCost',
  originRegionId: 'originRegionId',
  status: 'status',
  triggeredBy: 'triggeredBy',
  emailThreadLink: 'emailThreadLink',
  action: 'action',
};

export const ORDER_LINES_TABLE_SELECT_COLUMN = 'select';

export const ORDER_LINES_TABLE_COLUMNS_LIST = Object.values(ORDER_LINES_TABLE_COLUMNS);
