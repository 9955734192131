import { TabsFulfillmentInfo } from '@sales-orders-app/modules/orders/models';
import { ErrorResponse } from '~shared/model';

export interface TabsFulfillmentInfoState {
  payload: TabsFulfillmentInfo[];
  loading: boolean;
  error: ErrorResponse;
}

export const TabsFulfillmentInfoInitialState: TabsFulfillmentInfoState = {
  payload: [],
  loading: true,
  error: null,
};
