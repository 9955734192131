export enum ChipTypes {
  yellow = 'yellow',
  lemon = 'lemon',
  blue = 'blue',
  green = 'green',
  red = 'red',
  crimson = 'crimson',
  cyan = 'cyan',
  grey = 'grey',
  lime = 'lime',
}
