import { ErrorResponse } from '~shared/model';
import { ReturnReason } from '../../models';

export interface ReturnReasonsState {
  payload: ReturnReason[];
  loading: boolean;
  error: ErrorResponse | null;
}

export const initialState: ReturnReasonsState = {
  payload: [],
  loading: false,
  error: null,
};
