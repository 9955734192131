import { ChipTypes } from '~shared/modules/chip';
import { AttributeType } from './attribute-types';

export const ATTRIBUTE_CHIP_TYPES = {
  [AttributeType.LIST_OF_VALUES]: ChipTypes.blue,
  [AttributeType.BOOLEAN]: ChipTypes.green,
  [AttributeType.TEXT]: ChipTypes.lemon,
  [AttributeType.INTEGER]: ChipTypes.red,
  [AttributeType.FILE]: ChipTypes.grey,
  [AttributeType.DECIMAL]: ChipTypes.crimson,
  [AttributeType.IMAGE]: ChipTypes.yellow,
  [AttributeType.LONG_TEXT]: ChipTypes.lime,
};
