import { createAction, props } from '@ngrx/store';
import { NotifyFraudRequest } from '@sales-orders-app/modules/orders/models';
import { ErrorResponse } from '~shared/model/error-response.model';

export const notifyFraud = createAction('[NotifyFraud] Notify Fraud', props<{ fraudPayload: NotifyFraudRequest }>());

export const notifyFraudSuccess = createAction('[NotifyFraud] Notify Fraud Success');

export const notifyFraudFailure = createAction('[NotifyFraud] Notify Fraud failure', props<{ error: ErrorResponse }>());

export const clearNotifyFraud = createAction('[NotifyFraud] NotifyFraud clear state');
