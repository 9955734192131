export enum SortableDirection {
  asc = 'asc',
  desc = 'desc',
}

export type SortDirectionType = SortableDirection.asc | SortableDirection.desc | '';

export interface SortDirectiveModel {
  mmSortActive: string;
  mmSortDirection: SortDirectionType;
}
