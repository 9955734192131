import { HttpErrorResponse } from '@angular/common/http';
import { CountryPayload } from '../../models';

export interface CountriesState {
  payload: CountryPayload;
  errorData: HttpErrorResponse | null;
  loading: boolean;
}

export const initialCountriesState: CountriesState = {
  payload: {
    entity: {},
    list: [],
  },
  errorData: null,
  loading: false,
};
