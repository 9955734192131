import { Action } from '@ngrx/store';
import { TabsFulfillmentInfo } from '@sales-orders-app/modules/orders/models';
import { ErrorResponse } from '~shared/model/error-response.model';

export enum TabFulfillmentsActionTypes {
  LOAD_TAB_FULLFILMENT_INFO = '[TabsFulfillmentInfo] TabsFulfillmentInfo load',
  LOAD_TAB_FULLFILMENT_INFO_SUCCESS = '[TabsFulfillmentInfo] TabsFulfillmentInfo load success',
  LOAD_TAB_FULLFILMENT_INFO_FAILURE = '[TabsFulfillmentInfo] TabsFulfillmentInfo load failure',
}

export class LoadTabsFulfillmentInfo implements Action {
  readonly type = TabFulfillmentsActionTypes.LOAD_TAB_FULLFILMENT_INFO;

  constructor(public payload: { orderId: string }) {}
}

export class LoadTabsFulfillmentInfoSuccess implements Action {
  readonly type = TabFulfillmentsActionTypes.LOAD_TAB_FULLFILMENT_INFO_SUCCESS;

  constructor(public payload: TabsFulfillmentInfo[]) {}
}

export class LoadTabsFulfillmentInfoFailure implements Action {
  readonly type = TabFulfillmentsActionTypes.LOAD_TAB_FULLFILMENT_INFO_FAILURE;

  constructor(public payload: { error: ErrorResponse }) {}
}

export type TabFulfillmentActionsUnion =
  | LoadTabsFulfillmentInfo
  | LoadTabsFulfillmentInfoSuccess
  | LoadTabsFulfillmentInfoFailure;
