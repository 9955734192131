<mat-toolbar class="toolbar">
  <div class="toolbar__left">
    <mm-icon type="fluid" [name]="'logo-metro'" [isCurrentColor]="false" class="toolbar__logo"></mm-icon>
    <a routerLink="/">
      <span class="toolbar__title">Back office</span>
    </a>
  </div>
  <span class="toolbar__right">
    <employee-app-market-dropdown *ngIf="isLoggedIn"></employee-app-market-dropdown>
    <app-language-picker></app-language-picker>
    <span *ngIf="userName && isLoggedIn" class="toolbar__username">
      {{ userName }}
    </span>
    <button (click)="logout()" *ngIf="isLoggedIn" mat-raised-button [mmTestTarget]="'logout-button'">Logout</button>
  </span>
</mat-toolbar>

<div *ngIf="isLoggedIn && isNavigated">
  <employee-app-side-bar-icon [scrolled]="scrolled" (sideBarToogle)="showSidebar = $event"></employee-app-side-bar-icon>
</div>

<mat-drawer-container *ngIf="isLoggedIn" class="main-container">
  <mat-drawer
    class="main-container__sidebar"
    [ngClass]="{
      'main-container__sidebar_closed': !showSidebar,
      'main-container__sidebar_scrolled': scrolled
    }"
    mode="side"
    opened
  >
    <employee-app-sidenav-menu
      *ngIf="adminPanelTreeData"
      [treeData]="adminPanelTreeData"
      [showSidebar]="showSidebar"
    ></employee-app-sidenav-menu>
  </mat-drawer>

  <mat-drawer-content class="drawer-content drawer-content-{{ showSidebar ? 'open' : 'closed' }}">
    <mm-alert-wrapper></mm-alert-wrapper>
    <app-breadcrumb [treeData]="adminPanelTreeData" *ngIf="adminPanelTreeData"></app-breadcrumb>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
