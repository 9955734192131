<div
  (click)="toggleSideBar()"
  [ngClass]="{
    'side-bar-icon--traverse-left': !showSideBar,
    'side-bar-icon--traverse-right': showSideBar,
    scrolled: scrolled
  }"
>
  <svg
    width="34px"
    height="34px"
    viewBox="0 0 34 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 6 Copy</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Navigation-Menu-bar-Copy-2" transform="translate(-43.000000, -6.000000)">
        <g id="Group-6-Copy" transform="translate(44.000000, 7.000000)">
          <path
            d="M16,1.3500312e-13 C7.16342857,1.3500312e-13 0,7.1775437 0,16.0315271 C0,24.8855105 7.16342857,32.0630542 16,32.0630542 C24.8365714,32.0630542 32,24.8855105 32,16.0315271 C32,7.1775437 24.8365714,1.3500312e-13 16,1.3500312e-13 Z"
            id="Path"
            stroke="#002D72"
            fill="#002D72"
            fill-rule="nonzero"
          ></path>
          <polygon
            id="Path"
            fill="#FFFFFF"
            transform="translate(16.235000, 16.026208) scale(-1, 1) translate(-16.235000, -16.026208) "
            points="21.47 23.560039 19.9761338 25.0524156 11 16.0262078 19.9761338 7 21.47 8.49237662 13.9779599 16.0262078"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
</div>
