import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { CountryPayload } from '../../models';

export enum CountriesActionTypes {
  LOAD_COUNTRIES_DICTIONARY = '[Dictionary] Countries Load',
  LOAD_COUNTRIES_DICTIONARY_SUCCESS = '[Dictionary] Countries Load Success',
  LOAD_COUNTRIES_DICTIONARY_FAILURE = '[Dictionary] Countries Load Failure',
}

export class LoadCountriesDictionary implements Action {
  readonly type = CountriesActionTypes.LOAD_COUNTRIES_DICTIONARY;
}

export class LoadCountriesDictionarySuccess implements Action {
  readonly type = CountriesActionTypes.LOAD_COUNTRIES_DICTIONARY_SUCCESS;

  constructor(public payload: { countries: CountryPayload }) {}
}

export class LoadCountriesDictionaryFailure implements Action {
  readonly type = CountriesActionTypes.LOAD_COUNTRIES_DICTIONARY_FAILURE;

  constructor(public payload: HttpErrorResponse) {}
}

export type CountriesDictionaryActions =
  | LoadCountriesDictionary
  | LoadCountriesDictionarySuccess
  | LoadCountriesDictionaryFailure;
