import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SearchInputModule } from '../search-input/search-input.module';
import { SearchDropdownModule } from '../search-dropdown/search-dropdown.module';
import { MmButtonModule, MmSelectModule } from '@metromarkets/components-17';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { ValueRangeComponent } from './components/value-range/value-range.component';
import { CastClassModule } from '@root/shared/pipes/cast-class/cast-class.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [SearchBarComponent, DatePickerComponent, ValueRangeComponent],
  imports: [
    CommonModule,
    SearchInputModule,
    SearchDropdownModule,
    MmSelectModule,
    MmButtonModule,
    DatePickerModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule,
    CastClassModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [SearchBarComponent],
})
export class SearchBarModule {}
