import { StoreUtils } from '~shared/utils/store.utils';
import { OriginsActions, OriginsActionTypes } from './origins.actions';
import { initialOriginsState, OriginsState } from './origins.state';
import { Collection } from '~shared/model';
import { OriginItem } from '~shared/modules/dictionary/models';

export function originsReducer(state = initialOriginsState, action: OriginsActions) {
  switch (action.type) {
    case OriginsActionTypes.LOAD_ORIGINS_DICTIONARY:
      return StoreUtils.requestHandler<OriginsState>(state);

    case OriginsActionTypes.LOAD_ORIGINS_DICTIONARY_SUCCESS:
      return StoreUtils.successHandler<OriginsState, Collection<OriginItem>>(state, action.payload);

    case OriginsActionTypes.LOAD_ORIGINS_DICTIONARY_FAILURE:
      return StoreUtils.failureHandler<OriginsState>(state, action.payload.error);

    default:
      return state;
  }
}
