import { Injectable } from '@angular/core';
import { BreadcrumbModel } from '@root/modules/breadcrumb/models/breadcrumb.model';
import { isEmpty } from 'lodash';
import { UrlSegment } from '@angular/router';
import { SidenavNode } from '~core/models';
import { BreadcrumbRouterdata } from '@root/modules/breadcrumb/models/breadcrumb.routerdata';
import { BreadcrumbState } from '@root/modules/breadcrumb/models/breadcrumb.state';
import { LEAF_NODE_KEY, LEAF_NODE_TITLE } from '@root/modules/breadcrumb/constants/braeadcrumbs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {

  private treeData: SidenavNode[] = [];

  public setTreeData(treeData: SidenavNode[]) {
    this.treeData = treeData;
  }

  public createBreadCrumbState(
    routerData: BreadcrumbRouterdata,
    currentBreadcrumb: {
      [key: string]: BreadcrumbModel;
    },
    urlSegment: UrlSegment[],
    isSellerSupport: boolean = false,
  ): BreadcrumbState {
    const emptyState = {
      breadCrumbs: {},
    };
    let breadCrumbs = currentBreadcrumb || emptyState.breadCrumbs;
    let level = 0;
    if (!urlSegment.length || !this.treeData.length || isSellerSupport) {
      return emptyState;
    }
    const node = this.treeData.find(tree => {
      return tree.link === `/${urlSegment[0].path}`;
    });
    if (!breadCrumbs[node.link]) {
      breadCrumbs = {};
    }
    breadCrumbs = {
      ...breadCrumbs,
      [node.link]: {
        url: node.link,
        title: node.title,
        level: level++,
      },
    };

    breadCrumbs = this.getChildNodes(routerData, breadCrumbs, node.children, urlSegment, level);

    if (!isEmpty(routerData.params)) {
      breadCrumbs = {
        ...breadCrumbs,
        [LEAF_NODE_KEY]: { url: '', title: LEAF_NODE_TITLE, level: level++ },
      };
    } else {
      delete breadCrumbs[LEAF_NODE_KEY];
    }

    return {
      breadCrumbs,
    };
  }

  private getChildUrl(
    routerData: BreadcrumbRouterdata,
    breadCrumbs: { [key: string]: BreadcrumbModel },
    childUrl: string,
  ) {
    if (isEmpty(routerData.params)) {
      return routerData.url;
    }
    if (breadCrumbs[childUrl] && !isEmpty(routerData.params)) {
      return breadCrumbs[childUrl].url;
    }
    return childUrl;
  }

  private resetStateFromLevel(
    breadCrumbs: { [key: string]: BreadcrumbModel },
    fromLevel: number,
  ): { [key: string]: BreadcrumbModel } {
    Object.keys(breadCrumbs).forEach(key => {
      if (breadCrumbs[key].level >= fromLevel) {
        delete breadCrumbs[key];
      }
    });
    return breadCrumbs;
  }

  private getChildNodes(
    routerData: BreadcrumbRouterdata,
    breadCrumbs,
    children: SidenavNode[],
    urlSegment: UrlSegment[],
    level: number,
  ) {
    urlSegment.forEach((segment, index) => {
      if (index >= level) {
        const node = children.find(tree => tree.link.indexOf(`/${urlSegment[index - 1].path}/${segment.path}`) !== -1);
        if (node) {
          if (!breadCrumbs[node.link]) {
            breadCrumbs = this.resetStateFromLevel(breadCrumbs, level);
          }
          const nodeUrl = this.getChildUrl(routerData, breadCrumbs, node.link);
          breadCrumbs = {
            ...breadCrumbs,
            [node.link]: {
              ...breadCrumbs[node.link],
              url: nodeUrl,
              title: node.title,
              level: level++,
            },
          };
          if (node.children) {
            this.getChildNodes(routerData, breadCrumbs, node.children, urlSegment, level);
          }
        }
      }
    });
    return breadCrumbs;
  }
}
