import { ActionReducerMap } from '@ngrx/store';
import { ordersReducer } from '@sales-orders-app/modules/orders/store/orders';
import { orderDetailsReducer } from './order-details';
import { State } from '@sales-orders-app/modules/orders/store/state';
import { tabOrderlineReducer } from './tabs-orderline';
import { paymentInfosReducer } from './tabs-payment-info';
import { tabsSellerInfoReducer } from './tabs-seller-info';
import { tabBuyerInfosReducer } from './tabs-buyer-info';
import { tabFulfillmentReducer } from './tabs-fulfillment-info';
import { returnReasonReducer } from './return-reasons';
import { notifyFraudReducer } from '@sales-orders-app/modules/orders/store/notify-fraud';

export const orderReducers: ActionReducerMap<State> = {
  orders: ordersReducer,
  orderDetails: orderDetailsReducer,
  notifyFraud: notifyFraudReducer,
  tabsOrderline: tabOrderlineReducer,
  tabsFulfillmentInfo: tabFulfillmentReducer,
  paymentInfo: paymentInfosReducer,
  tabsSellerInfo: tabsSellerInfoReducer,
  tabsBuyerInfo: tabBuyerInfosReducer,
  returnReasons: returnReasonReducer,
};
