import { DropdownFieldType } from './dropdown-field-type';
import { DropdownSearch } from './dropdown-search.model';

export class DropdownSearchSingle extends DropdownSearch {
  minCharacters?: number;
  constructor(id: string | number, value: string, minCharacters?: number) {
    super(id, value, DropdownFieldType.TEXT);
    this.minCharacters = minCharacters;
  }
}
