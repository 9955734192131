import {
  TabsSellerInfoInitialState,
  TabsSellerInfoState,
} from '@sales-orders-app/modules/orders/store/tabs-seller-info/tabs-seller-info.state';
import { TabsSellerInfoActionsUnion, TabsSellerInfoActionTypes } from './tabs-seller-info.actions';

export function tabsSellerInfoReducer(
  state = TabsSellerInfoInitialState,
  action: TabsSellerInfoActionsUnion,
): TabsSellerInfoState {
  switch (action.type) {
    case TabsSellerInfoActionTypes.LOAD_TAB_SELLER_INFO:
      return { ...state, loading: true };
    case TabsSellerInfoActionTypes.LOAD_TAB_SELLER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload.sellerInfos,
        error: null,
      };
    case TabsSellerInfoActionTypes.LOAD_TAB_SELLER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        payload: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
