import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ORDERS_FEATURE_NAME } from '@sales-orders-app/modules/orders/store/feature-name';
import { TabsFulfillmentInfoState } from '@sales-orders-app/modules/orders/store/tabs-fulfillment-info/fulfillment-info.state';
import { State } from '@sales-orders-app/modules/orders/store/state';
import { TabsFulfillmentInfo } from '@sales-orders-app/modules/orders/models';

const getModuleFeatureState = createFeatureSelector(ORDERS_FEATURE_NAME);

export const selectTabsFulfillmentInfoState = createSelector(
  getModuleFeatureState,
  (state: State) => state.tabsFulfillmentInfo,
);

export const selectTabsFulfillmentInfoPayload = createSelector(
  selectTabsFulfillmentInfoState,
  (state: TabsFulfillmentInfoState) => state.payload,
);

export const getTabsFulfillmentInfo = createSelector(
  selectTabsFulfillmentInfoPayload,
  (payload: TabsFulfillmentInfo[]) => payload,
);

export const getTabsFulfillmentInfoLoading = createSelector(
  selectTabsFulfillmentInfoState,
  (state: TabsFulfillmentInfoState) => state.loading,
);
