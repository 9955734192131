import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DICTIONARY_FEATURE_NAME } from '~shared/modules/dictionary/constants';
import { reducers } from './store';
import { CountriesEffects } from './store/countries';
import { DocumentTypesEffects } from './store/document-types';
import { EmployeeEffects } from './store/employee';
import { OrganizationStatusesEffects } from './store/organization-statuses';
import { ProductCategoriesEffects } from './store/product-categories';
import { RegistrationDestinationEffects } from './store/registration-destination';
import { CountryNamePipe } from './pipe/country-name.pipe';
import { DestinationNamePipe } from './pipe/destination-name.pipe';
import { OriginsEffects } from './store/origins/origins.effects';

@NgModule({
  declarations: [CountryNamePipe, DestinationNamePipe],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(DICTIONARY_FEATURE_NAME, reducers),
    EffectsModule.forFeature([
      ProductCategoriesEffects,
      CountriesEffects,
      OrganizationStatusesEffects,
      DocumentTypesEffects,
      EmployeeEffects,
      RegistrationDestinationEffects,
      OriginsEffects,
    ]),
  ],
  exports: [CountryNamePipe, DestinationNamePipe],
})
export class DictionaryModule {}
