import { HttpErrorResponse } from '@angular/common/http';
import { ProductCategoryItemModel } from '../../models';

export interface ProductCategoriesState {
  payload: ProductCategoryItemModel[];
  errorData: HttpErrorResponse | null;
  loading: boolean;
}

export const initialProductCategoriesState: ProductCategoriesState = {
  payload: [],
  errorData: null,
  loading: false,
};
