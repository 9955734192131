import { Action } from '@ngrx/store';
import { PaymentInfo } from '@sales-orders-app/modules/orders/models/payment-info.model';
import { ErrorResponse } from '~shared/model/error-response.model';

export enum PaymentInfosActionTypes {
  LOAD_PAYMENT_INFOS = '[PaymentInfos] PaymentInfos load',
  LOAD_PAYMENT_INFOS_SUCCESS = '[PaymentInfos] PaymentInfos load success',
  LOAD_PAYMENT_INFOS_FAILURE = '[PaymentInfos] PaymentInfos load failure',
}

export class LoadPaymentInfos implements Action {
  readonly type = PaymentInfosActionTypes.LOAD_PAYMENT_INFOS;

  constructor(public payload: { orderId: string }) {}
}

export class LoadPaymentInfosSuccess implements Action {
  readonly type = PaymentInfosActionTypes.LOAD_PAYMENT_INFOS_SUCCESS;

  constructor(public payload: PaymentInfo) {}
}

export class LoadPaymentInfosFailure implements Action {
  readonly type = PaymentInfosActionTypes.LOAD_PAYMENT_INFOS_FAILURE;

  constructor(public payload: { error: ErrorResponse }) {}
}

export type PaymentInfosActionsUnion = LoadPaymentInfos | LoadPaymentInfosSuccess | LoadPaymentInfosFailure;
