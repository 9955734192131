import {
  BreadcrumbActionsUnion,
  BreadcrumbActionTypes,
} from '@root/modules/breadcrumb/store/breadcrumb/breadcrumb.actions';
import { initialState } from '@root/modules/breadcrumb/store/breadcrumb/breadcrumb.state';
import { BreadcrumbState } from '@root/modules/breadcrumb/models/breadcrumb.state';

export function breadcrumbReducer(state = initialState, action: BreadcrumbActionsUnion): BreadcrumbState {
  if (action.type === BreadcrumbActionTypes.URL_CHANGE) {
    return {
      ...state,
      breadCrumbs: action.payload.state.breadCrumbs,
    };
  }
  return state;
}
