import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ORDERS_FEATURE_NAME } from '@sales-orders-app/modules/orders/store/feature-name';
import { TabsSellerInfoState } from '@sales-orders-app/modules/orders/store/tabs-seller-info/tabs-seller-info.state';
import { State } from '@sales-orders-app/modules/orders/store/state';
import { TabsSellerInfo } from '@sales-orders-app/modules/orders/models';

const getModuleFeatureState = createFeatureSelector(ORDERS_FEATURE_NAME);

export const selectTabsSellerInfoState = createSelector(getModuleFeatureState, (state: State) => state.tabsSellerInfo);

export const selectTabsSellerInfoPayload = createSelector(
  selectTabsSellerInfoState,
  (state: TabsSellerInfoState) => state.payload,
);

export const getTabsSellerInfo = createSelector(selectTabsSellerInfoPayload, (payload: TabsSellerInfo[]) => payload);

export const getTabsSellerInfoLoading = createSelector(
  selectTabsSellerInfoState,
  (state: TabsSellerInfoState) => state.loading,
);
