import { Field, Model } from 'serialize-ts/dist';

@Model()
export class InvalidParams {
  @Field()
  name: string;

  @Field()
  reason: string;
}
