import { Action } from '@ngrx/store';
import { OrderDetails, TabsSellerInfo } from '@sales-orders-app/modules/orders/models';
import { ErrorResponse } from '~shared/model/error-response.model';

export enum TabsSellerInfoActionTypes {
  LOAD_TAB_SELLER_INFO = '[TabsSellerInfo] TabsSellerInfo load',
  LOAD_TAB_SELLER_INFO_SUCCESS = '[TabsSellerInfo] TabsSellerInfo load success',
  LOAD_TAB_SELLER_INFO_FAILURE = '[TabsSellerInfo] TabsSellerInfo load failure',
}

export class LoadTabsSellerInfo implements Action {
  readonly type = TabsSellerInfoActionTypes.LOAD_TAB_SELLER_INFO;

  constructor(public payload: OrderDetails) {}
}

export class LoadTabsSellerInfoSuccess implements Action {
  readonly type = TabsSellerInfoActionTypes.LOAD_TAB_SELLER_INFO_SUCCESS;

  constructor(public payload: { sellerInfos: TabsSellerInfo[] }) {}
}

export class LoadTabsSellerInfoFailure implements Action {
  readonly type = TabsSellerInfoActionTypes.LOAD_TAB_SELLER_INFO_FAILURE;

  constructor(public payload: { error: ErrorResponse }) {}
}

export type TabsSellerInfoActionsUnion = LoadTabsSellerInfo | LoadTabsSellerInfoSuccess | LoadTabsSellerInfoFailure;
