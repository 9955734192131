import { Pipe, PipeTransform } from '@angular/core';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { CountryEntity } from '~shared/modules/dictionary/models';

@Pipe({
  name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
  transform(value: string | string[], countriesEntity: CountryEntity): string {
    if (!isObject(countriesEntity)) {
      return '';
    }

    if (isString(value)) {
      return countriesEntity[value.toUpperCase()];
    }

    if (isArray(value)) {
      return value.map(item => countriesEntity[item.toUpperCase()]).join(', ');
    }

    return '';
  }
}
