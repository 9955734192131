import { PaymentInfo } from '@sales-orders-app/modules/orders/models/payment-info.model';
import { ErrorResponse } from '~shared/model/error-response.model';

export interface PaymentInfosState {
  payload: PaymentInfo;
  loading: boolean;
  error: ErrorResponse;
}

export const paymentInfosInitialState: PaymentInfosState = {
  payload: null,
  loading: false,
  error: null,
};
