import { Inject, Injectable } from '@angular/core';
import { LogLevel } from 'configcat-common';
import { PollingMode } from 'configcat-common/lib/ConfigCatClientOptions';
import { User } from 'configcat-common/lib/User';
import { createConsoleLogger, getClient } from 'configcat-js';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlagConfig } from '~core/modules/feature-flag/models';
import { environment } from '~env/environment';
import { FEATURE_FLAG_CONFIG_TOKEN } from './feature-flag-config.token';

export const BINARY_NUMBER_3 = 0x3;
export const BINARY_NUMBER_8 = 0x8;
export const NUMBER_16 = 16;
export const NUMBER_0 = 0;

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private envFeatureConfig: Record<string, number>;
  private configCatClient = getClient(this.config.apiKey, PollingMode.AutoPoll, {
    logger: createConsoleLogger(environment.production ? LogLevel.Off : LogLevel.Info),
  });

  private readonly overrideSuffix = '_override';

  constructor(@Inject(FEATURE_FLAG_CONFIG_TOKEN) protected readonly config: FeatureFlagConfig) {
    this.envFeatureConfig = Object.entries(environment.featureConfig).reduce(
      (acc, [key, value]) => ({ ...acc, [key.toLowerCase()]: value }),
      {},
    );
  }

  isFeatureEnabled(featureName: string, defaultValue?): Observable<boolean> {
    const envFFName = featureName.toLowerCase();
    const overriddenFFName = `${envFFName}${this.overrideSuffix}`;
    const overriddenFFValue = this.envFeatureConfig[overriddenFFName];

    if (overriddenFFValue !== undefined) {
      return of(Boolean(overriddenFFValue));
    }

    return from(this.configCatClient.getValueAsync<boolean>(featureName, defaultValue)).pipe(
      map(featureFlagValue => {
        if (featureFlagValue !== undefined) {
          return Boolean(featureFlagValue);
        }

        const envFFValue = this.envFeatureConfig[envFFName];
        if (envFFValue !== undefined) {
          return Boolean(envFFValue);
        }

        return defaultValue;
      }),
    );
  }

  getFeatureValue(featureName: string, defaultValue?: string | number): Observable<string | number> {
    return from(this.configCatClient.getValueAsync<string | number>(featureName, defaultValue)).pipe(
      map(featureFlagValue => {
        if (featureFlagValue !== undefined) {
          return featureFlagValue;
        }
        return defaultValue;
      }),
    );
  }

  public isFeatureEnabledForUser(featureName: string, userName: string, defaultValue?: boolean): Observable<boolean> {
    const user = new User(userName);
    try {
      return from(this.configCatClient.getValueAsync<boolean>(featureName, false, user)).pipe(
        map((featureFlagValue: boolean) => {
          if (featureFlagValue !== undefined) {
            return Boolean(featureFlagValue);
          }

          return defaultValue;
        }),
      );
    } catch (ex) {
      return of(defaultValue);
    }
  }

  isFeatureEnabledByCountry(featureName: string, country: string, defaultValue?: any): Observable<boolean> {
    const envFFName = featureName.toLowerCase();
    const overriddenFFName = `${envFFName}${this.overrideSuffix}`;
    const overriddenFFValue = this.envFeatureConfig[overriddenFFName];

    if (overriddenFFValue !== undefined) {
      return of(Boolean(overriddenFFValue));
    }

    const user = new User(this.uuidv4(), undefined, country);

    try {
      return from(this.configCatClient.getValueAsync(featureName, false, user) as Promise<boolean>).pipe(
        map(featureFlagValue => {
          if (featureFlagValue !== undefined) {
            return Boolean(featureFlagValue);
          }

          const envFFValue = this.envFeatureConfig[envFFName];
          if (envFFValue !== undefined) {
            return Boolean(envFFValue);
          }

          return defaultValue;
        }),
      );
    } catch (ex) {
      return of(false);
    }
  }

  private uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * NUMBER_16) | NUMBER_0;
      const v = c === 'x' ? r : (r & BINARY_NUMBER_3) | BINARY_NUMBER_8;
      return v.toString(NUMBER_16);
    });
  }
}
