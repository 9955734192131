export class ActiveFilter {
  id: string;
  label: string;
  value: string | string[];
  valueLabel: string;

  constructor(id: string, label: string, value: string | string[], valueLabel: string = null) {
    this.id = id;
    this.label = label;
    this.value = value;
    this.valueLabel = valueLabel;
  }
}
