import { TabsSellerInfo } from '@sales-orders-app/modules/orders/models';
import { ErrorResponse } from '~shared/model';

export interface TabsSellerInfoState {
  payload: TabsSellerInfo[];
  loading: boolean;
  error: ErrorResponse;
}

export const TabsSellerInfoInitialState: TabsSellerInfoState = {
  payload: null,
  loading: true,
  error: null,
};
