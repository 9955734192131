<div class="language-picker">
  <mm-icon class="language-picker__icon" name="language-picker"></mm-icon>
  <p class="language-picker__title">{{ 'SHARED.LANGUAGE.TITLE' | translate }}</p>
  <ng-select
    [items]="availableLanguages"
    [clearable]="false"
    [searchable]="false"
    [trackByFn]="trackByLanguageCode"
    (change)="onLanguageChange($event)"
    [(ngModel)]="currentLanguage"
    bindValue="code"
    class="language-picker__select"
  >
    <ng-template ng-label-tmp let-item="item">
      {{ item.name | translate }}
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <span>{{ item.name | translate }}</span>
    </ng-template>
  </ng-select>
</div>
