import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Collection } from '~shared/model';
import { RegistrationDestinationItem } from '~shared/modules/dictionary/models';
import { HttpDictionaryService } from '../../services/dictionaries.service';
import {
  LoadRegistrationDestinationDictionaryFailure,
  LoadRegistrationDestinationDictionarySuccess,
  RegistrationDestinationDictionaryActionTypes,
} from './registration-destination.actions';

@Injectable()
export class RegistrationDestinationEffects {
  loadRegistrationDestinationDictionary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegistrationDestinationDictionaryActionTypes.LOAD_REGISTRATION_DESTINATION_DICTIONARY),
      switchMap(() =>
        this.httpDictionaryService.loadRegistrationDestinationDictionary().pipe(
          map((res: Collection<RegistrationDestinationItem>) => {
            return new LoadRegistrationDestinationDictionarySuccess(res);
          }),
          catchError(error => of(new LoadRegistrationDestinationDictionaryFailure(error))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private httpDictionaryService: HttpDictionaryService) {}
}
