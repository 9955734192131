import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryIconComponent } from './components/country-icon/country-icon.component';
import { MmTooltipModule } from '@metromarkets/components-17';
import { TranslateModule } from '@ngx-translate/core';
import { DictionaryModule } from '../dictionary/dictionary.module';

@NgModule({
  declarations: [CountryIconComponent],
  imports: [CommonModule, MmTooltipModule, TranslateModule, DictionaryModule],
  exports: [CountryIconComponent],
})
export class CountryIconModule {}
