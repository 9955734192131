import { DocumentTypesActionTypes, DocumentTypesDictionaryActions } from './document-types.actions';
import { initialDocumentTypesState, DocumentTypesState } from './document-types.state';

export function documentTypesReducer(
  state = initialDocumentTypesState,
  action: DocumentTypesDictionaryActions,
): DocumentTypesState {
  switch (action.type) {
    case DocumentTypesActionTypes.LOAD_DOCUMENT_TYPES_DICTIONARY:
      return {
        ...state,
        loading: true,
      };

    case DocumentTypesActionTypes.LOAD_DOCUMENT_TYPES_DICTIONARY_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload.types,
      };

    case DocumentTypesActionTypes.LOAD_DOCUMENT_TYPES_DICTIONARY_FAILURE:
      return {
        ...state,
        loading: false,
        errorData: action.payload.error,
      };

    default:
      return state;
  }
}
