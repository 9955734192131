import { Action } from '@ngrx/store';
import { ErrorResponse } from '~shared/model/error-response.model';
import { TabsBuyerOrderInfo, BuyerCdmId, TabsBuyerInfo } from '@sales-orders-app/modules/orders/models';

export enum BuyerInfosActionTypes {
  LOAD_BUYER_INFOS = '[BuyerInfos] BuyerInfos load',
  LOAD_BUYER_INFOS_SUCCESS = '[BuyerInfos] BuyerInfos load success',
  LOAD_BUYER_INFOS_FAILURE = '[BuyerInfos] BuyerInfos load failure',
  LOAD_BUYER_ORDER_INFOS = '[BuyerInfos] BuyerOrderInfos load',
  LOAD_BUYER_ORDER_INFOS_SUCCESS = '[BuyerInfos] BuyerOrderInfos load success',
  LOAD_BUYER_ORDER_INFOS_FAILURE = '[BuyerInfos] BuyerOrderInfos load failure',
  GET_BUYER_CDM_ID = '[BuyerInfos] Get Buyer CDM ID',
  GET_BUYER_CDM_ID_SUCCESS = '[BuyerInfos] Get buyer CDM ID success',
  GET_BUYER_CDM_ID_FAILURE = '[BuyerInfos] Get buyer CDM ID failure',
}

export class LoadBuyerInfos implements Action {
  readonly type = BuyerInfosActionTypes.LOAD_BUYER_INFOS;

  constructor(public payload: { buyerId: string; saleChannel: string; buyerEmail: string }) {}
}

export class LoadBuyerInfosSuccess implements Action {
  readonly type = BuyerInfosActionTypes.LOAD_BUYER_INFOS_SUCCESS;

  constructor(public buyerInfo: TabsBuyerInfo) {}
}

export class LoadBuyerInfosFailure implements Action {
  readonly type = BuyerInfosActionTypes.LOAD_BUYER_INFOS_FAILURE;

  constructor(public payload: { error: ErrorResponse }) {}
}

export class LoadBuyerOrderInfos implements Action {
  readonly type = BuyerInfosActionTypes.LOAD_BUYER_ORDER_INFOS;

  constructor(public payload: { buyerId: string }) {}
}

export class LoadBuyerOrderInfosSuccess implements Action {
  readonly type = BuyerInfosActionTypes.LOAD_BUYER_ORDER_INFOS_SUCCESS;

  constructor(public buyerOrderInfo: TabsBuyerOrderInfo) {}
}

export class LoadBuyerOrderInfosFailure implements Action {
  readonly type = BuyerInfosActionTypes.LOAD_BUYER_ORDER_INFOS_FAILURE;

  constructor(public payload: { error: ErrorResponse }) {}
}

export class GetBuyerCDMId implements Action {
  readonly type = BuyerInfosActionTypes.GET_BUYER_CDM_ID;

  constructor(public payload: { buyerId: string }) {}
}

export class GetBuyerCDMIdSuccess implements Action {
  readonly type = BuyerInfosActionTypes.GET_BUYER_CDM_ID_SUCCESS;

  constructor(public buyerCdmId: BuyerCdmId) {}
}

export class GetBuyerCDMIdFailure implements Action {
  readonly type = BuyerInfosActionTypes.GET_BUYER_CDM_ID_FAILURE;

  constructor(public payload: { error: ErrorResponse }) {}
}

export type BuyerInfosActionsUnion =
  | LoadBuyerInfos
  | LoadBuyerInfosSuccess
  | LoadBuyerInfosFailure
  | LoadBuyerOrderInfos
  | LoadBuyerOrderInfosSuccess
  | LoadBuyerOrderInfosFailure
  | GetBuyerCDMId
  | GetBuyerCDMIdSuccess
  | GetBuyerCDMIdFailure;
