import {
  BuyerInfosActionsUnion,
  BuyerInfosActionTypes,
} from '@sales-orders-app/modules/orders/store/tabs-buyer-info/buyer-info.actions';
import {
  buyerInfosInitialState,
  BuyerInfosState,
} from '@sales-orders-app/modules/orders/store/tabs-buyer-info/buyer-info.state';

export function tabBuyerInfosReducer(state = buyerInfosInitialState, action: BuyerInfosActionsUnion): BuyerInfosState {
  switch (action.type) {
    case BuyerInfosActionTypes.LOAD_BUYER_INFOS:
      return { ...state, loading: true };
    case BuyerInfosActionTypes.LOAD_BUYER_INFOS_SUCCESS:
      return {
        ...state,
        loading: false,
        buyerInfo: { ...action.buyerInfo },
        error: null,
      };
    case BuyerInfosActionTypes.LOAD_BUYER_ORDER_INFOS_SUCCESS:
      return {
        ...state,
        loading: false,
        buyerOrderInfo: { ...action.buyerOrderInfo },
        error: null,
      };
    case BuyerInfosActionTypes.LOAD_BUYER_ORDER_INFOS_FAILURE:
      return {
        ...state,
        loading: false,
        buyerOrderInfo: null,
        error: action.payload.error,
      };
    case BuyerInfosActionTypes.LOAD_BUYER_INFOS_FAILURE:
      return {
        ...state,
        loading: false,
        buyerInfo: null,
        error: action.payload.error,
      };

    case BuyerInfosActionTypes.GET_BUYER_CDM_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        buyerInfo: null,
        buyerCdmId: action.buyerCdmId,
        error: null,
      };
    case BuyerInfosActionTypes.GET_BUYER_CDM_ID_FAILURE:
      return {
        ...state,
        loading: false,
        buyerInfo: null,
        buyerCdmId: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
